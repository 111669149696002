const Funcoes = {
  sumDig(n) {
    let a = 0;
    let b = n;
    while (b > 0) {
      a += b % 10;
      b = parseInt(b / 10, 10);
    }
    return a;
  },
  isValidIMEI(n) {
    // Converting the number into
    // String for finding length
    const s = n.toString();
    const len = s.length;

    if (len !== 15) return false;

    let sum = 0;
    let c = n;
    for (let i = len; i >= 1; i -= 1) {
      let d = c % 10;

      // Doubling every alternate digit
      if (i % 2 === 0) d *= 2;

      // Finding sum of the digits
      sum += this.sumDig(d);
      c = parseInt(c / 10, 10);
    }

    return sum % 10 === 0;
  },
  isValidCPF(n) {
    const strCPF = n.replaceAll(".", "").replaceAll("-", "");
    let Soma;
    let Resto;
    Soma = 0;
    if (strCPF === "00000000000") return false;

    for (let i = 1; i <= 9; i += 1) {
      Soma += parseInt(strCPF.substring(i - 1, i), 10) * (11 - i);
    }
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) {
      Resto = 0;
    }

    if (Resto !== parseInt(strCPF.substring(9, 10), 10)) {
      return false;
    }

    Soma = 0;
    for (let i = 1; i <= 10; i += 1) {
      Soma += parseInt(strCPF.substring(i - 1, i), 10) * (12 - i);
    }
    Resto = (Soma * 10) % 11;

    if (Resto === 10 || Resto === 11) Resto = 0;
    if (Resto !== parseInt(strCPF.substring(10, 11), 10)) return false;
    return true;
  },
  isValidCreditCard(n) {
    const strNumero = n.replaceAll("", "");
    let digit;
    const flag = true;
    let sum = 0;
    let i;
    let l;

    const digits = (strNumero).split("").reverse();

    for (i = 0, l = digits.length; i < l; i += 1) {
      digit = digits[i];
      digit = parseInt(digit, 10);

      if ((flag === !flag)) {
        digit *= 2;
      }

      if (digit > 9) {
        digit -= 9;
      }

      sum += digit;
    }
    return sum % 10 === 0;
  },
};

export default Funcoes;
