<template>
  <div
    class="text-white text-center font-bold p-4 mb-4"
    v-if="login_show_alert"
    :class="login_alert_variant"
  >
    {{ login_alert_msg }}
  </div>
  <vee-form :validation-schema="loginSchema" @submit="login">
    <!-- Email -->
    <div class="mb-3">
      <label class="inline-block mb-2">Email</label>
      <vee-field
        type="email"
        name="email"
        class="
          block
          w-full
          py-1.5
          px-3
          text-gray-800
          border border-gray-300
          transition
          duration-500
          focus:outline-none
          focus:border-black
          rounded
        "
        placeholder="Digite seu Email"
      />
      <ErrorMessage class="text-red-600" name="email" />
    </div>
    <!-- Password -->
    <div class="mb-3">
      <label class="inline-block mb-2">Senha</label>
      <vee-field
        type="password"
        name="senha"
        class="
          block
          w-full
          py-1.5
          px-3
          text-gray-800
          border border-gray-300
          transition
          duration-500
          focus:outline-none
          focus:border-black
          rounded
        "
        placeholder="Digite sua senha"
      />
      <ErrorMessage class="text-red-600" name="senha" />
    </div>
    <button
      type="submit"
      :disabled="login_in_submission"
      class="
        block
        w-full
        bg-orange
        text-white
        py-1.5
        px-3
        rounded
        transition
        hover:bg-black
      "
    >
      Entrar
    </button>
  </vee-form>
</template>

<script>
export default {
  name: "LoginForm",
  data() {
    return {
      loginSchema: {
        email: "required|email",
        senha: "required|min:3|max:32",
      },
      login_in_submission: false,
      login_show_alert: false,
      login_alert_variant: "bg-blue-500",
      login_alert_msg: "Por favor, espere! Estamos efetuando sua entrada no sistema.",
    };
  },
  methods: {
    async login(values) {
      this.login_in_submission = true;
      this.login_show_alert = true;
      this.login_alert_variant = "bg-blue-500";
      this.login_alert_msg = "Por favor, espere! Estamos efetuando sua entrada no sistema.";

      try {
        await this.$store.dispatch("login", values);
      } catch (error) {
        this.login_in_submission = false;
        this.login_alert_variant = "bg-red-500";
        this.login_alert_msg = "Dados de acesso inválidos.";
        return;
      }

      this.login_alert_variant = "bg-green-500";
      this.login_alert_msg = "Sucesso! Você agora acessou o sistema.";
      window.location.reload();
      /* this.$router.push({ name: "home" }); */
    },
  },
};
</script>
