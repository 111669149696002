<template>
  <main class="mb-10">
    <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-cogs text-orange"></i>
        </button>
        <div class="z-50 text-left ml-8">
          <!-- Info -->
          <div class="text-3xl font-bold">Configurações</div>
          <div>Acesso as cofigurações do clube.</div>
        </div>
      </div>
    </section>
    <!-- Services -->
    <section class="container mx-auto mt-6" id="comments">
      <div
        class="bg-white rounded border border-gray-200 relative flex flex-col"
      >
        <div
          class="
            bg-purple-500
            px-6
            pt-6
            pb-5
            font-bold
            border-b border-gray-200
          "
        >
          <span class="card-title text-white">Configurações disponíveis</span>
          <i class="fa fa-tasks float-right text-white text-2xl"></i>
        </div>
        <ul class="container mx-auto">
          <li class="p-4 border border-gray-200">
            <div>
              <div class="font-bold">
                <i class="fas fa-question w-7 text-purple-700"></i>
                <span
                  class="py-4 px-2 font-semibold"
                  :to="{ name: 'perfil-funcionario' }"
                >
                  Meus Dados
                </span>
                <div>
                  <router-link
                  :to="{ name: 'perfil-funcionario' }"
                  class="
                    text-base
                    ml-10
                    w-24
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    hover:text-white
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  >
                    Acessar
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li class="p-4 border border-gray-200"
           v-if="showCadastroLojas"
          >
            <div>
              <div class="font-bold">
                <i class="fas fa-question w-7 text-purple-700"></i>
                <span
                  class="py-4 px-2 font-semibold"
                >
                  Cadastro de Lojas
                </span>
                <div>
                  <router-link
                  :to="{ name: 'cadastro-loja' }"
                  class="
                    text-base
                    ml-10
                    w-24
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    hover:text-white
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  >
                    Acessar
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li class="p-4 border border-gray-200"
            v-if="showCadastroPlanos"
          >
            <div>
              <div class="font-bold">
                <i class="fas fa-question w-7 text-purple-700"></i>
                <span
                  class="py-4 px-2 font-semibold"
                >
                  Cadastro de Planos
                </span>
                <div>
                  <router-link
                  :to="{ name: 'cadastro-planos' }"
                  class="
                    text-base
                    ml-10
                    w-24
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    hover:text-white
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  >
                    Acessar
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li class="p-4 border border-gray-200"
            v-if="showCadastroAparelhos"
          >
            <div>
              <div class="font-bold">
                <i class="fas fa-question w-7 text-purple-700"></i>
                <span
                  class="py-4 px-2 font-semibold"
                >
                  Cadastro de Modelos
                </span>
                <div>
                  <router-link
                  :to="{ name: 'cadastro-modelo' }"
                  class="
                    text-base
                    ml-10
                    w-24
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    hover:text-white
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  >
                    Acessar
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li class="p-4 border border-gray-200">
            <div>
              <div class="font-bold">
                <i class="fas fa-question w-7 text-purple-700"></i>
                <span
                  class="py-4 px-2 font-semibold"
                  :to="{ name: 'cadastro-usuario' }"
                >
                  Cadastro de usuários
                </span>
                <div>
                  <router-link
                  :to="{ name: 'cadastro-usuario' }"
                  class="
                    text-base
                    ml-10
                    w-24
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    hover:text-white
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  >
                    Acessar
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li class="p-4 border border-gray-200" v-if="showCadastroAdmin">
            <div>
              <div class="font-bold">
                <i class="fas fa-question w-7 text-purple-700"></i>
                <span
                  class="py-4 px-2 font-semibold"
                  :to="{ name: 'cadastro-admin' }"
                >
                  Cadastro de administradores
                </span>
                <div>
                  <router-link
                  :to="{ name: 'cadastro-admin' }"
                  class="
                    text-base
                    ml-10
                    w-24
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    hover:text-white
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  >
                    Acessar
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li class="p-4 border border-gray-200" v-if="showCadastroSupervisores">
            <div>
              <div class="font-bold">
                <i class="fas fa-question w-7 text-purple-700"></i>
                <span
                  class="py-4 px-2 font-semibold"
                  :to="{ name: 'cadastro-supervisores' }"
                >
                  Cadastro de supervisores
                </span>
                <div>
                  <router-link
                  :to="{ name: 'cadastro-supervisores' }"
                  class="
                    text-base
                    ml-10
                    w-24
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    hover:text-white
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  >
                    Acessar
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li class="p-4 border border-gray-200" v-if="showExportarConciliacao">
            <div>
              <div class="font-bold">
                <i class="fas fa-question w-7 text-purple-700"></i>
                <span
                  class="py-4 px-2 font-semibold"
                  :to="{ name: 'exportar-conciliacao' }"
                >
                  Exportação de Conciliação
                </span>
                <div>
                  <router-link
                  :to="{ name: 'exportar-conciliacao' }"
                  class="
                    text-base
                    ml-10
                    w-24
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    hover:text-white
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  >
                    Acessar
                  </router-link>
                </div>
              </div>
            </div>
          </li>
          <li class="p-4 border border-gray-200" v-if="showHistoricoRecorrencias">
            <div>
              <div class="font-bold">
                <i class="fas fa-question w-7 text-purple-700"></i>
                <span
                  class="py-4 px-2 font-semibold"
                  :to="{ name: 'historico-recorrencias' }"
                >
                  Historico de Recorrencias
                </span>
                <div>
                  <router-link
                  :to="{ name: 'historico-recorrencias' }"
                  class="
                    text-base
                    ml-10
                    w-24
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    hover:text-white
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  >
                    Acessar
                  </router-link>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </main>
</template>

<script>
// import { auth, usersCollection } from "@/includes/firebase";
import { mapState } from "vuex";
import { auth, usersCollection } from "@/includes/firebase";

export default {
  // eslint-disable-next-line
  name: "Setting",
  computed: {
    ...mapState(["userLoggedIn"]),
    showCadastroLojas() {
      // console.log(this.userLoggedIn, " ", this.profile);
      return this.userLoggedIn && this.profile !== "gerente" && this.profile !== "vendedor";
    },
    showCadastroPlanos() {
      // console.log(this.userLoggedIn, " ", this.profile);
      return this.userLoggedIn && this.profile !== "gerente" && this.profile !== "vendedor";
    },
    showCadastroAparelhos() {
      // console.log(this.userLoggedIn, " ", this.profile);
      return this.userLoggedIn && this.profile !== "gerente" && this.profile !== "vendedor";
    },
    showCadastroAdmin() {
      // console.log(this.userLoggedIn, " ", this.profile);
      return this.userLoggedIn && this.profile !== "gerente" && this.profile !== "vendedor" && this.profile !== "parceiro" && this.profile !== "supervisor";
    },
    showCadastroSupervisores() {
      // console.log(this.userLoggedIn, " ", this.profile);
      return this.userLoggedIn && this.profile !== "gerente" && this.profile !== "vendedor" && this.profile !== "supervisor";
    },
    showExportarConciliacao() {
      // console.log(this.userLoggedIn, " ", this.profile);
      return this.userLoggedIn && this.profile !== "gerente" && this.profile !== "vendedor" && this.profile !== "supervisor";
    },
    showHistoricoRecorrencias() {
      // console.log(this.userLoggedIn, " ", this.profile);
      return this.userLoggedIn && this.profile !== "gerente" && this.profile !== "vendedor" && this.profile !== "supervisor";
    },
  },
  data() {
    return {
      profile: "",
    };
  },
  async mounted() {
    if (auth.currentUser != null) {
      const docSnapshot = await usersCollection.doc(auth.currentUser.uid).get();
      this.profile = docSnapshot.data().profile;
    }
  },
};
</script>
