<template>
  <main>
    <!-- Main Section -->
    <section class="container mx-auto mt-6">
      <div
        class="bg-white rounded border-gray-200 relative flex flex-col"
      >
        <div class="p-6">
          SKU SISTEMA CARAMELO;ID MARCA;MARCA;MODELO;VALOR APARELO;TIPO REPARO;VALOR REPARO;STATUS

          <div v-for="item in listaAparelhos" :key="item.id">
            {{ item.id }};{{ item.marca }};{{ item.nomeMarca }};{{item.nomeModelo}};{{item.valorAparelho}};
            {{item.tipoReparo}};{{item.valorReparo}};{{item.status}}
        </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { marcasCollection, modelosCollection } from "@/includes/firebase";

export default {
  // eslint-disable-next-line
  name: "Export",
  data() {
    return {
      listaAparelhos: [],
    };
  },
  async mounted() {
    console.log("sks");
    this.listaAparelhos = [];
    const modelos = await modelosCollection.get();

    /* eslint-disable-next-line */
    for (const modelo of modelos.docs) {
      /* eslint-disable-next-line */
      const marcas = await marcasCollection.doc(modelo.data().marca).get();
      const aparelho = {
        id: modelo.id,
        marca: modelo.data().marca,
        nomeMarca: marcas.data().nome,
        nomeModelo: modelo.data().nome,
        valorAparelho: modelo.data().preco,
        tipoReparo: "TELA",
        valorReparo: 0,
        status: modelo.data().status,
      };
      this.listaAparelhos.push(aparelho);
    }
  },
};
</script>
