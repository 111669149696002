<script>
import { Chart, registerables } from 'chart.js';
import { LineChart, useLineChart } from 'vue-chart-3';
import {
  ref,
  computed,
  defineComponent,
  reactive,
} from 'vue';
import {
  assinaturasCollection,
  usersCollection,
  auth,
  // acionamentoReparoCollection
} from '@/includes/firebase';

Chart.register(...registerables);

export default defineComponent({
  name: 'App',
  components: {
    LineChart,
  },
  setup() {
    const loaded = ref(false);

    const assinaturasMes = reactive([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);

    (async () => {
      const user = await usersCollection.doc(auth.currentUser.uid).get();
      if (user.data().profile !== "gerente") {
        const assinaturas = await assinaturasCollection.get();
        assinaturas.forEach((doc) => {
          if (doc.data().status === false && (doc.data().situacao === "Cancelada" || doc.data().situacao === "Cacenlada (In)")) {
            assinaturasMes[Number(doc.data().dataCancelamento.substr(3, 2)) - 1] += 1;
          }
        });
        // console.log(assinaturas);
        loaded.value = true;
      } else {
        const assinaturas = await assinaturasCollection
          .where("loja", "==", user.data().loja)
          .get();
        assinaturas.forEach((doc) => {
          if (doc.data().status === false) {
            assinaturasMes[Number(doc.data().dataCancelamento.substr(3, 2)) - 1] += 1;
          }
        });
        // console.log(assinaturas);
        loaded.value = true;
      }
    })();

    const data = ref(assinaturasMes);

    const chartData = computed(() => ({
      labels: ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      datasets: [
        {
          data: data.value,
          backgroundColor: '#FF6961',
        },
      ],
    }));

    const options = ref({
      plugins: {
        title: {
          text: "Total Cancelamentos por Mês",
          font: {
            size: 20,
          },
        },
      },
    });

    const { lineChartProps } = useLineChart({
      chartData,
      options,
    });

    return { lineChartProps };
  },
});
</script>

<template>
  <div id="app" style="width: 100%">
    <LineChart v-bind="lineChartProps" />
  </div>
</template>
