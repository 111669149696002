<template>
  <main>
  <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-store text-orange"></i>
        </button>
        <div class="z-50 text-left ml-8">
          <!-- Info -->
          <div class="text-3xl font-bold">Cadastro de Planos</div>
        </div>
      </div>
    </section>
    <!-- Main Content -->
    <section class="container mx-auto">
      <div
        class="bg-white rounded border border-gray-200 relative flex flex-col"
      >
        <!-- component -->
        <div class="p-5">
          <div class="mt-8 p-4">
            <!-- Forms -->
            <div>
              <div
                class="
                  flex flex-col
                  md:flex-row
                  border
                  rounded
                  border-gray-200
                  bg-gray-100
                "
              >
                <span
                  class="
                    font-bold
                    text-gray-600 text-xs
                    leading-8
                    uppercase
                    ml-3
                  "
                >
                  Dados do Plano
                </span>
              </div>
              <vee-form
                :validation-schema="planoSchema"
                ref="frmDados"
              >
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Nome do Plano
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="nome"
                        v-model="plano.nome"
                        placeholder="Digite o nome.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                          bg-white
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="nome"
                    />
                    <span
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      v-show="alertMsg !== ''"
                    >
                      {{ alertMsg }}
                    </span>
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Percentual
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="percentual"
                        v-model="plano.percentual"
                        placeholder="Digite o Percentual..."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                        v-mask="'0.##'"
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="percentual"
                    />
                  </div>
                </div>
              </vee-form>
              <vee-form enctype="multipart/form-data">
                <input
                  class="form-control
                    block
                    w-1/2
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                  "
                  type="file" @change="onFileChange" accept=".csv"
                />
              </vee-form>
            </div>
            <div class="flex p-2 mt-4">
              <div class="flex-auto flex flex-row-reverse">
                <button
                  class="
                    text-base
                    ml-2
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  @click.prevent="cadastrar()"
                >
                  Cadastrar
                </button>
                <!--<button
                  class="
                    text-base
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-gray-500
                    bg-gray-400
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  @click.prevent="importar()"
                >
                  Importar
                </button>
                <button
                  class="
                    text-base
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-gray-500
                    bg-gray-400
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  @click.prevent="email()"
                >
                  Alterar
                </button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mask } from "vue-the-mask";
import {
  planosCollection,
  // functions,
} from "@/includes/firebase";

export default {
  name: "CadastroPlanos",
  data() {
    return {
      alertMsg: "",
      file: "",
      lista: [],
      plano: {
        nome: "",
        percentual: "",
      },
      planoSchema: {
        nome: "required:min:5",
        percentual: "required:max:4",
      },
    };
  },
  directives: { mask },
  methods: {
    // lê os dados do arquivo
    onFileChange(arq) {
      console.log(arq.target.result);
      const files = arq.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.target);
        this.lista.push(e.target.result.split("\r\n"));
      };
      reader.readAsText(files);
    },
    // grava os dados do csv
    async importar() {
      const lista = [];
      let qtdLinhas = 0;
      // eslint-disable-next-line
      this.lista[0].forEach(() => qtdLinhas += 1);
      for (let i = 0; i < qtdLinhas; i += 1) {
        // console.log(this.lista);
        if (this.lista[0][i] !== '') lista[i] = this.lista[0][i].split(";");
      }
      for (let i = 1; i < lista.length; i += 1) {
        const plano = {
          nome: lista[i][0],
          percentual: lista[i][1],
        };
        try {
          /* eslint-disable-next-line */
          await planosCollection.add(plano);
        } catch (e) {
          /* eslint-disable-next-line */
          alert("Um erro inesperado aconteceu. Por favor, tente novamente.");

          /* eslint-disable-next-line */
          return;
        }
        this.reg_alert_variant = "bg-green-500";
        this.reg_alert_msg = "Sucesso! Importação realizada.";
        /* eslint-disable-next-line */
        alert("Sucesso! Importação realizada");
      }
    },
    async cadastrar() {
      const plano = {
        nome: this.plano.nome.replace(/\d/g, ''),
        percentual: this.plano.percentual,
      };
      /* eslint-disable-next-line */
      if (plano.nome !== '' && plano.percentual !== '') {
        // console.log(plano);
        try {
          await planosCollection.add(plano);
        } catch (e) {
        /* eslint-disable-next-line */
          alert("Um erro inesperado aconteceu. Por favor, tente novamente.");

          /* eslint-disable-next-line */
          return;
        }
      }
      /* eslint-disable-next-line */
      alert("Sucesso! Plano cadastrado");      
      this.plano.nome = '';
      this.plano.percentual = '';
      this.reg_alert_variant = "bg-green-500";
      this.reg_alert_msg = "Sucesso! Importação realizada.";
    },
  },
};
</script>
