<template>
  <main>
    <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-store text-orange"></i>
        </button>
        <div class="z-50 text-left ml-8">
          <!-- Info -->
          <div class="text-3xl font-bold">Histórico de Recorrências</div>
        </div>
      </div>
    </section>
    <!-- Main Content -->
    <section class="container mx-auto">
      <div
        class="bg-white rounded border border-gray-200 relative flex flex-col"
      >
        <!-- component -->
        <div class="p-5">
          <div class="p-4 flex justify-center">
            <!-- Forms -->

            <div class="flex-col md:flex-row">
              <div class=" w-full mx-2 flex-1 svelte-1l8159u">
                <div
                  class="
                    bg-white
                    my-2
                    p-1
                    flex
                    justify-center
                    items-center
                    border border-gray-200
                    rounded
                    svelte-1l8159u
                  "
                >
                  <input
                    name="cpf"
                    label="cpf"
                    class="
                      justify-center
                      items-center
                      p-1
                      px-2
                      appearance-none
                      outline-none
                      w-full
                      text-gray-800
                    "
                    v-model="cpfSelecionado"
                    placeholder="Digite o CPF"
                    v-mask="'###.###.###-##'"
                  />
                  <button
                    class="
                      text-base
                      hover:scale-110
                      focus:outline-none
                      flex
                      justify-center
                      px-4
                      py-2
                      rounded
                      font-bold
                      cursor-pointer
                      hover:bg-black
                      bg-orange
                      text-white
                      border
                      duration-200
                      ease-in-out
                      border-white
                      transition
                    "
                     @click.self="consultarCPF()"
                  >
                    Pesquisar
                  </button>
                </div>
              </div>
              <!-- <div class="w-full mx-2 flex-1 svelte-1l8159u">
                <button
                  class="
                    text-base
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-black
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-white
                    transition
                  "
                    @click.self="filtrarAssinaturasPendentes()"
                >
                  Filtrar Pendentes
                </button>
                <button
                  class="
                    text-base
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-black
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-white
                    transition
                  "
                    @click.self="listarAssinaturas()"
                >
                  Limpar
                </button>
              </div> -->
            </div>
          </div>

          <div class="flex justify-center text-sm">
            <!-- <table>
            <thead>
              <tr>
                <td class="justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue text-sm">Nome</td>
                <td class="justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue text-sm">Contratação</td>
                <td class="justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue text-sm">Plano</td>
                <td class="justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue text-sm">Celular</td>
                <td class="justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue text-sm">Modelo</td>
                <td class="justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue text-sm">Marca</td>
                <td class="justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue text-sm">Status</td>
                <td class="justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue text-sm">Acionamentos</td>
                <td class="justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue text-sm">Acionar Reparo</td>
                <td class="justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue text-sm">Gerar Link</td>
                <td class="justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue text-sm">2º via Certificado</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table> -->
            <!-- <div class="flex justify-center items-center text-white
            w-1/7 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-purple-400 h-12">
              CPF
            </div> -->
            <div class="flex justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Nome
            </div>
            <div class="flex justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Contratação
            </div>
            <div class="flex justify-center items-center text-white hidden sm:flex
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Plano
            </div>
            <!-- <div class="flex justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Celular
            </div> -->
            <div class="flex justify-center items-center text-white hidden sm:flex
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Marca
            </div>
            <div class="flex justify-center items-center text-white hidden sm:flex
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Modelo
            </div>
            <div class="flex justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Status
            </div>
            <div class="flex justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/6 mb-4 bg-blue h-12">
              Acionamentos
            </div>
            <div class="flex justify-center items-center text-white
            w-full sm:w-1/2 md:w-1/3 lg:w-1/2 xl:w-1/6 mb-4 bg-blue h-12">
              Histórico
            </div>
          </div>
          <div v-for="item in listaUserAssinatura"  :key ="item.value" class="text-sm">
            <div class="flex justify-center grid-cols-11">
              <!-- <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              {{item.cpf}}
              </div> -->
              <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4">
              {{item.nome}}
              </div>
              <div class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4"
              >
              {{item.dataAquisicao}}</div>
              <div class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4"
              >
              {{item.nomePlano}}</div>
              <!-- <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4">
              {{item.celular}}
              </div> -->
              <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4">
              {{item.nomeMarca}}
              </div>
              <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4"
              >
              {{item.nomeModelo}}
              <br>
              </div>
              <div class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4"
              >
              {{item.situacao}}
              <!-- <div v-if="item.status">
                <p>
                  Ativo
                </p>
              </div>
              <div v-else>
                <p>
                  Cancelado
                </p>
              </div> -->
              </div>
              <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4">
              {{item.qtdAcionamento}}
              </div>
              <div>
              <div class="font-bold">
                <div>
                  <router-link :to="`/cellairis/historico-recorrencia-cliente/${item.value}`"
                  class="
                    text-base
                    ml-10
                    w-24
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    hover:text-white
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  >
                    Acessar
                  </router-link>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mask } from "vue-the-mask";
// import { v4 as uuidv4 } from 'uuid';
import {
  auth,
  usersCollection,
  lojasCollection,
  assinaturasCollection,
  marcasCollection,
  modelosCollection,
  planosCollection,
  acionamentoReparoCollection,
  // functions,
} from "@/includes/firebase";

export default {
  name: "HistoricoRecorrencias",
  data() {
    return {
      listaAssinaturasPendentes: [],
      emailLogado: "",
      possuiAssinatura: false,
      listaUser: [],
      msgLink: "",
      link: "",
      listaAssinaturas: null,
      msgCancelamento: "",
      toggleModal: false,
      ToggleGerarLink: false,
      ToggleAcionar: false,
      TogglSucessoAcionar: false,
      ToggleLimiteAcionar: false,
      ToggleFalhaAcionar: false,
      ToggleFinalizarAcionamento: false,
      TogglSucessoFinalizarAcionamento: false,
      ToggleAcionarInadimplente: false,
      motivoSelecionado: "",
      observacao: "",
      showImage: false,
      toggleModalImagem: false,
      reparado: true,
      listaMotivos: [],
      motivo: {
        value: "",
        nome: "",
      },
      cpfSelecionado: "",
      assinaturaSelecionada: {
        id: "",
        cpf: "",
        celular: "",
        valorCobertura: "",
        imei: "",
        dataAquisicao: "",
        codMarca: "",
        nomeMarca: "",
        codModelo: "",
        nomeModelo: "",
        codPlano: "",
        nomePlano: "",
        nome: "",
        email: "",
        cartao: "",
      },
      listaUserAssinatura: [],
      assinatura: {
        value: null,
        cpf: null,
        nome: null,
        celular: null,
        valorCobertura: null,
        imei: null,
        dataAquisicao: null,
        codMarca: null,
        nomeMarca: null,
        codModelo: null,
        nomeModelo: null,
        valorReparo: null,
        valorFranquia: null,
        tipoReparo: "TELA",
        qtdAcionamento: "",
        codPlano: null,
        nomePlano: null,
        email: null,
        status: null,
        situacao: "",
        data: null,
        emReparo: false,
        tokenCobranca: "",
      },
    };
  },
  directives: { mask },
  async created() {
    if (auth.currentUser != null) {
      const docSnapshot = await usersCollection.doc(auth.currentUser.uid).get();
      console.log(auth.currentUser.uid);
      this.emailLogado = docSnapshot.data().email;
      this.profileLogado = docSnapshot.data().profile;
      const docSnapshotAssinaturas = await assinaturasCollection.where("cliente", "==", this.emailLogado).get();
      console.log(docSnapshotAssinaturas);
      if (docSnapshotAssinaturas.docs.length >= 1) {
        this.possuiAssinatura = true;
      }
    }
    this.listarAssinaturas();
    this.listarUsers();
  },
  methods: {
    consultarHistorico(idAssinatura) {
      const itemSelecionado = this.listaUserAssinatura.find((a) => a.value === idAssinatura);
      this.assinaturaSelecionada.id = idAssinatura;
      this.assinaturaSelecionada.cpf = itemSelecionado.cpf;
      this.assinaturaSelecionada.celular = itemSelecionado.celular;
      this.assinaturaSelecionada.valorCobertura = itemSelecionado.valorCobertura;
      this.assinaturaSelecionada.imei = itemSelecionado.imei;
      this.assinaturaSelecionada.dataAquisicao = itemSelecionado.dataAquisicao;
      this.assinaturaSelecionada.codMarca = itemSelecionado.codMarca;
      this.assinaturaSelecionada.nomeMarca = itemSelecionado.nomeMarca;
      this.assinaturaSelecionada.codModelo = itemSelecionado.codModelo;
      this.assinaturaSelecionada.nomeModelo = itemSelecionado.nomeModelo;
      this.assinaturaSelecionada.codPlano = itemSelecionado.codPlano;
      this.assinaturaSelecionada.nomePlano = itemSelecionado.nomePlano;
      this.assinaturaSelecionada.nome = itemSelecionado.nome;
      this.assinaturaSelecionada.email = itemSelecionado.email;
      this.assinaturaSelecionada.cartao = itemSelecionado.cartao;
    },
    async consultarCPF() {
      const snapshot = await usersCollection
        .where("cpf", "==", this.cpfSelecionado)
        .get();
      console.log(snapshot);
      const listaUser = [];
      console.log(snapshot);
      snapshot.docs.forEach((document) => {
        const user = {
          cpf: document.data().cpf,
          nome: document.data().name,
          email: document.data().email,
          profile: document.data().profile,
        };
        listaUser.push(user);
      });

      this.listaUserAssinatura = [];

      const userSession = await usersCollection.doc(auth.currentUser.uid).get();
      let documentos = null;
      // LIBERAÇÃO DE VENDAS A SEREM EXIBIDAS POR TIPO DE USUÁRIO
      if (userSession.data().profile === "vendedor") {
        documentos = await assinaturasCollection
          .where("cliente", "==", listaUser[0].email)
          .where("vendedor", "==", auth.currentUser.uid)
          .get();
      } else if (userSession.data().profile === "gerente") {
        documentos = await assinaturasCollection
          .where("cliente", "==", listaUser[0].email)
          .where("loja", "==", userSession.data().loja)
          .get();
      } else {
        documentos = await assinaturasCollection.where("cliente", "==", listaUser[0].email).get();
      }
      if (userSession.data().profile === "parceiro") {
        const lojas = await lojasCollection
          .where("grupoEconomico", "==", userSession.data().grupoEconomico)
          .get();
        /* eslint-disable-next-line */
        for (const document of documentos.docs) {
          // console.log(document.data().vendedor);
          lojas.docs.forEach(async (loja) => {
            // console.log(loja.data().nome);
            if (document.data().loja === loja.data().nome) {
              const reparos = await acionamentoReparoCollection.where("codAssinatura", "==", document.id).get();
              const d = new Date();
              const anoAtual = d.getFullYear();
              let reparosNoAno = 0;
              reparos.docs.forEach((doc) => {
                if (doc.data().dataAcionamento.substr(-4) === `${anoAtual}`) {
                  reparosNoAno += 1;
                }
              });
              // eslint-disable-next-line
              const acionamentos = reparosNoAno + "/2";
              const userAssinatura = {
                value: document.id,
                cpf: listaUser[0].cpf,
                nome: listaUser[0].nome,
                celular: document.data().celular,
                valorCobertura: document.data().valorCobertura,
                imei: document.data().imei,
                dataAquisicao: document.data().data.toLocaleDateString("pt-BR").substr(0, 10),
                codMarca: document.data().marca,
                codModelo: document.data().modelo,
                codPlano: document.data().plano,
                email: document.data().cliente,
                imagem: document.data().imagem,
                qtdAcionamento: acionamentos,
                status: document.data().status,
                situacao: document.data().situacao,
                data: document.data().data,
                emReparo: document.data().emReparo,
                tipoReparo: "TELA",
                cartao: document.data().cartao,
                tokenCobranca: document.data().tokenCobranca,
              };
              // eslint-disable-next-line
              console.log(document.data().valorCobertura);
              /* eslint-disable-next-line */
              const mac = await marcasCollection.doc(document.data().marca).get();
              userAssinatura.nomeMarca = mac.data().nome;
              /* eslint-disable-next-line */
              const mdc = await modelosCollection.doc(document.data().modelo).get();
              userAssinatura.nomeModelo = mdc.data().nome;
              userAssinatura.valorReparo = mdc.data().valorReparo;
              userAssinatura.valorFranquia = mdc.data().valorReparo * 0.1;
              /* eslint-disable-next-line */
              const plc = await planosCollection.doc(mdc.data().faixa).get();
              userAssinatura.nomePlano = document.data().plano;
              // userAssinatura.nomePlano = plc.data().nome;

              // Adicionando os dados recuperados na lista
              this.listaUserAssinatura.push(userAssinatura);
            }
            // eslint-disable-next-line
            // console.log(this.listaUserAssinatura);
          });
        }
      } else {
        /* eslint-disable-next-line */
        for (const document of documentos.docs) {
          // eslint-disable-next-line
          const reparos = await acionamentoReparoCollection.where("codAssinatura", "==", document.id).get();
          const d = new Date();
          const anoAtual = d.getFullYear();
          let reparosNoAno = 0;
          reparos.docs.forEach((doc) => {
            if (doc.data().dataAcionamento.substr(-4) === `${anoAtual}`) {
              reparosNoAno += 1;
            }
          });
          // eslint-disable-next-line
          const reparosUtilizados = reparosNoAno + "/2"
          const userAssinatura = {
            value: document.id,
            cpf: listaUser[0].cpf,
            nome: listaUser[0].nome,
            celular: document.data().celular,
            valorCobertura: document.data().valorCobertura,
            imei: document.data().imei,
            dataAquisicao: document.data().data.toDate().toLocaleString("pt-BR").substr(0, 10),
            codMarca: document.data().marca,
            codModelo: document.data().modelo,
            codPlano: document.data().plano,
            email: document.data().cliente,
            imagem: document.data().imagem,
            status: document.data().status,
            situacao: document.data().situacao,
            qtdAcionamento: reparosUtilizados,
            data: document.data().data,
            emReparo: document.data().emReparo,
            tipoReparo: "TELA",
            cartao: document.data().cartao,
            tokenCobranca: document.data().tokenCobranca,
          };
          // eslint-disable-next-line
          // console.log(document.data().valorCobertura);
          /* eslint-disable-next-line */
          const mac = await marcasCollection.doc(document.data().marca).get();
          userAssinatura.nomeMarca = mac.data().nome;
          /* eslint-disable-next-line */
          const mdc = await modelosCollection.doc(document.data().modelo).get();
          userAssinatura.nomeModelo = mdc.data().nome;
          userAssinatura.valorReparo = mdc.data().valorReparo;
          userAssinatura.valorFranquia = mdc.data().valorReparo * 0.1;
          /* eslint-disable-next-line */
          const plc = await planosCollection.doc(mdc.data().faixa).get();
          userAssinatura.nomePlano = document.data().plano;
          // userAssinatura.nomePlano = plc.data().nome;

          // Adicionando os dados recuperados na lista
          this.listaUserAssinatura.push(userAssinatura);
        }
        // eslint-disable-next-line
        // console.log(this.listaUserAssinatura);
      }
    },
    obterDataVigencia(data) {
      const dateParts = data.split("/");
      const d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      const dProxima = new Date(d.getFullYear() + 1, d.getMonth(), d.getDate()).toLocaleDateString("pt-BR");

      const vigencia = `${data} - ${dProxima}`;
      return vigencia;
    },
    async listarAssinaturas() {
      // const response = await assinaturasCollection.get();
      // this.listaUserAssinaturas = response.docs;
      this.listaUserAssinatura = [];

      const userSession = await usersCollection.doc(auth.currentUser.uid).get();
      console.log(userSession.data());
      let documentos = null;
      // LIBERAÇÃO DE VENDAS A SEREM EXIBIDAS POR TIPO DE USUÁRIO
      if (userSession.data().profile === "vendedor" || userSession.data().profile === "gerente") {
        documentos = await assinaturasCollection
          .where("loja", "==", userSession.data().loja)
          .get();
        console.log(documentos.docs);
      } else {
        documentos = await assinaturasCollection.get();
      }
      if (userSession.data().profile === "parceiro") {
        const lojas = await lojasCollection
          .where("grupoEconomico", "==", userSession.data().grupoEconomico)
          .get();
        /* eslint-disable-next-line */
        for (const document of documentos.docs) {
          // console.log(document.data().vendedor);
          lojas.docs.forEach(async (loja) => {
            // console.log(loja.data().nome);
            if (document.data().loja === loja.data().nome) {
              const reparos = acionamentoReparoCollection.where("codAssinatura", "==", document.id).get();
              const d = new Date();
              const anoAtual = d.getFullYear();
              let reparosNoAno = 0;
              reparos.docs.forEach((doc) => {
                if (doc.data().dataAcionamento.substr(-4) === `${anoAtual}`) {
                  reparosNoAno += 1;
                }
              });
              const userAssinaturaRef = this.listaUser.find((user) => user.email === document.data().cliente);
              // eslint-disable-next-line
              const acionamentos = reparosNoAno + "/2";
              const dataAssinatura = await document.data().data.toDate().toLocaleString("pt-BR").substr(0, 10);
              const userAssinatura = {
                value: document.id,
                cpf: userAssinaturaRef.cpf,
                nome: userAssinaturaRef.nome,
                celular: document.data().celular,
                valorCobertura: document.data().valorCobertura,
                imei: document.data().imei,
                dataAquisicao: dataAssinatura,
                codMarca: document.data().marca,
                codModelo: document.data().modelo,
                codPlano: document.data().plano,
                email: document.data().cliente,
                imagem: document.data().imagem,
                qtdAcionamento: acionamentos,
                status: document.data().status,
                situacao: document.data().situacao,
                data: document.data().data,
                emReparo: document.data().emReparo,
                tipoReparo: "TELA",
                cartao: document.data().cartao,
                tokenCobranca: document.data().tokenCobranca,
              };
              console.log(userAssinatura.dataAquisicao);
              /* eslint-disable-next-line */
              const mac = await marcasCollection.doc(document.data().marca).get();
              userAssinatura.nomeMarca = mac.data().nome;
              /* eslint-disable-next-line */
              const mdc = await modelosCollection.doc(document.data().modelo).get();
              userAssinatura.nomeModelo = mdc.data().nome;
              userAssinatura.valorReparo = mdc.data().valorReparo;
              userAssinatura.valorFranquia = mdc.data().valorReparo * 0.1;
              /* eslint-disable-next-line */
              const plc = await planosCollection.doc(mdc.data().faixa).get();
              userAssinatura.nomePlano = document.data().plano;
              // userAssinatura.nomePlano = plc.data().nome;

              // Adicionando os dados recuperados na lista
              this.listaUserAssinatura.push(userAssinatura);
            }
            // eslint-disable-next-line
            // console.log(this.listaUserAssinatura);
          });
        }
      } else {
        /* eslint-disable-next-line */
        for (const document of documentos.docs) {
          // eslint-disable-next-line
          const reparos = await acionamentoReparoCollection.where("codAssinatura", "==", document.id).get();
          const d = new Date();
          const anoAtual = d.getFullYear();
          let reparosNoAno = 0;
          reparos.docs.forEach((doc) => {
            if (doc.data().dataAcionamento.substr(-4) === `${anoAtual}`) {
              reparosNoAno += 1;
            }
          });
          const userAssinaturaRef = this.listaUser.find((user) => user.email === document.data().cliente);
          // eslint-disable-next-line
          const dataAssinatura = await document.data().data.toDate().toLocaleString("pt-BR").substr(0, 10);
          const reparosUtilizados = `${reparosNoAno}/2`;
          const userAssinatura = {
            value: document.id,
            cpf: userAssinaturaRef.cpf,
            nome: userAssinaturaRef.nome,
            celular: document.data().celular,
            valorCobertura: document.data().valorCobertura,
            imei: document.data().imei,
            dataAquisicao: dataAssinatura,
            codMarca: document.data().marca,
            codModelo: document.data().modelo,
            codPlano: document.data().plano,
            email: document.data().cliente,
            imagem: document.data().imagem,
            status: document.data().status,
            situacao: document.data().situacao,
            qtdAcionamento: reparosUtilizados,
            data: document.data().data,
            emReparo: document.data().emReparo,
            tipoReparo: "TELA",
            cartao: document.data().cartao,
            tokenCobranca: document.data().tokenCobranca,
          };
          // console.log(userAssinatura.dataAquisicao);
          // eslint-disable-next-line
          // console.log(document.data().valorCobertura);
          /* eslint-disable-next-line */
          const mac = await marcasCollection.doc(document.data().marca).get();
          userAssinatura.nomeMarca = mac.data().nome;
          /* eslint-disable-next-line */
          const mdc = await modelosCollection.doc(document.data().modelo).get();
          userAssinatura.nomeModelo = mdc.data().nome;
          userAssinatura.valorReparo = mdc.data().valorReparo;
          userAssinatura.valorFranquia = mdc.data().valorReparo * 0.1;
          /* eslint-disable-next-line */
          const plc = await planosCollection.doc(mdc.data().faixa).get();
          userAssinatura.nomePlano = document.data().plano;
          // userAssinatura.nomePlano = plc.data().nome;

          // Adicionando os dados recuperados na lista
          this.listaUserAssinatura.push(userAssinatura);
        }
        // eslint-disable-next-line
        // console.log(this.listaUserAssinatura);
      }
    },
    async listarUsers() {
      const snapshot = await usersCollection.get();

      this.listaUser = [];
      snapshot.docs.forEach((document) => {
        const user = {
          cpf: document.data().cpf,
          nome: document.data().name,
          email: document.data().email,
          profile: document.data().profile,
        };
        this.listaUser.push(user);
      });
    },
  },
};
</script>
