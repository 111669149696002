<template>
  <!-- Navbar goes here -->
  <nav class="bg-white shadow-lg">
    <div class="max-w-6xl mx-auto px-4">
      <div class="flex justify-between">
        <div class="flex space-x-5 text-base">
          <div>
            <!-- Website Logo -->
            <router-link
              class="flex items-center py-4"
              :to="{ name: 'home' }"
              exact-active-class="no-active"
            >
              <img
                src="../assets/logo-cellairis-plus-laranja.png"
                alt="Logo"
                class="h-8"
              />
              <span class="font-semibold text-gray-500 text-lg"></span>
            </router-link>
          </div>
          <!-- Primary Navbar items -->
          <div class="hidden md:flex items-center space-x-1">
            <router-link
              class="py-4 px-1 text-black font-semibold"
              :to="{ name: 'about' }"
            >
              Sobre o Cellairis Plus
            </router-link>
            <router-link
              class="py-4 px-1 text-black font-semibold"
              :to="{ name: 'store' }"
            >
              Nossas lojas
            </router-link>
            <template v-if="showCRM">
              <router-link
                class="py-4 px-1 text-black font-semibold"
                :to="{ name: 'crm' }"
              >
                Atendimento
              </router-link>
            </template>
            <template v-if="showProdutos">
              <router-link
                class="py-4 px-1 text-black font-semibold"
                :to="{ name: 'produtos' }"
              >
                Produtos Participantes
              </router-link>
            </template>
            <template v-if="showControlPanel">
              <router-link
                class="py-4 px-1 text-black font-semibold"
                :to="{ name: 'controlpanel' }"
              >
                Painel de Controle
              </router-link>
            </template>
            <template v-if="showSetting">
              <router-link
                class="py-4 px-1 text-black font-semibold"
                :to="{ name: 'setting' }"
              >
                Configuração
              </router-link>
            </template>
            <template v-if="showMyClub">
              <router-link
                class="py-4 px-2 text-black font-semibold"
                :to="{ name: 'myclub' }"
              >
                Meu clube
              </router-link>
            </template>
          </div>
        </div>
        <!-- Secondary Navbar items -->
        <div class="hidden md:flex items-center space-x-1">
          <a
            v-if="!userLoggedIn"
            @click.prevent="toggleAuthModal"
            href=""
            class="
              py-2
              px-6
              font-semibold
              text-black
              bg-orange-500
              rounded
              hover:bg-white
            "
            >Acessar minha conta</a
          >
          <template v-else>
            <span class="font-semibold text-black text-base">{{ displayName }}</span>
            <a
              href="#"
              @click.prevent="signout"
              class="
                py-2
                px-3
                font-semibold
                text-white
                bg-orange
                rounded
                hover:bg-black
                hover:text-white
              "
              >Sair</a
            >
          </template>
        </div>
        <!-- Mobile menu button -->
        <div class="md:hidden flex items-center">
          <button
            class="outline-none mobile-menu-button"
            @click.prevent="hideMobileMenu = !hideMobileMenu"
          >
            <svg
              class="w-6 h-6 text-gray-500 hover:text-green-500"
              x-show="!showMenu"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
    <!-- mobile menu -->
    <div class="mobile-menu" :class="{ hidden: hideMobileMenu }">
      <ul class="">
        <li class="active">
          <router-link
            @click="hideMobileMenu = !hideMobileMenu"
            class="
              block
              py-4
              px-2
              text-white
              font-semibold
              bg-purple-500
              hover:bg-white
            "
            :to="{ name: 'about' }"
          >
            Sobre o clube
          </router-link>
        </li>
        <li>
          <router-link
            @click="hideMobileMenu = !hideMobileMenu"
            class="
              block
              py-4
              px-2
              text-white
              font-semibold
              bg-purple-500
              hover:bg-white
            "
            :to="{ name: 'store' }"
          >
            Nossas lojas
          </router-link>
        </li>
        <li v-if="showCRM">
          <router-link
            @click="hideMobileMenu = !hideMobileMenu"
            class="
              block
              py-4
              px-2
              text-white
              font-semibold
              bg-purple-500
              hover:bg-white
            "
            :to="{ name: 'crm' }"
          >
            Atendimento
          </router-link>
        </li>
        <li v-if="showProdutos">
          <router-link
            @click="hideMobileMenu = !hideMobileMenu"
            class="
              block
              py-4
              px-2
              text-white
              font-semibold
              bg-purple-500
              hover:bg-white
            "
            :to="{ name: 'produtos' }"
          >
            Produtos
          </router-link>
        </li>
        <li v-if="showControlPanel">
          <router-link
            @click="hideMobileMenu = !hideMobileMenu"
            class="
              block
              py-4
              px-2
              text-white
              font-semibold
              bg-purple-500
              hover:bg-white
            "
            :to="{ name: 'controlpanel' }"
          >
            Painel de Controle
          </router-link>
        </li>
        <li v-if="showSetting">
          <router-link
            @click="hideMobileMenu = !hideMobileMenu"
            class="
              block
              py-4
              px-2
              text-white
              font-semibold
              bg-purple-500
              hover:bg-white
            "
            :to="{ name: 'setting' }"
          >
            Configuração
          </router-link>
        </li>
        <li v-if="showMyClub">
          <router-link
            @click="hideMobileMenu = !hideMobileMenu"
            class="
              block
              py-4
              px-2
              text-white
              font-semibold
              bg-purple-500
              hover:bg-white
            "
            :to="{ name: 'myclub' }"
          >
            Meu clube
          </router-link>
        </li>
        <li v-if="!userLoggedIn">
          <a
            @click.prevent="
              hideMobileMenu = !hideMobileMenu;
              toggleAuthModal();
            "
            href=""
            class="
              block
              py-4
              px-2
              text-orange
              font-semibold
              bg-purple-500
              hover:bg-orange
            "
          >
            Acessar minha conta
          </a>
        </li>
        <li v-if="userLoggedIn">
          <a
            @click.prevent="
              hideMobileMenu = !hideMobileMenu;
              signout();
            "
            href=""
            class="
              block
              py-4
              px-2
              text-black
              font-semibold
              bg-purple-500
              hover:bg-white
            "
          >
            Sair
          </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import { auth, usersCollection } from "@/includes/firebase";

export default {
  // eslint-disable-next-line
  name: "Header",
  computed: {
    ...mapState(["userLoggedIn"]),
    showCRM() {
      return this.userLoggedIn && this.profile !== "cliente";
    },
    showControlPanel() {
      return (
        this.userLoggedIn
        && this.profile !== "cliente"
        && this.profile !== "vendedor"
      );
    },
    showProdutos() {
      return (
        this.userLoggedIn
        && this.profile === "vendedor"
      );
    },
    showSetting() {
      return (
        this.userLoggedIn
        && this.profile !== "cliente"
        && this.profile !== "vendedor"
      );
    },
    showMyClub() {
      return this.userLoggedIn && this.profile === "cliente";
    },
  },
  data() {
    return {
      displayName: "",
      profile: "cliente",
      hideMobileMenu: true,
    };
  },
  async mounted() {
    if (auth.currentUser != null) {
      this.displayName = `Oi, ${auth.currentUser.displayName}`;
      const docSnapshot = await usersCollection.doc(auth.currentUser.uid).get();
      this.profile = docSnapshot.data().profile;
    }
  },
  methods: {
    ...mapMutations(["toggleAuthModal"]),
    signout() {
      this.$store.dispatch("signout", {
        router: this.$router,
        route: this.$route,
      });

      this.displayName = "";
      this.profile = "cliente";

      // console.log(this.$route);
      if (this.$route.meta.requiresAuth) {
        this.$router.push({ name: "home" });
      }
    },
    toggleAuthModal() {
      this.$store.commit("toggleAuthModal");
    },
  },
};
</script>
