<template>
  <main>
  <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-store text-orange"></i>
        </button>
        <div class="z-50 text-left ml-8">
          <!-- Info -->
          <div class="text-3xl font-bold">Cadastro de Lojas</div>
        </div>
      </div>
    </section>
    <!-- Main Content -->
    <section class="container mx-auto">
      <div
        class="bg-white rounded border border-gray-200 relative flex flex-col"
      >
        <!-- component -->
        <div class="p-5">
          <div class="mt-8 p-4">
            <!-- Forms -->
            <div>
              <div class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-2" role="alert" v-if="alertMsg === ' Importação realizada' || alertMsg === 'CADASTRO ALTERADO COM SUCESSO!' || alertMsg === ' Cadastro realizado'">
                <strong class="font-bold">Sucesso!</strong>
                <span class="block sm:inline">{{alertMsg}}.</span>
                  <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
              </div>
              <div class="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-2" role="alert" v-if="alertMsg === ' estamos realizando o cadastro' || alertMsg === ' estamos realizando a importação' || alertMsg === 'ESTAMOS ATUALIZANDO OS DADOS.'">
                <strong class="font-bold">Aguarde!</strong>
                <span class="block sm:inline">{{alertMsg}}.</span>
                  <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
              </div>
              <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-2" role="alert" v-if="alertMsg !== '' && alertMsg !== ' estamos realizando o cadastro' && alertMsg !== ' Atualização realizada!' && alertMsg !== ' Dados atualizados' && alertMsg !== ' Importação realizada' && alertMsg !== ' Cadastro realizado' && alertMsg === 'NÃO FOI POSSÍVEL ATUALIZAR OS DADOS!' && alertMsg !== ' estamos realizando a atualização'">
                <strong class="font-bold">ERRO!</strong>
                <span class="block sm:inline">{{alertMsg}}.</span>
                  <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
              </div>
              <div
                class="
                  flex flex-col
                  md:flex-row
                  border
                  rounded
                  border-gray-200
                  bg-gray-100
                "
              >
                <span
                  class="
                    font-bold
                    text-gray-600 text-xs
                    leading-8
                    uppercase
                    ml-3
                  "
                >
                  Dados da Loja
                </span>
              </div>
              <div>
                <p
                  class="
                    text-red-600 text-xs
                    leading-8
                    uppercase
                    ml-3
                  "
                >para alterar uma loja informe o cnpj</p>
              </div>
              <vee-form
                :validation-schema="lojaSchema"
                ref="frmDados"
              >
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Regional
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="regional"
                        v-model="loja.regional"
                        placeholder="Digite a Regional..."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="regional"
                    />
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Grupo Econômico
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="grupoEconomico"
                        v-model="loja.grupoEconomico"
                        placeholder="Digite o Grupo Econômico.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="grupoEconomico"
                    />
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      CNPJ
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="cnpj"
                        v-model="loja.cnpj"
                        placeholder="Digite o CNPJ..."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                        v-mask="'##.###.###/####-##'"
                        @blur.prevent="consultarCNPJ()"
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="cnpj"
                    />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Razão Social
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="razao"
                        v-model="loja.razao"
                        placeholder="Digite a Razão Social.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="razao"
                    />
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Nome da Loja
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="nome"
                        v-model="loja.nome"
                        placeholder="Digite o Nome da Loja..."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="nome"
                    />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Endereço
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="endereco"
                        v-model="loja.endereco"
                        placeholder="Digite o Endereco.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="endereco"
                    />
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Cidade
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="cidade"
                        v-model="loja.cidade"
                        placeholder="Digite a Cidade.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="cidade"
                    />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Bairro
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="bairro"
                        v-model="loja.bairro"
                        placeholder="Digite o Bairro.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="bairro"
                    />
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      CEP
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="cep"
                        v-model="loja.cep"
                        placeholder="Digite o CEP.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                        v-mask="'#####-###'"
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="cep"
                    />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      UF
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="uf"
                        v-model="loja.uf"
                        placeholder="Digite o UF.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="uf"
                    />
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Estado
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="uf_nome"
                        v-model="loja.uf_nome"
                        placeholder="Digite o Estado.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="uf_nome"
                    />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Mapa
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="mapa"
                        v-model="loja.mapa"
                        placeholder="Digite a URL do mapa.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="Mapa"
                    />
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                  </div>
                </div>
                <div
                  class="
                    mt-10
                    flex flex-col
                    md:flex-row
                    border
                    rounded
                    border-gray-200
                    bg-gray-100
                  "
                >
                  <span
                    class="
                      font-bold
                      text-gray-600 text-xs
                      leading-8
                      uppercase
                      ml-3
                    "
                  >
                    Dados do Gerente da Loja
                  </span>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Gerente
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="gerente"
                        v-model="loja.gerente"
                        placeholder="Digite o Nome do Gerente.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="gerente"
                    />
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      E-mail do Gerente
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="emailGerente"
                        v-model="loja.emailGerente"
                        placeholder="Digite o Email do Gerente.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="emailGerente"
                    />
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Celular do Gerente
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="celularGerente"
                        v-model="loja.celularGerente"
                        placeholder="Digite o Celular do Gerente.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                        v-mask="'(##) #####-####'"
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="celularGerente"
                    />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      cpf do gerente
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="cpfGerente"
                        v-model="loja.cpfGerente"
                        placeholder="Digite o CPF do Gerente.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                        v-mask="'###.###.###-##'"
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="cpfGerente"
                    />
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Supervisor
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="supervisor"
                        v-model="loja.supervisor"
                        placeholder="Digite o Supervisor.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="supervisor"
                    />
                  </div>
                </div>
              </vee-form>
              <vee-form enctype="multipart/form-data">
                <div
                  class="
                    mt-10
                    flex flex-col
                    md:flex-row
                    border
                    rounded
                    border-gray-200
                    bg-gray-100
                  "
                >
                  <span
                    class="
                      font-bold
                      text-gray-600 text-xs
                      leading-8
                      uppercase
                      ml-3
                    "
                  >
                    IMPORTAÇÃO DE DIVERSAS LOJAS
                  </span>
                </div>
                <input
                  class="form-control
                    block
                    w-1/2
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                  "
                  type="file" @change="onFileChange" accept=".csv"
                />
              </vee-form>
            </div>
            <div class="flex p-2 mt-4">
              <div class="flex-auto flex flex-row-reverse">
                <button
                  class="
                    text-base
                    ml-2
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  @click.prevent="cadastrar()"
                >
                  Cadastrar
                </button>
                <button
                  class="
                    text-base
                    hover:scale-110
                    focus:outline-none
                    ml-2
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  @click.prevent="importar()"
                >
                  Importar
                </button>
                <button
                  class="
                    text-base
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  @click.prevent="alterar()"
                >
                  Alterar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mask } from "vue-the-mask";
import { lojasCollection } from "@/includes/firebase";

export default {
  name: "CadastroLojas",
  data() {
    return {
      alertMsg: "",
      file: "",
      lista: [],
      loja: {
        codigo: "",
        regional: "",
        grupoEconomico: "",
        cnpj: "",
        razao: "",
        nome: "",
        endereco: "",
        cidade: "",
        bairro: "",
        cep: "",
        uf: "",
        uf_nome: "",
        gerente: "",
        emailGerente: "",
        celularGerente: "",
        cpfGerente: "",
        supervisor: "",
        mapa: "",
      },
      lojaSchema: {
        regional: "required",
        cnpj: "required",
        razao: "required:min:5",
        nome: "required:min:3|max:50",
        endereco: "required",
        cidade: "required",
        bairro: "required",
        cep: "required:min:9",
        uf: "required|min:2",
        uf_nome: "required",
        gerente: "required:min:3|max:50",
        emailGerente: "required|email",
        celularGerente: "required|min:14",
        cpfGerente: "required|cpf",
        supervisor: "required:min:3|max:50",
        mapa: "required",
      },
    };
  },
  directives: { mask },
  methods: {
    // lê os dados do arquivo
    onFileChange(arq) {
      console.log(arq.target.result);
      const files = arq.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.target);
        this.lista.push(e.target.result.split("\r\n"));
      };
      reader.readAsText(files, 'ISO-8859-1');
    },
    async consultarCNPJ() {
      this.alertMsg = "";
      console.log(this.loja.cnpj);
      const strCNPJ = this.loja.cnpj;
      const snapshot = await lojasCollection.where("cnpj", "==", strCNPJ).get();
      console.log(snapshot);
      if (snapshot.docs.length > 0) {
        this.loja.codigo = snapshot.docs[0].id;
        this.loja.regional = snapshot.docs[0].data().regional;
        this.loja.grupoEconomico = snapshot.docs[0].data().grupoEconomico;
        this.loja.cnpj = snapshot.docs[0].data().cnpj;
        this.loja.razao = snapshot.docs[0].data().razao;
        this.loja.nome = snapshot.docs[0].data().nome;
        this.loja.endereco = snapshot.docs[0].data().endereco;
        this.loja.cidade = snapshot.docs[0].data().cidade;
        this.loja.bairro = snapshot.docs[0].data().bairro;
        this.loja.cep = snapshot.docs[0].data().cep;
        this.loja.uf = snapshot.docs[0].data().uf;
        this.loja.uf_nome = snapshot.docs[0].data().uf_nome;
        this.loja.gerente = snapshot.docs[0].data().gerente;
        this.loja.emailGerente = snapshot.docs[0].data().emailGerente;
        this.loja.celularGerente = snapshot.docs[0].data().celularGerente;
        this.loja.cpfGerente = snapshot.docs[0].data().cpfGerente;
        this.loja.supervisor = snapshot.docs[0].data().supervisor;
        this.loja.mapa = snapshot.docs[0].data().mapa;
      }
    },
    // grava os dados do csv
    async importar() {
      const lista = [];
      let qtdLinhas = 0;
      // eslint-disable-next-line
      this.lista[0].forEach(() => qtdLinhas += 1);
      for (let i = 0; i < qtdLinhas; i += 1) {
        // console.log(this.lista);
        lista[i] = this.lista[0][i].split(";");
      }
      for (let i = 1; i < lista.length; i += 1) {
        const loja = {
          regional: lista[i][1],
          grupoEconomico: lista[i][2],
          cnpj: lista[i][3],
          razao: lista[i][4],
          nome: lista[i][5],
          endereco: lista[i][6],
          cidade: lista[i][7],
          bairro: lista[i][8],
          cep: lista[i][9],
          uf: lista[i][10],
          uf_nome: lista[i][11],
          gerente: lista[i][12],
          emailGerente: lista[i][13],
          celularGerente: lista[i][14],
          cpfGerente: lista[i][15],
          supervisor: lista[i][16],
          mapa: lista[i][17],
        };
        if (loja.uf_nome !== '' && loja.uf_nome !== null && loja.regional !== '' && loja.regional !== null && loja.grupoEconomico !== '' && loja.cnpj !== '' && loja.razao !== '' && loja.nome !== '' && loja.endereco !== '' && loja.cidade !== '' && loja.bairro !== '' && loja.cep !== '' && loja.uf !== '' && loja.gerente !== '' && loja.emailGerente !== '' && loja.celularGerente !== '' && loja.supervisor !== '' && loja.supervisor !== null) {
          try {
            /* eslint-disable-next-line */
            await lojasCollection.add(loja);
          } catch (e) {
            /* eslint-disable-next-line */
            alert("Um erro inesperado aconteceu. Por favor, tente novamente.");
            /* eslint-disable-next-line */
            return;
          }
          /* eslint-disable-next-line */
          alert("Sucesso! Importação realizada");
        }
      }
    },
    async cadastrar() {
      const loja = {
        regional: this.loja.regional,
        grupoEconomico: this.loja.grupoEconomico,
        cnpj: this.loja.cnpj,
        razao: this.loja.razao,
        nome: this.loja.nome,
        endereco: this.loja.endereco,
        cidade: this.loja.cidade,
        bairro: this.loja.bairro,
        cep: this.loja.cep,
        uf: this.loja.uf,
        uf_nome: this.loja.uf_nome,
        gerente: this.loja.gerente,
        emailGerente: this.loja.emailGerente,
        celularGerente: this.loja.celularGerente,
        cpfGerente: this.loja.cpfGerente,
        supervisor: this.loja.supervisor,
        mapa: this.loja.mapa,
      };
      /* eslint-disable-next-line */
      if (this.loja.uf_nome !== '' && this.loja.uf_nome !== null && loja.regional !== '' && loja.regional !== null && loja.grupoEconomico !== '' && loja.cnpj !== '' && loja.razao !== '' && loja.nome !== '' && loja.endereco !== '' && loja.cidade !== '' && loja.bairro !== '' &&       loja.cep !== '' && loja.uf !== '' && loja.gerente !== '' && loja.emailGerente !== '' && loja.celularGerente !== '' && loja.supervisor !== '' && loja.supervisor !== null) {
        try {
          await lojasCollection.add(loja);
        } catch (e) {
          /* eslint-disable-next-line */
          alert("Um erro inesperado aconteceu. Por favor, tente novamente.");

          /* eslint-disable-next-line */
          return;
        }
      } else {
        /* eslint-disable-next-line */
        alert("Loja já existente");
        /* eslint-disable-next-line */
        return;
      }
      /* eslint-disable-next-line */
      this.loja.regional = "";
      this.loja.grupoEconomico = "";
      this.loja.cnpj = "";
      this.loja.razao = "";
      this.loja.nome = "";
      this.loja.endereco = "";
      this.loja.cidade = "";
      this.loja.bairro = "";
      this.loja.cep = "";
      this.loja.uf = "";
      this.loja.gerente = "";
      this.loja.emailGerente = "";
      this.loja.celularGerente = "";
      this.loja.cpfGerente = "";
      this.loja.supervisor = "";
      this.loja.mapa = "";
      /* eslint-disable-next-line */
      // this.reg_alert_variant = "bg-green-500";
      // this.reg_alert_msg = "Sucesso! Importação realizada.";
    },
    async alterar() {
      window.scroll(0, 0);
      this.alertMsg = "ESTAMOS ATUALIZANDO OS DADOS.";
      try {
        lojasCollection.doc(this.loja.codigo).update({
          supervisor: this.loja.supervisor,
          cpfGerente: this.loja.cpfGerente,
          celularGerente: this.loja.celularGerente,
          gerente: this.loja.gerente,
          regional: this.loja.regional,
          cnpj: this.loja.cnpj,
          razao: this.loja.razao,
          nome: this.loja.nome,
          endereco: this.loja.endereco,
          cidade: this.loja.cidade,
          bairro: this.loja.bairro,
          cep: this.loja.cep,
          uf: this.loja.uf,
          emailGerente: this.loja.emailGerente,
          mapa: this.loja.mapa,
        });
      } catch (error) {
        this.alertMsg = "NÃO FOI POSSÍVEL ATUALIZAR OS DADOS!";
      }
      this.alertMsg = "CADASTRO ALTERADO COM SUCESSO!";
    },
  },
};
</script>
