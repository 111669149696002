<template>
  <main>
  <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-store text-orange"></i>
        </button>
        <div class="z-50 text-left ml-8">
          <!-- Info -->
          <div class="text-3xl font-bold">Cadastro de Usuários</div>
        </div>
      </div>
    </section>
    <!-- Main Content -->
    <section class="container mx-auto">
      <div
        class="bg-white rounded border border-gray-200 relative flex flex-col"
      >
        <!-- component -->
        <div class="p-5">
          <div class="mt-8 p-4">
            <!-- Forms -->
            <div>
              <div class="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-2" role="alert" v-if="msgCadastro === ' Importação realizada' || msgCadastro === ' Dados atualizados' || msgCadastro === ' Cadastro realizado'">
                <strong class="font-bold">Sucesso!</strong>
                <span class="block sm:inline">{{msgCadastro}}.</span>
                  <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
              </div>
              <div class="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded relative mb-2" role="alert" v-if="msgCadastro === ' estamos realizando o cadastro' || msgCadastro === ' estamos realizando a importação' || msgCadastro === ' estamos realizando a atualização'">
                <strong class="font-bold">Aguarde,</strong>
                <span class="block sm:inline">{{msgCadastro}}.</span>
                  <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
              </div>
              <div class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-2" role="alert" v-if="msgCadastro !== '' && msgCadastro !== ' estamos realizando o cadastro' && msgCadastro !== ' Atualização realizada!' && msgCadastro !== ' Dados atualizados' && msgCadastro !== ' Importação realizada' && msgCadastro !== ' Cadastro realizado' && msgCadastro !== ' estamos realizando a importação' && msgCadastro !== ' estamos realizando a atualização'">
                <strong class="font-bold">Erro no cadastro!</strong>
                <span class="block sm:inline">{{msgCadastro}}.</span>
                  <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
                  <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
                </span>
              </div>
              <div
                class="
                  flex flex-col
                  md:flex-row
                  border
                  rounded
                  border-gray-200
                  bg-gray-100
                "
              >
                <span
                  class="
                    font-bold
                    text-gray-600 text-xs
                    leading-8
                    uppercase
                    ml-3
                  "
                >
                  Dados do Usuario
                </span>
              </div>
              <div>
                <p
                  class="
                    text-red-600 text-xs
                    leading-8
                    uppercase
                    ml-3
                  "
                >para alterar um usuário informe o cpf</p>
              </div>
              <vee-form
                :validation-schema="usuarioSchema"
                ref="frmDados"
              >
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Nome Completo
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="nome"
                        v-model="usuario.nome"
                        placeholder="Digite o nome.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                          bg-white
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="nome"
                    />
                    <span
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      v-show="alertMsg !== ''"
                    >
                      {{ alertMsg }}
                    </span>
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Email
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="email"
                        placeholder="Digite o Email..."
                        v-model="usuario.email"
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="email"
                    />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      CPF
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="cpf"
                        placeholder="Digite o CPF.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                        v-mask="'###.###.###-##'"
                        v-model="usuario.cpf"
                        @blur.prevent="consultarCPF()"
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="cpf"
                    />
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                    Celular
                  </div>
                  <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="celular"
                        v-model="usuario.celular"
                        placeholder="Digite o Celular.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                        v-mask="'(##) #####-####'"
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="celular"
                    />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                    Loja
                  </div>
                  <div
                    class="
                      bg-white
                      my-2
                      flex
                      border border-gray-200
                      rounded
                      svelte-1l8159u
                    "
                  >
                    <Multiselect
                      v-model="this.usuario.loja"
                      :options="listaLojas"
                      :searchable="true"
                      @change="selecionarLoja"
                      label="nome"
                      class="
                        px-2
                        appearance-none
                        outline-none
                        w-full
                        text-gray-800
                        border-none
                      "
                    />
                  </div>
                  <span
                    class="text-red-600 font-bold text-xs uppercase leading-8"
                    v-show="!isValidLoja()"
                  >
                    O campo Loja é obrigatório!</span
                  >
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      CNPJ
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="cnpj"
                        v-model="usuario.cnpj"
                        placeholder="##.###.###/####-##"
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                        disabled
                        v-mask="'##.###.###/.####-##'"
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="cnpj"
                    />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                    Tipo de Usuário
                  </div>
                  <div
                    class="
                      bg-white
                      my-2
                      flex
                      border border-gray-200
                      rounded
                      svelte-1l8159u
                    "
                  >
                    <Multiselect
                      v-model="this.usuario.profile"
                      :options="listaprofile"
                      :searchable="true"
                      @change="selecionarProfile"
                      label="nome"
                      class="
                        px-2
                        appearance-none
                        outline-none
                        w-full
                        text-gray-800
                        border-none
                      "
                    />
                  </div>
                  <span
                    class="text-red-600 font-bold text-xs uppercase leading-8"
                    v-show="!isValidprofile()"
                  >
                    O campo Tipo de Usuário é obrigatório!</span
                  >
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                  </div>
                </div>
              </vee-form>
              <vee-form enctype="multipart/form-data">
                <div
                  class="
                    font-bold
                    h-6
                    mt-24
                    text-gray-600 text-xs
                    leading-8
                    uppercase
                  "
                  >
                  Arquivo para importação
                </div>
                <input
                  class="form-control
                    block
                    w-1/2
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                  "
                  type="file" @change="onFileChange" accept=".csv"
                />
                <p
                  class="
                    text-red-600 text-xs
                    leading-8
                    uppercase
                    ml-3
                  "
                >Selecione um arquivo csv para cadastrar um lote de usuários.</p>
                <div>
              </div>
              </vee-form>
            </div>
            <div class="flex p-2 mt-4">
              <div class="flex-auto flex flex-row-reverse">
                <button
                  class="
                    text-base
                    ml-2
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  @click.prevent="cadastrar()"
                >
                  Cadastrar
                </button>
                <button
                  class="
                    text-base
                    hover:scale-110
                    focus:outline-none
                    ml-2
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  id="importar"
                  @click.prevent="importar()"
                >
                  Importar
                </button>
                <button
                  class="
                    ml-2
                    text-base
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  id="atualizar"
                  @click.prevent="alterar()"
                >
                  Atualizar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mask } from "vue-the-mask";
import Multiselect from "@vueform/multiselect";
import {
  usersCollection,
  lojasCollection,
  // auth,
} from "@/includes/firebase";

export default {
  name: "CadastroUsuario",
  data() {
    return {
      listaprofile: [{ value: 'gerente', nome: 'Gerente' }, { value: 'vendedor', nome: 'Vendedor' }],
      alertMsg: "",
      msgCadastro: "",
      file: "",
      lista: [],
      listaLojas: [],
      emailAtual: "",
      usuario: {
        codigo: "",
        nome: "",
        email: "",
        cpf: "",
        loja: "",
        cnpj: "",
        celular: "",
        profile: "",
        senha: "",
      },
      usuarioSchema: {
        codigo: "",
        nome: "required:min:5",
        email: "required|email",
        loja: "required",
        cpf: "required|cpf",
        celular: "required|min:14",
        profile: "required",
        cnpj: "required",
      },
    };
  },
  directives: { mask },
  components: {
    Multiselect,
  },
  async created() {
    this.listarLojas();
  },
  mounted() {
    document.getElementById("importar").disabled = true;
    document.getElementById("importar").classList.add("bg-gray-400");
    document.getElementById("importar").classList.remove("bg-orange");
    document.getElementById("atualizar").disabled = true;
    document.getElementById("atualizar").classList.add("bg-gray-400");
    document.getElementById("atualizar").classList.remove("bg-orange");
  },
  methods: {

    async listarLojas() {
      const snapshot = await lojasCollection.orderBy("nome", "asc").get();

      snapshot.docs.forEach((document) => {
        const loja = {
          value: document.data().nome,
          nome: document.data().nome,
        };
        this.listaLojas.push(loja);
      });
    },
    async selecionarLoja(value) {
      this.usuario.loja = value;

      const snapshot = await lojasCollection
        .where("nome", "==", value)
        .get();
      // eslint-disable-next-line
      snapshot.docs.forEach((document) => this.usuario.cnpj = document.data().cnpj);
    },
    async selecionarProfile(value) {
      this.usuario.profile = value;
    },
    // lê os dados do arquivo
    onFileChange(arq) {
      document.getElementById("importar").disabled = false;
      document.getElementById("importar").classList.add("bg-orange");
      document.getElementById("importar").classList.remove("bg-gray-400");
      const files = arq.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        console.log(e.target);
        this.lista.push(e.target.result.split("\r\n"));
      };
      reader.readAsText(files, 'ISO-8859-1');
    },
    // grava os dados do csv
    async importar() {
      this.msgCadastro = " estamos realizando a importação";

      const lista = [];
      let qtdLinhas = 0;
      // eslint-disable-next-line
      this.lista[0].forEach(() => qtdLinhas += 1);
      for (let i = 0; i < qtdLinhas; i += 1) {
        // console.log(this.lista);
        if (this.lista[0][i] !== '') lista[i] = this.lista[0][i].split(";");
      }
      for (let i = 1; i < lista.length; i += 1) {
        const usuario = {
          cnpj: lista[i][0],
          loja: lista[i][1],
          nome: lista[i][2],
          cpf: lista[i][3],
          email: lista[i][4],
          celular: lista[i][5],
          profile: lista[i][6],
        };
        // console.log(usuario);
        if ((usuario.nome !== '' && usuario.nome !== null) && (usuario.email !== '' && usuario.email !== null) && (usuario.cpf !== '' && usuario.cpf !== null) && (usuario.celular !== '' && usuario.celular !== null) && (usuario.loja !== '' && usuario.loja !== null) && (usuario.profile !== '' && usuario.profile !== null)) {
          try {
            // eslint-disable-next-line
            await this.$store.dispatch("register", {
              senha: Math.random().toString(36).slice(-8),
              nome: usuario.nome.replace(/\d/g, ''),
              email: usuario.email,
              cpf: usuario.cpf,
              celular: usuario.celular,
              loja: usuario.loja,
              cnpj: usuario.cnpj,
              profile: usuario.profile,
            });
            this.msgCadastro = " Importação realizada";
          } catch (e) {
            /* eslint-disable-next-line */
            this.msgCadastro = " Um erro inesperado aconteceu, se o erro persistir entre em contato com o suporte";
            console.log(e);
            /* eslint-disable-next-line */
            return;
          }
        }
      }
    },
    async cadastrar() {
      window.scroll(0, 0);
      if (this.usuario.codigo === "") {
        this.msgCadastro = " estamos realizando o cadastro";
        this.usuario.senha = Math.random().toString(36).slice(-8);
        const usuario = {
          senha: this.usuario.senha,
          nome: this.usuario.nome.replace(/\d/g, ''),
          email: this.usuario.email.replace(" ", ""),
          cpf: this.usuario.cpf,
          celular: this.usuario.celular,
          loja: this.usuario.loja,
          cnpj: this.usuario.cnpj,
          profile: this.usuario.profile,
        };
        /* eslint-disable-next-line */
        if ((usuario.nome !== '' && usuario.nome !== null) && (usuario.email !== '' && usuario.email !== null) && (usuario.cpf !== '' && usuario.cpf !== null) && (usuario.celular !== '' && usuario.celular !== null) && (usuario.loja !== '' && usuario.loja !== null) && (usuario.profile !== '' && usuario.profile !== null)) {
          try {
            await this.$store.dispatch("register", {
              senha: usuario.senha,
              nome: usuario.nome.replace(/\d/g, ''),
              email: usuario.email.replace(" ", ""),
              cpf: usuario.cpf,
              celular: usuario.celular,
              loja: usuario.loja,
              cnpj: usuario.cnpj,
              profile: usuario.profile,
            });
            this.msgCadastro = " Cadastro realizado";
          } catch (e) {
            /* eslint-disable-next-line */
            this.msgCadastro = " Cadastro não realizado, entre em contato com o suporte para obter ajuda";
            console.log(e);
            /* eslint-disable-next-line */
            return;
          }
        }
      } else {
        this.msgCadastro = " Usuário já existente";
      }
    },
    isValidLoja() {
      if ((this.usuario.loja !== "" && this.usuario.loja !== null) === false) this.usuario.cnpj = "";
      return this.usuario.loja !== "" && this.usuario.loja !== null;
    },
    isValidprofile() {
      return this.usuario.profile !== "" && this.usuario.profile !== null;
    },
    async consultarCPF() {
      const strCPF = this.usuario.cpf; // .replaceAll(".", "").replaceAll("-", "");
      const snapshot = await usersCollection.where("cpf", "==", strCPF).get();
      if (snapshot.docs.length > 0) {
        this.usuario.codigo = snapshot.docs[0].id;
        this.usuario.cpf = snapshot.docs[0].data().cpf;
        this.usuario.nome = snapshot.docs[0].data().name;
        this.usuario.celular = snapshot.docs[0].data().celular;
        this.usuario.email = snapshot.docs[0].data().email;
        this.usuario.loja = snapshot.docs[0].data().loja;
        this.usuario.profile = snapshot.docs[0].data().profile;
        this.usuario.cnpj = snapshot.docs[0].data().cnpj;
        this.emailAtual = snapshot.docs[0].data().email;
        // console.log(snapshot.docs[0].data().profile);
        // console.log(this.usuario.profile);
        document.getElementById("atualizar").disabled = false;
        document.getElementById("atualizar").classList.add("bg-orange");
        document.getElementById("atualizar").classList.remove("bg-gray-400");
      }
    },
    async alterar() {
      if (this.usuario.codigo !== "") {
        this.msgCadastro = " estamos atualizando os dados";
        try {
          if (this.usuario.email !== this.emailAtual) {
            this.$store.dispatch("atualizaEmailGerente", {
              uid: this.usuario.codigo,
              email: this.usuario.email,
            });
            await usersCollection.doc(this.usuario.codigo).update({
              celular: this.usuario.celular,
              loja: this.usuario.loja,
              cnpj: this.usuario.cnpj,
              email: this.usuario.email,
            });
          } else {
            await usersCollection.doc(this.usuario.codigo).update({
              celular: this.usuario.celular,
              loja: this.usuario.loja,
              cnpj: this.usuario.cnpj,
            });
          }
          // eslint-disable-next-line
          this.msgCadastro = " Dados atualizados";
        } catch (e) {
          console.log(e);
          // eslint-disable-next-line
          this.msgCadastro = " Atualização não efetuada";
        }
      }
    },

  },
};
</script>
