<template>
  <main>
    <!-- Main Content -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-address-card text-orange"></i>
        </button>
        <div class="z-50 text-left ml-8">
          <!-- Info -->
          <div class="text-3xl font-bold">Meu Perfil</div>
        </div>
      </div>
    </section>
    <section class="container mx-auto py-8">
      <div
        class="bg-white rounded border border-gray-200 relative flex flex-col"
      >
        <div
          class="
            px-6
            pt-6
            pb-5
            font-bold
            border-b border-gray-200
            bg-blue
            text-center
          "
        >
        <span class="card-title text-2xl text-white">Dados Cadastrais</span>
        </div>
        <form>
          <div class="mt-8 p-4">
            <!-- Forms -->
            <div v-show="true">
              <div class="flex flex-col md:flex-row">
                <div class="w-full mx-2 flex-1 svelte-1l8159u">
                  <div
                    class="
                      font-bold
                      h-6
                      mt-3
                      text-gray-600 text-xs
                      leading-8
                      uppercase
                    "
                  >
                    CPF
                  </div>
                  <div
                    class="
                      bg-gray-200
                      my-2
                      flex
                      border border-gray-200
                      rounded
                      svelte-1l8159u
                    "
                  >
                    <div
                      class="
                        px-2
                        appearance-none
                        outline-none
                        w-full
                        text-gray-800
                        border-non"
                    >{{this.user.cpf}}
                    </div>
                  </div>
                </div>
                <div class="w-full mx-2 flex-1 svelte-1l8159u">
                  <div
                    class="
                      font-bold
                      h-6
                      mt-3
                      text-gray-600 text-xs
                      leading-8
                      uppercase
                    "
                  >
                    Celular
                  </div>
                  <div
                    class="
                      bg-gray-200
                      my-2
                      flex
                      border border-gray-200
                      rounded
                      svelte-1l8159u
                    "
                  >
                    <div
                      class="
                        px-2
                        appearance-none
                        outline-none
                        w-45
                        text-gray-800
                        border-none
                      "
                    >{{this.user.celular}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex flex-col md:flex-row">
                <div class="w-full mx-2 flex-1 svelte-1l8159u">
                  <div
                    class="
                      font-bold
                      h-6
                      mt-3
                      text-gray-600 text-xs
                      leading-8
                      uppercase
                    "
                  >
                    Nome
                  </div>
                  <div
                    class="
                      bg-white
                      my-2
                      flex
                      border border-gray-200
                      rounded
                      svelte-1l8159u
                    "
                  >
                    <div
                      label="nome"
                      class="
                        px-2
                        appearance-none
                        outline-none
                        w-full
                        text-gray-800
                        border-none
                      "
                    >{{this.user.nome}}
                    </div>
                  </div>
                </div>
                <div class="w-full mx-2 flex-1 svelte-1l8159u">
                  <div
                    class="
                      font-bold
                      h-6
                      mt-3
                      text-gray-600 text-xs
                      leading-8
                      uppercase
                    "
                  >
                    E-mail
                  </div>
                  <div
                    class="
                      bg-white
                      my-2
                      flex
                      border border-gray-200
                      rounded
                      svelte-1l8159u
                    "
                  >
                    <div
                      class="
                        px-2
                        appearance-none
                        outline-none
                        w-full
                        text-gray-800
                        border-none
                      "
                    >{{this.user.email}}
                    </div>
                  </div>
                </div>
                <div class="w-full mx-2 flex-1 svelte-1l8159u">
                </div>
              </div>
              <div class="
                my-2
                p-10
                flex
                justify-center
                items-center
                w-full">
                <button
                  class="
                    text-base
                    ml-2
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-1
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    bg-orange
                    text-white
                    duration-200
                    ease-in-out
                    transition"
                  @click.prevent="ModalAtualizar(this.user)">
                  Editar</button>
                  <button
                  class="
                    text-base
                    ml-2
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-1
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    bg-orange
                    text-white
                    duration-200
                    ease-in-out
                    transition"
                  @click.prevent="ModalAtualizarSenha()">
                  Alterar senha</button>
              </div>
            </div>
          </div>
        </form>
        <!-- component -->
        <div class="p-5">
          <!--Modal Atualização de Perfil-->
          <div class="
            fixed
            space-y-3
            overflow-x-hidden
            overflow-y-auto
            inset-0
            flex
            justify-center
            items-center
            z-50
            "
            v-if="ToggleAcionar">
            <div class="
              max-w-2xl">
                <div class="bg-white
                px-6
                py-4
                w-full
                rounded
                shadow-2xl
                flex
                flex-col">
                  <div class="text=2xl
                    my-2
                    font-bold
                    text-center
                    ">
                    Editar Perfil
                  </div>
                  <div
                      class="
                        bg-white
                        my-2
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <label class="nome w-80">Nome</label>
                      <input
                        name="nome"
                        type="text"
                        label="nome"
                        v-model="user.nome"
                        class="
                          text-center
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                          border-none
                        "
                      />
                  </div>
                  <div
                      class="
                        bg-white
                        my-2
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <label class="email w-80">E-mail</label>
                      <input
                        name="email"
                        type="text"
                        label="email"
                        v-model="user.email"
                        class="
                          text-center
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                          border-none
                        "
                      />
                  </div>
                  <div>
                    <p
                      class="
                        text-red-600 text-xs
                        leading-8
                        uppercase
                      "
                    >Para concluir informe a senha</p>
                  </div>
                  <div
                      class="
                        bg-white
                        my-2
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <label class="email w-80">Senha</label>
                      <input
                        name="senha"
                        type="text"
                        label="senha"
                        v-model="user.senha"
                        class="
                          text-center
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                          border-none
                        "
                      />
                  </div>
                  <div class="inline-flex space-x-4
                    my-2">
                    <button type="submit" class="
                      inline-block
                      w-1/2
                      bg-blue
                      text-white
                      py-1.5
                      px-3
                      rounded
                      transition
                      hover:bg-black"
                      @click="gravaAtualizacao(user)">Atualizar</button>
                    <button type="submit" class="
                      inline-block
                      w-1/2
                      bg-red-600
                      text-white
                      py-1.5
                      px-3
                      rounded
                      transition
                      hover:bg-red-700"
                      @click="ToggleAcionar=false">Cancelar</button>
                  </div>
                </div>
              </div>
          </div>
          <div class="
            fixed
            space-y-3
            overflow-x-hidden
            overflow-y-auto
            inset-0
            flex
            justify-center
            items-center
            z-50
            "
            v-if="ToggleAlterarSenha">
            <div class="
              max-w-2xl">
                <div class="bg-white
                px-6
                py-4
                w-full
                rounded
                shadow-2xl
                flex
                flex-col">
                  <div class="text=2xl
                    my-2
                    font-bold
                    text-center
                    ">
                    Alterar Senha
                  </div>
                  <div
                      class="
                        bg-white
                        my-2
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <label class="nome w-80">Senha Atual</label>
                      <input
                        name="nome"
                        type="text"
                        label="nome"
                        v-model="user.senha"
                        class="
                          text-center
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                          border-none
                        "
                      />
                  </div>
                  <div
                      class="
                        bg-white
                        my-2
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <label class="email w-80">Nova Senha</label>
                      <input
                        name="email"
                        type="text"
                        label="email"
                        v-model="user.newPwd"
                        class="
                          text-center
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                          border-none
                        "
                      />
                  </div>
                  <div class="inline-flex space-x-4
                    my-2">
                    <button type="submit" class="
                      inline-block
                      w-1/2
                      bg-blue
                      text-white
                      py-1.5
                      px-3
                      rounded
                      transition
                      hover:bg-black"
                      @click="atualizaSenha(user.newPwd, user.senha)">Atualizar</button>
                    <button type="submit" class="
                      inline-block
                      w-1/2
                      bg-red-600
                      text-white
                      py-1.5
                      px-3
                      rounded
                      transition
                      hover:bg-red-700"
                      @click="ToggleAlterarSenha=false">Cancelar</button>
                  </div>
                </div>
              </div>
          </div>
          <!--Sucesso ao Acionar Atualização -->
          <div class="
            fixed
            space-y-3
            overflow-x-hidden
            overflow-y-auto
            inset-0
            flex
            justify-center
            items-center
            z-50
            "
            v-if="TogglSucessoAcionar">
            <div class="
              max-w-2xl">
                <div class="bg-white
                px-6
                py-4
                w-full
                rounded
                shadow-2xl
                flex
                flex-col">
                  <div class="text=2xl
                    my-2
                    font-bold
                    text-center
                    ">
                    Dados atualizados com sucesso !
                  </div>
                  <div
                      class="
                        bg-white
                        my-2
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                  </div>
                  <div class="inline-flex
                    my-2">
                    <button type="submit" class="
                      w-full
                      bg-green-600
                      text-white
                      py-1.5
                      px-3
                      rounded
                      transition
                      hover:bg-green-600"
                      @click="TogglSucessoAcionar=false">Ok</button>
                  </div>
                </div>
              </div>
          </div>
          <!--Falha ao Acionar Atualização-->
          <div class="
            fixed
            space-y-3
            overflow-x-hidden
            overflow-y-auto
            inset-0
            flex
            justify-center
            items-center
            z-50
            "
            v-if="ToggleFalhaAcionar">
            <div class="
              max-w-2xl">
                <div class="bg-white
                px-6
                py-4
                w-full
                rounded
                shadow-2xl
                flex
                flex-col">
                  <div class="text=2xl
                    my-2
                    font-bold
                    text-center
                    ">
                    Falha ao acionar reparo !
                  </div>
                  <div
                      class="
                        bg-white
                        my-2
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                  </div>
                  <div class="inline-flex
                    my-2">
                    <button type="submit" class="
                      w-full
                      bg-gray-400
                      text-white
                      py-1.5
                      px-3
                      rounded
                      transition
                      hover:bg-gray-600"
                      @click="ToggleFalhaAcionar=false">Ok</button>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div v-if="ToggleAcionar"
          class="absolute inset-0 z-40 opacity-25 bg-black">
        </div>
        <div v-if="TogglSucessoAcionar"
          class="absolute inset-0 z-40 opacity-25 bg-black">
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mask } from "vue-the-mask";
import {
  auth,
  usersCollection,
} from "@/includes/firebase";

export default {
  name: "PerfilFuncionario",
  data() {
    return {
      ToggleAcionar: false,
      ToggleAlterarSenha: false,
      TogglSucessoAcionar: false,
      emailAtual: "",
      user: {
        value: "",
        cpf: "",
        nome: "",
        email: "",
        celular: "",
        dataNascimento: "",
        newPwd: "",
        senha: "",
        emailNovo: "",
      },
    };
  },
  directives: { mask },
  components: {
  },
  async created() {
    this.mounted();
  },
  methods: {
    async mounted() {
      if (auth.currentUser != null) {
        const docSnapshot = await usersCollection.doc(auth.currentUser.uid).get();
        this.user.value = docSnapshot.id;
        this.user.cpf = docSnapshot.data().cpf;
        this.user.nome = docSnapshot.data().name;
        this.user.email = docSnapshot.data().email;
        this.user.celular = docSnapshot.data().celular;
        this.user.dataNascimento = docSnapshot.data().dataNascimento;
        this.emailAtual = docSnapshot.data().email;
      }
    },
    ModalAtualizar(user) {
      this.ToggleAcionar = !this.ToggleAcionar;
      this.user = user;
    },
    ModalAtualizarSenha() {
      this.ToggleAlterarSenha = !this.ToggleAlterarSenha;
    },
    async gravaAtualizacao(user) {
      if (user.email !== "" && user.email !== null && user.senha !== "" && user.senha !== null) {
        await this.$store.dispatch("atualizaEmail", {
          novoEmail: user.email,
          senha: user.senha,
        });
      }
      usersCollection.doc(user.value).update({
        name: user.nome,
        email: user.email,
      });
      this.TogglSucessoAcionar = !this.TogglSucessoAcionar;
      this.ToggleAcionar = false;
    },
    async atualizaSenha(newPwd, senha) {
      // eslint-disable-next-line
      if (newPwd !== "" && newPwd !== null && senha !== "" && senha !== null) {
        await this.$store.dispatch("atualizaSenha", {
          senhaAtual: senha,
          novaSenha: newPwd,
        });
      }
    },
    async atualizaEmail(emailNovo, senhaAtual) {
      if (emailNovo !== "" && emailNovo !== null && senhaAtual !== "" && senhaAtual !== null) {
        await this.$store.dispatch("atualizaEmail", {
          novoEmail: emailNovo,
          senha: senhaAtual,
        });
      }
    },
  },
};
</script>
