<template>
  <main>
    <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-store text-orange"></i>
          </button>
          <div class="z-50 text-left ml-8">
            <!-- Info -->
            <div class="text-3xl font-bold">Detalhamento</div>
            <div>
              Relação de vendas
            </div>
          </div>
        </div>
      </section>
      <!-- Main Content -->
      <section class="container mx-auto">
        <div
          class="bg-white rounded border border-gray-200 relative flex flex-col mb-8"
        >
          <!-- component -->
          <div class="p-5">
            <div class="p-4 flex justify-center" v-if="this.profile !== 'gerente'">
              <!-- Forms -->
              <div class="flex-col md:flex-row w-full flex">
                <div class="w-1/3 mx-2 flex-1 svelte-1l8159u">
                  <div
                    class="
                      bg-white
                      my-2
                      p-1
                      flex
                      justify-center
                      items-center
                      svelte-1l8159u
                      w-full
                      uppercase
                    "
                  >
                    <!-- <o-field label="Selecione um intervalo">
                      <o-datepicker :searchable="true" placeholder="Clique para selecionar" icon="calendar" v-model="dates" editable range class="border rounded border-gray-200 p-2"> </o-datepicker>
                    </o-field> -->
                    <o-field label="Data inicial:" class="text-xs uppercase font-bold text-gray-600 mt-3 w-1/3">
                      <o-datepicker :searchable="true" editable v-model="dataInicial" :locale="pt-br" placeholder="Clique para selecionar..." class="border rounded border-gray-200 py-3 px-2"> </o-datepicker>
                    </o-field>
                    <o-field label="Data final:" class="ml-8 text-xs uppercase font-bold text-gray-600 w-1/3">
                      <o-datepicker :searchable="true"  editable v-model="dataFinal" :locale="pt-br" placeholder="Clique para selecionar..." trap-focus class="border rounded border-gray-200 py-3 px-2"> </o-datepicker>
                    </o-field>
                  </div>
                </div>
                <div class="w-1/3 mx-2 flex-1 svelte-1l8159u">
                  <div
                    class="
                      bg-white
                      my-2
                      p-1
                      flex
                      justify-center
                      items-center
                      svelte-1l8159u
                    "
                  >
                    <Multiselect
                      name="filtro"
                      v-model="filtroSelecionado"
                      :options="listaFiltro"
                      @change="selecionarFiltro"
                      :searchable="true"
                      label="nome"
                      class="
                        p-1
                        px-2
                        appearance-none
                        outline-none
                        text-gray-800
                        w-full
                        m-1
                        mt-6
                      "
                    />
                    <!-- <button
                    class="
                      text-left
                      w-1/8
                      text-base
                      hover:scale-110
                      focus:outline-none
                      justify-left
                      flex
                      px-4
                      py-2
                      rounded
                      font-bold
                      cursor-pointer
                      hover:bg-black
                      bg-orange
                      text-white
                      border
                      duration-200
                      ease-in-out
                      border-white
                      transition
                      mt-5
                    "
                     @click.self="filtrar()"
                  >
                    Filtrar
                  </button>
                  <button
                    class="
                      text-left
                      w-1/8
                      text-base
                      hover:scale-110
                      focus:outline-none
                      justify-left
                      flex
                      px-4
                      py-2
                      rounded
                      font-bold
                      cursor-pointer
                      hover:bg-black
                      bg-orange
                      text-white
                      border
                      duration-200
                      ease-in-out
                      border-white
                      transition
                      mt-5
                    "
                     @click.self="carregaDados()"
                  >
                    Limpar
                  </button> -->
                  <button
                    class="
                      text-left
                      w-1/8
                      text-base
                      hover:scale-110
                      focus:outline-none
                      justify-left
                      flex
                      px-4
                      py-2
                      rounded
                      font-bold
                      cursor-pointer
                      hover:bg-black
                      bg-orange
                      text-white
                      border
                      duration-200
                      ease-in-out
                      border-white
                      transition
                      mt-5
                    "
                     @click.self="exportarExcel()"
                  >
                    Exportar
                  </button>
                  </div>
                </div>
                <!-- <div class="w-1/2 mx-2 flex-1 svelte-1l8159u">
                </div> -->
              </div>
            </div>
            <!-- <table class="border w-full" id="table-to-export">
              <thead class="border bg-red-500 text-white font-light">
                  <th class="border w-1/7">Marca</th>
                  <th class="border w-1/7">Modelo</th>
                  <th class="border w-1/7">Vendedor</th>
                  <th class="border w-1/7">CPF Vendedor</th>
                  <th class="border w-1/7">Loja</th>
                  <th class="border w-1/7">Data Adesão</th>
                  <th class="border w-1/7">Plano</th>
              </thead>
              <tbody class="border">
                <tr v-for="item in listaAssinaturas" :key ="item.Loja" class="text-sm">
                  <td class="border text-center py-2 w-1/7">{{item.Marca}}</td>
                  <td class="border text-center py-2 w-1/7">{{item.Modelo}}</td>
                  <td class="border text-center py-2 w-1/7">{{item.Vendedor}}</td>
                  <td class="border text-center py-2 w-1/7">{{item.CpfVendedor}}</td>
                  <td class="border text-center py-2 w-1/7">{{item.Loja}}</td>
                  <td class="border text-center py-2 w-1/7">{{item.Data.toDate().toLocaleDateString("pt-BR")}}</td>
                  <td class="border text-center py-2 w-1/7">{{item.Plano}}</td>
                </tr>
              </tbody>
            </table> -->
            <!-- <div class="flex justify-center text-sm">
              <div class="flex justify-center items-center text-white
              w-1/7 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-purple-400 h-12">
                CPF
              </div>
              <div class="flex justify-center items-center text-white border
              w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 bg-red-500 h-12">
                Marca
              </div>
              <div class="flex justify-center items-center text-white border
              w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 bg-red-500 h-12">
                Modelo
              </div>
              <div class="flex justify-center items-center text-white border
              w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 bg-red-500 h-12">
                Vendedor
              </div>
              <div class="flex justify-center items-center text-white border
              w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 bg-red-500 h-12">
                CPF Vendedor
              </div>
              <div class="flex justify-center items-center text-white border
              w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 bg-red-500 h-12">
                Loja
              </div>
              <div class="flex justify-center items-center text-white border
              w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 bg-red-500 h-12">
                Data Adesão
              </div>
              <div class="flex justify-center items-center text-white border
              w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 bg-red-500 h-12">
                Plano
              </div>
            </div>
            <div v-for="item in listaAssinaturas"  :key ="item.Loja" class="text-sm">
              <div class="flex justify-center">
                <div
                class="
                bg-gray-100
                my-2
                p-1
                flex
                justify-center
                items-center
                w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
                {{item.cpf}}
                </div>
                <div
                class="
                border
                p-1
                flex
                justify-center
                items-center
                w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 h-12">
                {{item.Marca}}
                </div>
                <div class="
                border
                p-1
                flex
                justify-center
                items-center
                w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 h-12"
                >
                {{item.Modelo}}</div>
                <div class="
                border
                p-1
                flex
                justify-center
                items-center
                w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 h-12"
                >
                {{item.Vendedor}}</div>
                <div class="
                border
                p-1
                flex
                justify-center
                items-center
                w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 h-12"
                >
                {{item.CpfVendedor}}</div>
                <div
                class="
                border
                p-1
                flex
                justify-center
                items-center
                w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 h-12">
                {{item.Loja}}
                </div>
                <div
                class="
                border
                p-1
                flex
                justify-center
                items-center
                w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 h-12">
                {{item.Data.toDate().toLocaleDateString("pt-BR")}}
                </div>
                <div
                class="
                border
                p-1
                flex
                justify-center
                items-center
                w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 h-12">
                {{item.Plano}}
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "@vueform/multiselect";
// import ProductService from './service/ProductService.js';
// import { parse } from "json2csv";
// import json2xls from "json2xls";
import * as XLSX from "xlsx";
import {
  auth,
  assinaturasCollection,
  usersCollection,
  modelosCollection,
  marcasCollection,
  lojasCollection,
} from "@/includes/firebase";

export default {
  name: "ReportDetails",
  computed: {
    ...mapState(["userLoggedIn"]),
  },
  data() {
    return {
      profile: "",
      carregamento: false,
      listaLojas: [],
      listaVendedores: [],
      listaCliente: [],
      listaAssinaturas: [],
      listaAssinaturasData: [],
      filtroSelecionado: "",
      listaFiltro: [],
      dates: [],
      dataInicial: null,
      dataFinal: null,
    };
  },
  components: {
    Multiselect,
  },
  async mounted() {
    if (auth.currentUser != null) {
      const docSnapshot = await usersCollection.doc(auth.currentUser.uid).get();
      this.profile = docSnapshot.data();
    }
    this.carregaDados();
  },
  methods: {
    async carregaDados() {
      this.listaAssinaturas = [];
      this.filtroSelecionado = "";
      // this.listaFiltro = [];
      this.dates = [];
      if (this.profile.profile !== 'gerente') {
        const lojas = await lojasCollection
          .where("nome", "!=", "HQ CELLAIRIS")
          .get();
        lojas.docs.forEach((doc) => {
          const loja = {
            id: doc.id,
            nome: doc.data().nome,
            bairro: doc.data().bairro,
            cidade: doc.data().cidade,
            razao: doc.data().razao,
            grupoEconomico: doc.data().grupoEconomico,
            cep: doc.data().cep,
            uf: doc.data().uf,
            uf_nome: doc.data().uf_nome,
            regional: doc.data().regional,
          };
          this.listaLojas.push(loja);
        });
        // console.log(this.listaLojas);
        lojas.docs.forEach((doc) => {
          this.listaFiltro.push({
            nome: doc.data().nome,
            value: doc.data().nome,
          });
        });
        const assinaturas = await assinaturasCollection.where("loja", "!=", "HQ CELLAIRIS").orderBy("loja").get();
        // console.log(assinaturas.docs);
        for (let i = 0; i < assinaturas.docs.length; i += 1) {
          console.log(assinaturas.docs[i].data());
          // eslint-disable-next-line
          const vendedor = await usersCollection.doc(assinaturas.docs[i].data().vendedor).get();
          // eslint-disable-next-line
          const marca = await marcasCollection.doc(assinaturas.docs[i].data().marca).get();
          // eslint-disable-next-line
          const modelo = await modelosCollection.doc(assinaturas.docs[i].data().modelo).get();
          const item = {
            Marca: marca.data().nome,
            Modelo: modelo.data().nome,
            Vendedor: vendedor.data().name,
            CpfVendedor: vendedor.data().cpf,
            Loja: assinaturas.docs[i].data().loja,
            Data: assinaturas.docs[i].data().data,
            Plano: assinaturas.docs[i].data().plano,
            ValorCobertura: assinaturas.docs[i].data().valorCobertura,
            Situacao: assinaturas.docs[i].data().situacao,
            Cliente: assinaturas.docs[i].data().cliente,
            Status: assinaturas.docs[i].data().status,
          };
          this.listaAssinaturas.push(item);
        }
        const clientes = await usersCollection.get();
        clientes.docs.forEach((cliente) => {
          const usuario = {
            nome: cliente.data().name,
            cpf: cliente.data().cpf,
            email: cliente.data().email,
          };
          this.listaCliente.push(usuario);
        });
        // eslint-disable-next-line
      } else {
        const assinaturas = await assinaturasCollection.orderBy("vendedor")
          .where("loja", "==", this.profile.loja)
          .orderBy("data")
          .get();
        for (let i = 0; i < assinaturas.docs.length; i += 1) {
          // eslint-disable-next-line
          const vendedor = await usersCollection.doc(assinaturas.docs[i].data().vendedor).get();
          // eslint-disable-next-line
          const marca = await marcasCollection.doc(assinaturas.docs[i].data().marca).get();
          // eslint-disable-next-line
          const modelo = await modelosCollection.doc(assinaturas.docs[i].data().modelo).get();
          const item = {
            Marca: marca.data().nome,
            Modelo: modelo.data().nome,
            Vendedor: vendedor.data().name,
            CpfVendedor: vendedor.data().cpf,
            Loja: assinaturas.docs[i].data().loja,
            Data: assinaturas.docs[i].data().data,
            Plano: assinaturas.docs[i].data().plano,
            ValorCobertura: assinaturas.docs[i].data().valorCobertura,
            Situacao: assinaturas.docs[i].data().situacao,
            Cliente: assinaturas.docs[i].data().cliente,
            Status: assinaturas.docs[i].data().status,
          };
          if (item.Vendedor !== "Salomão") {
            this.listaAssinaturas.push(item);
          }
        }
      }
      console.log(this.listaAssinaturas);
      this.carregamento = true;
    },
    async filtrar() {
      this.listaAssinaturas = [];
      // console.log(this.filtroSelecionado);
      if (this.profile.profile !== 'gerente') {
        let assinaturas = null;
        if ((this.dataInicial === null && this.dataFinal === null) && (this.filtroSelecionado === null || this.filtroSelecionado === "")) {
          assinaturas = await assinaturasCollection.get();
        } else if (this.dataInicial !== "" && this.dataInicial !== null && this.dataFinal !== "" && this.dataFinal !== null && this.filtroSelecionado !== null && this.filtroSelecionado !== "") {
          assinaturas = await assinaturasCollection.where("loja", "==", this.filtroSelecionado).where("data", ">=", this.dataInicial).where("data", "<=", this.dataFinal).orderBy("data")
            .get();
        } else if (this.filtroSelecionado !== null && this.filtroSelecionado !== "") {
          assinaturas = await assinaturasCollection
            .where("loja", "==", this.filtroSelecionado)
            .orderBy("data")
            .get();
        } else if (this.dataInicial !== "" && this.dataInicial !== null && this.dataFinal !== "" && this.dataFinal !== null) {
          assinaturas = await assinaturasCollection.where("data", ">=", this.dataInicial).where("data", "<=", this.dataFinal).orderBy("data")
            .get();
        }
        /// eslint-disable-next-line
        console.log(assinaturas);
        assinaturas.forEach(async (assinatura) => {
          const vendedor = await usersCollection.doc(assinatura.data().vendedor).get();
          const marca = await marcasCollection.doc(assinatura.data().marca).get();
          const modelo = await modelosCollection.doc(assinatura.data().modelo).get();
          const item = {
            Marca: marca.data().nome,
            Modelo: modelo.data().nome,
            Vendedor: vendedor.data().name,
            CpfVendedor: vendedor.data().cpf,
            Loja: assinatura.data().loja,
            Data: assinatura.data().data,
            Plano: assinatura.data().plano,
          };
          if (item.Vendedor !== "Salomão") {
            this.listaAssinaturas.push(item);
          }
        });
        // eslint-disable-next-line
      } else {
        const assinaturas = await assinaturasCollection.orderBy(this.filtroSelecionado)
          .where("loja", "==", this.profile.loja)
          .get();
        assinaturas.forEach(async (assinatura) => {
          const vendedor = await usersCollection.doc(assinatura.data().vendedor).get();
          const marca = await marcasCollection.doc(assinatura.data().marca).get();
          const modelo = await modelosCollection.doc(assinatura.data().modelo).get();
          const item = {
            Marca: marca.data().nome,
            Modelo: modelo.data().nome,
            Vendedor: vendedor.data().name,
            CpfVendedor: vendedor.data().cpf,
            Loja: assinatura.data().loja,
            Data: assinatura.data().data,
            Plano: assinatura.data().plano,
          };
          if (item.Vendedor !== "Salomão") {
            this.listaAssinaturas.push(item);
          }
        });
      }
    },
    selecionarFiltro(value) {
      this.filtroSelecionado = value;
      console.log(this.dataInicial);
      console.log(this.dataFinal);
    },
    buscarLoja(loja) {
      console.log(loja);
      // eslint-disable-next-line
      const lojaSelecionada = this.listaLojas.find((loj) => loj.nome === loja);
      // console.log(lojaSelecionada);
      return lojaSelecionada;
    },
    buscarCliente(cliente) {
      return this.listaCliente.find((cli) => cli.email === cliente);
    },
    async exportarExcel() {
      console.log("teste");
      if (this.carregamento === true) {
        console.log("teste 2");
        // eslint-disable-next-line
        let arrayJson = [];
        arrayJson[0] = [];
        let dataJson = null;
        // const listaLojas = lojasCollection.get();
        if ((this.dataInicial === null && this.dataFinal === null) && (this.filtroSelecionado === null || this.filtroSelecionado === "")) {
          console.log("teste 3");
          for (let i = 0; i < this.listaAssinaturas.length; i += 1) {
            console.log("teste 4");
            // eslint-disable-next-line
            const loja = await this.buscarLoja(this.listaAssinaturas[i].Loja);
            console.log(loja.id);
            // eslint-disable-next-line
            const cliente = await this.buscarCliente(this.listaAssinaturas[i].Cliente);
            if (this.listaAssinaturas[i].Status !== false && this.listaAssinaturas[i].Situacao !== "Cancelada") {
              dataJson = {
                // eslint-disable-next-line
                GrupoEconomico: await loja.grupoEconomico,
                // eslint-disable-next-line
                Regional: await loja.regional,
                // eslint-disable-next-line
                UF: await loja.uf,
                // eslint-disable-next-line
                Municipio: await loja.cidade,
                // eslint-disable-next-line
                Bairro: await loja.bairro,
                Canal: "Pdv",
                // eslint-disable-next-line
                NomePdv: await loja.nome,
                Vendedor: this.listaAssinaturas[i].Vendedor,
                CpfVendedor: this.listaAssinaturas[i].CpfVendedor,
                // eslint-disable-next-line
                NomeAssinante: await cliente.nome,
                // eslint-disable-next-line
                CPFAssinante: await cliente.cpf,
                MarcaProtegida: this.listaAssinaturas[i].Marca,
                ModeloProtegido: this.listaAssinaturas[i].Modelo,
                ValorAssinatura: this.listaAssinaturas[i].ValorCobertura,
                MeioPagamento: "Cartão de Crédito",
                Plano: this.listaAssinaturas[i].Plano,
                DataAdesao: this.listaAssinaturas[i].Data.toDate().toLocaleDateString("pt-BR"),
                Status: this.listaAssinaturas[i].Situacao,
                DataCancelamento: " - ",
                MotivoCancelamento: " - ",
              };
            } else {
              dataJson = {
                // eslint-disable-next-line
                GrupoEconomico: await loja.grupoEconomico,
                // eslint-disable-next-line
                Regional: await loja.regional,
                // eslint-disable-next-line
                UF: await loja.uf,
                // eslint-disable-next-line
                Municipio: await loja.cidade,
                // eslint-disable-next-line
                Bairro: await loja.bairro,
                Canal: "Pdv",
                // eslint-disable-next-line
                NomePdv: await loja.nome,
                Vendedor: this.listaAssinaturas[i].Vendedor,
                CpfVendedor: this.listaAssinaturas[i].CpfVendedor,
                // eslint-disable-next-line
                NomeAssinante: await cliente.nome,
                // eslint-disable-next-line
                CPFAssinante: await cliente.cpf,
                MarcaProtegida: this.listaAssinaturas[i].Marca,
                ModeloProtegido: this.listaAssinaturas[i].Modelo,
                ValorAssinatura: this.listaAssinaturas[i].ValorCobertura,
                MeioPagamento: "Cartão de Crédito",
                Plano: this.listaAssinaturas[i].Plano,
                DataAdesao: this.listaAssinaturas[i].Data.toDate().toLocaleDateString("pt-BR"),
                Status: this.listaAssinaturas[i].Situacao,
                DataCancelamento: this.listaAssinaturas[i].Data.toDate().toLocaleDateString("pt-BR"),
                MotivoCancelamento: this.listaAssinaturas[i].motivoCancelamento,
              };
            }
            arrayJson[0].push(dataJson);
          }
        } else if ((this.dataInicial === "" && this.dataInicial === null) && (this.dataFinal === "" && this.dataFinal === null) && this.filtroSelecionado !== "" && this.filtroSelecionado !== null) {
          for (let i = 0; i < this.listaAssinaturas.length; i += 1) {
            if (this.listaAssinaturas[i].Loja === this.filtroSelecionado) {
              // eslint-disable-next-line
              const loja = await this.buscarLoja(this.listaAssinaturas[i].Loja);
              // eslint-disable-next-line
              const cliente = await this.buscarCliente(this.listaAssinaturas[i].Cliente);
              if (this.listaAssinaturas[i].Status !== false && this.listaAssinaturas[i].Situacao !== "Cancelada") {
                dataJson = {
                  // eslint-disable-next-line
                  GrupoEconomico: await loja.grupoEconomico,
                  // eslint-disable-next-line
                  Regional: await loja.regional,
                  // eslint-disable-next-line
                  UF: await loja.uf,
                  // eslint-disable-next-line
                  Municipio: await loja.cidade,
                  // eslint-disable-next-line
                  Bairro: await loja.bairro,
                  Canal: "Pdv",
                  // eslint-disable-next-line
                  NomePdv: await loja.nome,
                  Vendedor: this.listaAssinaturas[i].Vendedor,
                  CpfVendedor: this.listaAssinaturas[i].CpfVendedor,
                  // eslint-disable-next-line
                  NomeAssinante: await cliente.nome,
                  // eslint-disable-next-line
                  CPFAssinante: await cliente.cpf,
                  MarcaProtegida: this.listaAssinaturas[i].Marca,
                  ModeloProtegido: this.listaAssinaturas[i].Modelo,
                  ValorAssinatura: this.listaAssinaturas[i].ValorCobertura,
                  MeioPagamento: "Cartão de Crédito",
                  Plano: this.listaAssinaturas[i].Plano,
                  DataAdesao: this.listaAssinaturas[i].Data.toDate().toLocaleDateString("pt-BR"),
                  Status: this.listaAssinaturas[i].Situacao,
                  DataCancelamento: " - ",
                  MotivoCancelamento: " - ",
                };
              } else {
                dataJson = {
                  // eslint-disable-next-line
                  GrupoEconomico: await loja.grupoEconomico,
                  // eslint-disable-next-line
                  Regional: await loja.regional,
                  // eslint-disable-next-line
                  UF: await loja.uf,
                  // eslint-disable-next-line
                  Municipio: await loja.cidade,
                  // eslint-disable-next-line
                  Bairro: await loja.bairro,
                  Canal: "Pdv",
                  // eslint-disable-next-line
                  NomePdv: await loja.nome,
                  Vendedor: this.listaAssinaturas[i].Vendedor,
                  CpfVendedor: this.listaAssinaturas[i].CpfVendedor,
                  // eslint-disable-next-line
                  NomeAssinante: await cliente.nome,
                  // eslint-disable-next-line
                  CPFAssinante: await cliente.cpf,
                  MarcaProtegida: this.listaAssinaturas[i].Marca,
                  ModeloProtegido: this.listaAssinaturas[i].Modelo,
                  ValorAssinatura: this.listaAssinaturas[i].ValorCobertura,
                  MeioPagamento: "Cartão de Crédito",
                  Plano: this.listaAssinaturas[i].Plano,
                  DataAdesao: this.listaAssinaturas[i].Data.toDate().toLocaleDateString("pt-BR"),
                  Status: this.listaAssinaturas[i].Situacao,
                  DataCancelamento: this.listaAssinaturas[i].Data.toDate().toLocaleDateString("pt-BR"),
                  MotivoCancelamento: this.listaAssinaturas[i].motivoCancelamento,
                };
              }
              arrayJson[0].push(dataJson);
            }
          }
        } else if ((this.filtroSelecionado === "" || this.filtroSelecionado === null) && (this.dataInicial !== "" && this.dataInicial !== null) && (this.dataFinal !== "" && this.dataFinal !== null)) {
          this.listaAssinaturasData = [];
          if (this.profile.profile !== 'gerente') {
            const assinaturas = await assinaturasCollection
              .where("data", ">=", this.dataInicial)
              .where("data", "<=", this.dataFinal)
              .orderBy("data", "desc")
              .orderBy("loja")
              .get();
            for (let i = 0; i < assinaturas.docs.length; i += 1) {
              if (assinaturas.docs[i].loja !== "HQ CELLAIRIS") {
                // eslint-disable-next-line
                const vendedor = await usersCollection.doc(assinaturas.docs[i].data().vendedor).get();
                // eslint-disable-next-line
                const marca = await marcasCollection.doc(assinaturas.docs[i].data().marca).get();
                // eslint-disable-next-line
                const modelo = await modelosCollection.doc(assinaturas.docs[i].data().modelo).get();
                const item = {
                  Marca: marca.data().nome,
                  Modelo: modelo.data().nome,
                  Vendedor: vendedor.data().name,
                  CpfVendedor: vendedor.data().cpf,
                  Loja: assinaturas.docs[i].data().loja,
                  Data: assinaturas.docs[i].data().data,
                  Plano: assinaturas.docs[i].data().plano,
                  ValorCobertura: assinaturas.docs[i].data().valorCobertura,
                  Situacao: assinaturas.docs[i].data().situacao,
                  Cliente: assinaturas.docs[i].data().cliente,
                  Status: assinaturas.docs[i].data().status,
                };
                this.listaAssinaturasData.push(item);
              }
            }
            // eslint-disable-next-line
          } else {
            const assinaturas = await assinaturasCollection.orderBy("vendedor")
              .where("loja", "==", this.profile.loja)
              .where("data", ">=", this.dataInicial)
              .where("data", "<=", this.dataFinal)
              .orderBy("loja")
              .orderBy("data")
              .get();
            for (let i = 0; i < assinaturas.docs.length; i += 1) {
              // eslint-disable-next-line
              const vendedor = await usersCollection.doc(assinaturas.docs[i].data().vendedor).get();
              // eslint-disable-next-line
              const marca = await marcasCollection.doc(assinaturas.docs[i].data().marca).get();
              // eslint-disable-next-line
              const modelo = await modelosCollection.doc(assinaturas.docs[i].data().modelo).get();
              const item = {
                Marca: marca.data().nome,
                Modelo: modelo.data().nome,
                Vendedor: vendedor.data().name,
                CpfVendedor: vendedor.data().cpf,
                Loja: assinaturas.docs[i].data().loja,
                Data: assinaturas.docs[i].data().data,
                Plano: assinaturas.docs[i].data().plano,
                ValorCobertura: assinaturas.docs[i].data().valorCobertura,
                Situacao: assinaturas.docs[i].data().situacao,
                Cliente: assinaturas.docs[i].data().cliente,
                Status: assinaturas.docs[i].data().status,
              };
              if (item.Vendedor !== "Salomão") {
                this.listaAssinaturasData.push(item);
              }
            }
          }
          for (let i = 0; i < this.listaAssinaturasData.length; i += 1) {
            // console.log(this.listaAssinaturasData);
            // eslint-disable-next-line
            const loja = await this.buscarLoja(this.listaAssinaturasData[i].Loja);
            // console.log(this.listaAssinaturasData[i]);
            // console.log(loja.id);
            // eslint-disable-next-line
            const cliente = await this.buscarCliente(this.listaAssinaturasData[i].Cliente);
            if (this.listaAssinaturasData[i].Status !== false && this.listaAssinaturasData[i].Situacao !== "Cancelada") {
              dataJson = {
                // eslint-disable-next-line
                GrupoEconomico: await loja.grupoEconomico,
                // eslint-disable-next-line
                Regional: await loja.regional,
                // eslint-disable-next-line
                UF: await loja.uf,
                // eslint-disable-next-line
                Municipio: await loja.cidade,
                // eslint-disable-next-line
                Bairro: await loja.bairro,
                Canal: "Pdv",
                // eslint-disable-next-line
                NomePdv: await loja.nome,
                Vendedor: this.listaAssinaturasData[i].Vendedor,
                CpfVendedor: this.listaAssinaturasData[i].CpfVendedor,
                // eslint-disable-next-line
                NomeAssinante: await cliente.nome,
                // eslint-disable-next-line
                CPFAssinante: await cliente.cpf,
                MarcaProtegida: this.listaAssinaturasData[i].Marca,
                ModeloProtegido: this.listaAssinaturasData[i].Modelo,
                ValorAssinatura: this.listaAssinaturasData[i].ValorCobertura,
                MeioPagamento: "Cartão de Crédito",
                Plano: this.listaAssinaturasData[i].Plano,
                DataAdesao: this.listaAssinaturasData[i].Data.toDate().toLocaleDateString("pt-BR"),
                Status: this.listaAssinaturasData[i].Situacao,
                DataCancelamento: " - ",
                MotivoCancelamento: " - ",
              };
            } else {
              dataJson = {
                // eslint-disable-next-line
                GrupoEconomico: await loja.grupoEconomico,
                // eslint-disable-next-line
                Regional: await loja.regional,
                // eslint-disable-next-line
                UF: await loja.uf,
                // eslint-disable-next-line
                Municipio: await loja.cidade,
                // eslint-disable-next-line
                Bairro: await loja.bairro,
                Canal: "Pdv",
                // eslint-disable-next-line
                NomePdv: await loja.nome,
                Vendedor: this.listaAssinaturasData[i].Vendedor,
                CpfVendedor: this.listaAssinaturasData[i].CpfVendedor,
                // eslint-disable-next-line
                NomeAssinante: await cliente.nome,
                // eslint-disable-next-line
                CPFAssinante: await cliente.cpf,
                MarcaProtegida: this.listaAssinaturasData[i].Marca,
                ModeloProtegido: this.listaAssinaturasData[i].Modelo,
                ValorAssinatura: this.listaAssinaturasData[i].ValorCobertura,
                MeioPagamento: "Cartão de Crédito",
                Plano: this.listaAssinaturasData[i].Plano,
                DataAdesao: this.listaAssinaturasData[i].Data.toDate().toLocaleDateString("pt-BR"),
                Status: this.listaAssinaturasData[i].Situacao,
                DataCancelamento: this.listaAssinaturasData[i].Data.toDate().toLocaleDateString("pt-BR"),
                MotivoCancelamento: this.listaAssinaturasData[i].motivoCancelamento,
              };
            }
            // console.log(dataJson);
            arrayJson[0].push(dataJson);
          }
        } else if ((this.filtroSelecionado !== "" && this.filtroSelecionado !== null) && (this.dataInicial !== "" && this.dataInicial !== null) && (this.dataFinal !== "" && this.dataFinal !== null)) {
          this.listaAssinaturasData = [];
          if (this.profile.profile !== 'gerente') {
            const assinaturas = await assinaturasCollection
              .where("loja", "==", this.filtroSelecionado)
              .where("data", ">=", this.dataInicial)
              .where("data", "<=", this.dataFinal)
              .orderBy("data", "desc")
              .get();
            for (let i = 0; i < assinaturas.docs.length; i += 1) {
              if (assinaturas.docs[i].loja !== "HQ CELLAIRIS") {
                // eslint-disable-next-line
                const vendedor = await usersCollection.doc(assinaturas.docs[i].data().vendedor).get();
                // eslint-disable-next-line
                const marca = await marcasCollection.doc(assinaturas.docs[i].data().marca).get();
                // eslint-disable-next-line
                const modelo = await modelosCollection.doc(assinaturas.docs[i].data().modelo).get();
                const item = {
                  Marca: marca.data().nome,
                  Modelo: modelo.data().nome,
                  Vendedor: vendedor.data().name,
                  CpfVendedor: vendedor.data().cpf,
                  Loja: assinaturas.docs[i].data().loja,
                  Data: assinaturas.docs[i].data().data,
                  Plano: assinaturas.docs[i].data().plano,
                  ValorCobertura: assinaturas.docs[i].data().valorCobertura,
                  Situacao: assinaturas.docs[i].data().situacao,
                  Cliente: assinaturas.docs[i].data().cliente,
                  Status: assinaturas.docs[i].data().status,
                };
                this.listaAssinaturasData.push(item);
              }
            }
            // eslint-disable-next-line
          } else {
            const assinaturas = await assinaturasCollection.orderBy("vendedor")
              .where("loja", "==", this.profile.loja)
              .where("data", ">=", this.dataInicial)
              .where("data", "<=", this.dataFinal)
              .orderBy("loja")
              .orderBy("data")
              .get();
            for (let i = 0; i < assinaturas.docs.length; i += 1) {
              // eslint-disable-next-line
              const vendedor = await usersCollection.doc(assinaturas.docs[i].data().vendedor).get();
              // eslint-disable-next-line
              const marca = await marcasCollection.doc(assinaturas.docs[i].data().marca).get();
              // eslint-disable-next-line
              const modelo = await modelosCollection.doc(assinaturas.docs[i].data().modelo).get();
              const item = {
                Marca: marca.data().nome,
                Modelo: modelo.data().nome,
                Vendedor: vendedor.data().name,
                CpfVendedor: vendedor.data().cpf,
                Loja: assinaturas.docs[i].data().loja,
                Data: assinaturas.docs[i].data().data,
                Plano: assinaturas.docs[i].data().plano,
                ValorCobertura: assinaturas.docs[i].data().valorCobertura,
                Situacao: assinaturas.docs[i].data().situacao,
                Cliente: assinaturas.docs[i].data().cliente,
                Status: assinaturas.docs[i].data().status,
              };
              if (item.Vendedor !== "Salomão") {
                this.listaAssinaturasData.push(item);
              }
            }
          }
          for (let i = 0; i < this.listaAssinaturasData.length; i += 1) {
            // console.log(this.listaAssinaturasData);
            // eslint-disable-next-line
            const loja = await this.buscarLoja(this.listaAssinaturasData[i].Loja);
            console.log(this.listaAssinaturasData[i]);
            // console.log(loja.id);
            // eslint-disable-next-line
            const cliente = await this.buscarCliente(this.listaAssinaturasData[i].Cliente);
            if (this.listaAssinaturasData[i].Status !== false && this.listaAssinaturasData[i].Situacao !== "Cancelada") {
              dataJson = {
                // eslint-disable-next-line
                GrupoEconomico: await loja.grupoEconomico,
                // eslint-disable-next-line
                Regional: await loja.regional,
                // eslint-disable-next-line
                UF: await loja.uf,
                // eslint-disable-next-line
                Municipio: await loja.cidade,
                // eslint-disable-next-line
                Bairro: await loja.bairro,
                Canal: "Pdv",
                // eslint-disable-next-line
                NomePdv: await loja.nome,
                Vendedor: this.listaAssinaturasData[i].Vendedor,
                CpfVendedor: this.listaAssinaturasData[i].CpfVendedor,
                // eslint-disable-next-line
                NomeAssinante: await cliente.nome,
                // eslint-disable-next-line
                CPFAssinante: await cliente.cpf,
                MarcaProtegida: this.listaAssinaturasData[i].Marca,
                ModeloProtegido: this.listaAssinaturasData[i].Modelo,
                ValorAssinatura: this.listaAssinaturasData[i].ValorCobertura,
                MeioPagamento: "Cartão de Crédito",
                Plano: this.listaAssinaturasData[i].Plano,
                DataAdesao: this.listaAssinaturasData[i].Data.toDate().toLocaleDateString("pt-BR"),
                Status: this.listaAssinaturasData[i].Situacao,
                DataCancelamento: " - ",
                MotivoCancelamento: " - ",
              };
            } else {
              dataJson = {
                // eslint-disable-next-line
                GrupoEconomico: await loja.grupoEconomico,
                // eslint-disable-next-line
                Regional: await loja.regional,
                // eslint-disable-next-line
                UF: await loja.uf,
                // eslint-disable-next-line
                Municipio: await loja.cidade,
                // eslint-disable-next-line
                Bairro: await loja.bairro,
                Canal: "Pdv",
                // eslint-disable-next-line
                NomePdv: await loja.nome,
                Vendedor: this.listaAssinaturasData[i].Vendedor,
                CpfVendedor: this.listaAssinaturasData[i].CpfVendedor,
                // eslint-disable-next-line
                NomeAssinante: await cliente.nome,
                // eslint-disable-next-line
                CPFAssinante: await cliente.cpf,
                MarcaProtegida: this.listaAssinaturasData[i].Marca,
                ModeloProtegido: this.listaAssinaturasData[i].Modelo,
                ValorAssinatura: this.listaAssinaturasData[i].ValorCobertura,
                MeioPagamento: "Cartão de Crédito",
                Plano: this.listaAssinaturasData[i].Plano,
                DataAdesao: this.listaAssinaturasData[i].Data.toDate().toLocaleDateString("pt-BR"),
                Status: this.listaAssinaturasData[i].Situacao,
                DataCancelamento: this.listaAssinaturasData[i].Data.toDate().toLocaleDateString("pt-BR"),
                MotivoCancelamento: this.listaAssinaturasData[i].motivoCancelamento,
              };
            }
            console.log(dataJson);
            arrayJson[0].push(dataJson);
          }
        }
        // console.log(arrayJson);
        const data = XLSX.utils.json_to_sheet(arrayJson[0]);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, data, "data");
        XLSX.writeFile(wb, 'Detalhamento_Vendas.xlsx');
      }
    },
  },
};
</script>
