<template>
  <main>
    <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-chart-line text-orange"></i>
        </button>
        <div class="z-50 text-left ml-8">
          <!-- Info -->
          <div class="text-3xl font-bold">Painel de Controle</div>
          <div>
            Monitoramento da operação do Cellairis Plus através de Dashboards e
            Relatórios.
          </div>
        </div>
      </div>
    </section>
    <!-- Main Content -->
    <section class="container mx-auto">
      <div
        class="
          font-bold
          text-gray-600 text-xs
          leading-8
          uppercase
          sm:text-xs
        "
      >
        Para mais detalhes sobre as vendas realizadas até o momento clique no botão abaixo.
      </div>
      <div
        class="
        font-bold
        mt-3
        text-gray-600 text-xs
        leading-8
        uppercase
        mb-3
        "
      >
        <a class="sm:mt-8 mb-8 background-transparent md:mt-8 border border-gray-200 rounded uppercase px-2 py-2 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" href="/cellairis/control-panel-details">Detalhamento</a>
      </div>
      <div class="pt-4 flex justify-center" v-if="this.profile !== 'gerente'">
        <!-- Forms -->
        <div class="flex-col md:flex-row w-full flex justify-center">
          <div class="w-1/3 mx-2 flex-1 svelte-1l8159u">
            <div
              class="
                bg-white
                my-2
                p-1
                flex
                justify-center
                items-center
                svelte-1l8159u
                w-full
                uppercase
              "
            >
              <!-- <o-field label="Selecione um intervalo">
                <o-datepicker :searchable="true" placeholder="Clique para selecionar" icon="calendar" v-model="dates" editable range class="border rounded border-gray-200 p-2"> </o-datepicker>
              </o-field> -->
              <o-field label="Data inicial:" class="text-xs uppercase font-bold text-gray-600 mt-3 w-1/3">
                <o-datepicker :searchable="true" editable v-model="dataInicial" :locale="pt-br" placeholder="Clique para selecionar..." class="border rounded border-gray-200 py-3 px-2"> </o-datepicker>
              </o-field>
              <o-field label="Data final:" class="ml-8 text-xs uppercase font-bold text-gray-600 w-1/3" pack="fas" icon="calendar">
                <o-datepicker :searchable="true"  editable v-model="dataFinal" :locale="pt-br" placeholder="Clique para selecionar..." trap-focus class="border rounded border-gray-200 py-3 px-2" icon="calendar"> </o-datepicker>
              </o-field>
            </div>
          </div>
          <div class="w-1/3 mx-2 flex-1 svelte-1l8159u">
            <div
              class="
                bg-white
                my-2
                p-1
                flex
                justify-center
                items-center
                svelte-1l8159u
              "
            >
              <Multiselect
                name="filtro"
                v-model="filtroSelecionado"
                :options="listaFiltro"
                @change="selecionarFiltro"
                :searchable="true"
                label="nome"
                class="
                  p-1
                  px-2
                  appearance-none
                  outline-none
                  text-gray-800
                  w-full
                  m-1
                  mt-6
                "
              />
              <button
              class="
                text-left
                w-1/8
                text-base
                hover:scale-110
                focus:outline-none
                justify-left
                flex
                px-4
                py-2
                rounded
                font-bold
                cursor-pointer
                hover:bg-black
                bg-orange
                text-white
                border
                duration-200
                ease-in-out
                border-white
                transition
                mt-5
              "
                @click.self="filtrar()"
            >
              Filtrar
            </button>
            <button
              class="
                text-left
                w-1/8
                text-base
                hover:scale-110
                focus:outline-none
                justify-left
                flex
                px-4
                py-2
                rounded
                font-bold
                cursor-pointer
                hover:bg-black
                bg-orange
                text-white
                border
                duration-200
                ease-in-out
                border-white
                transition
                mt-5
              "
                @click.self="carregaDados()"
            >
              Limpar
            </button>
            <!-- <button
              class="
                text-left
                w-1/8
                text-base
                hover:scale-110
                focus:outline-none
                justify-left
                flex
                px-4
                py-2
                rounded
                font-bold
                cursor-pointer
                hover:bg-black
                bg-orange
                text-white
                border
                duration-200
                ease-in-out
                border-white
                transition
                mt-5
              "
                @click.self="exportarExcel()"
            >
              Exportar
            </button> -->
            </div>
          </div>
          <!-- <div class="w-1/2 mx-2 flex-1 svelte-1l8159u">
          </div> -->
        </div>
      </div>
      <!-- <div
        class="bg-white relative sm:items-center md:items-center flex flex-col w-full mt-8 md:flex-row"
      >
        <div class="p-6 h-40 w-1/2 bg-orange rounded-lg border mr-2 border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 sm:mb-3">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Total de Assinaturas Cadastradas</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">{{ this.totalAssinaturasAtivas }}</h5>
        </div>
        <div class="p-6 h-40 w-1/2 bg-orange rounded-lg mr-2 border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Total de Assinaturas Suspensas</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">{{ this.totalReparos }}</h5>
        </div>
        <div class="p-6 h-40 w-1/2 bg-orange rounded-lg mr-2 border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Valor Total Assinaturas</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">R$ {{ this.valorTotalCobertura.toFixed(2) }}</h5>
        </div>
        <div class="p-6 h-40 w-1/2 bg-orange rounded-lg mr-2 border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Valor Total Reparos</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">R$ {{ this.valorTotalReparos.toFixed(2) }}</h5>
        </div>
        <div class="p-6 w-1/2 h-40 bg-orange rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Média de Vendas por Vendedor</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">{{ Math.round(this.mediaVendas) }}</h5>
        </div>
      </div> -->
      <div
        class="bg-white relative sm:items-center md:items-center flex flex-col w-full mb-2 mt-2 md:flex-row"
      >
        <div class="p-6 h-40 w-1/2 bg-orange rounded-lg mr-2 border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700 sm:mb-3">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Total de Assinaturas Ativas</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">{{ this.totalAssinaturasAtivas }}</h5>
        </div>
        <div class="p-6 h-40 w-1/2 bg-orange rounded-lg mr-2 border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Total de Reparos</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">{{ this.totalReparos }}</h5>
        </div>
        <div class="p-6 h-40 w-1/2 bg-orange rounded-lg mr-2 border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Valor Total Assinaturas</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">{{ this.valorTotalCobertura.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}</h5>
        </div>
        <div class="p-6 h-40 w-1/2 bg-orange rounded-lg mr-2 border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Valor Total Reparos</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">{{ this.valorTotalReparos.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}</h5>
        </div>
        <div class="p-6 w-1/2 h-40 bg-orange rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Média de Vendas por Vendedor</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">{{ Math.round(this.mediaVendas) }}</h5>
        </div>
      </div>
      <div
        class="bg-white relative flex flex-col w-full mb-10 md:flex-row"
      >
        <div class="p-6 h-40 w-1/2 bg-orange rounded-lg mr-2 border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Total de Assinaturas Canceladas</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">{{ this.totalAssinaturasCanceladas }}</h5>
        </div>
        <div class="p-6 h-40 w-1/2 bg-orange rounded-lg mr-2 border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Valor Total Estornado</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">{{ this.valorTotalEstornado.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}</h5>
        </div>
        <div class="p-6 h-40 w-1/2 bg-orange rounded-lg mr-2 border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Ticket Médio Assinaturas</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">{{ this.ticketMedioAssinaturas.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}</h5>
        </div>
        <div class="p-6 h-40 w-1/2 bg-orange rounded-lg mr-2 border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Ticket Médio Reparos</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">{{ this.ticketMedioReparos.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'}) }}</h5>
        </div>
        <div class="p-6 w-1/2 h-40 bg-orange rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
          <h5 class="mb-2 text-xl font-bold tracking-tight text-white dark:text-white text-center">Percentual de Acionamento</h5>
          <h5 class="mb-3 font-bold text-2xl text-white dark:text-gray-400 text-center">% {{ this.percentualAcionamento.toFixed(0) }}</h5>
        </div>
      </div>
      <div class="bg-white relative flex flex-col w-full md:flex-row mb-10 pr-10">
        <div class="w-1/2 md:w-full flex-col md:flex-row">
          <LineChart />
          <BarChart />
        </div>
        <div class="ml-10 w-1/2 md:w-full flex-col md:flex-row">
          <PieChart />
          <BarChart2 />
        </div>
        <div v-if="profile === 'caramelo'">
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import { mapState } from "vuex";
import Multiselect from "@vueform/multiselect";
import AppFooter from '@/components/Footer.vue';
// eslint-disable-next-line
// import Oruga from '@oruga-ui/oruga-next';
import '@oruga-ui/oruga-next/dist/oruga.css';
import {
  auth,
  assinaturasCollection,
  usersCollection,
  acionamentoReparoCollection,
  lojasCollection,
  functions,
} from "@/includes/firebase";
import BarChart from "../components/ChartTotalCoberturaMarca.vue";
import LineChart from "../components/ChartAssinaturasMes.vue";
import PieChart from "../components/ChartTotalCancelamentoMes.vue";
import BarChart2 from "../components/ChartTotalCancelamentoMarcas.vue";

export default {
  // eslint-disable-next-line
  name: "Report",
  computed: {
    ...mapState(["userLoggedIn"]),
  },
  data() {
    return {
      filtroSelecionado: "",
      listaFiltro: [],
      profile: "",
      totalAssinaturas: 0,
      totalAssinaturasAtivas: 0,
      totalAssinaturasCanceladas: 0,
      mediaVendas: 0,
      percentualAcionamento: 0,
      valorTotalReparos: 0,
      totalReparos: 0,
      valorTotalCobertura: 0,
      ticketMedioReparos: 0,
      ticketMedioAssinaturas: 0,
      valorTotalEstornado: 0,
      dates: [],
      dataInicial: null,
      dataFinal: null,
    };
  },
  components: {
    BarChart,
    BarChart2,
    LineChart,
    PieChart,
    Multiselect,
    AppFooter,
    // Oruga,
  },
  async mounted() {
    if (auth.currentUser != null) {
      // const docSnapshot = await usersCollection.doc(auth.currentUser.uid).get();
      this.profile = "caramelo"; // docSnapshot.data().profile;
    }
    this.loaded = false;
    this.carregaDados();
    this.loaded = true;
  },
  methods: {
    async carregaDados() {
      this.totalAssinaturasAtivas = 0;
      this.totalAssinaturasCanceladas = 0;
      this.mediaVendas = 0;
      this.percentualAcionamento = 0;
      this.valorTotalReparos = 0;
      this.totalReparos = 0;
      this.valorTotalCobertura = 0;
      this.ticketMedioReparos = 0;
      this.ticketMedioAssinaturas = 0;
      this.valorTotalEstornado = 0;
      this.dates = [];
      this.filtroSelecionado = "";
      this.listaFiltro = [];
      const lojas = await lojasCollection.get();
      lojas.docs.forEach((doc) => {
        this.listaFiltro.push({
          nome: doc.data().nome,
          value: doc.data().nome,
        });
      });
      const listaVendas = [];

      // CARREGAMENTO DOS DADOS PARA PERFIS DE CARAMELO E PARCEIRO CELLAIRIS
      if (this.profile !== "gerente") {
        const assinaturas = await assinaturasCollection
          .where("loja", "!=", "HQ CELLAIRIS")
          .get();

        const vendedores = await usersCollection
          .where("profile", "==", "vendedor")
          .get();
        const reparos = await acionamentoReparoCollection
          .get();

        // QUANTIDADE DE REPAROS JÁ REALIZADOS
        this.totalReparos = reparos.docs.length;

        // MÉDIA DE VENDAS DE TODAS AS LOJAS
        this.mediaVendas = assinaturas.docs.length / vendedores.docs.length;
        vendedores.docs.forEach((vendedor) => {
          let vendas = 0;
          assinaturas.docs.forEach(async (doc) => {
            if (vendedor.id === doc.data().vendedor) {
              vendas += 1;
            }
          });
          listaVendas.push(vendas);
        });

        // ABAIXO O TOTAL DE ASSINATURAS ATIVAS E CANCELADAS
        for (let i = 0; i < assinaturas.docs.length; i += 1) {
          console.log(assinaturas.docs[i]);
          console.log(assinaturas.docs[i].data().status);
          console.log(assinaturas.docs[i].data().situacao);
          if ((assinaturas.docs[i].data().status === true || (assinaturas.docs[i].data().status === false && assinaturas.docs[i].data().situacao === "Suspensa")) && assinaturas.docs[i].data().cliente !== "sflacerda@gmail.com") {
            // ATIVAS
            this.totalAssinaturasAtivas += 1;
          } else if (assinaturas.docs[i].data().situacao === "Cancelada") {
            // CANCELADAS
            this.totalAssinaturasCanceladas += 1;
          }
        }
        for (let i = 0; i < assinaturas.docs.length; i += 1) {
          if (assinaturas.docs[i].data().tokenCobranca !== "") {
            const RPaymentId = {
              RecurrentPaymentId: assinaturas.docs[i].data().tokenCobranca,
            };
            const getRecorrencia = functions.httpsCallable('getRecorrencia');
            // eslint-disable-next-line
            const responseRecorrencia = await getRecorrencia(RPaymentId);
            const transacoes = responseRecorrencia.data.RecurrentPayment.RecurrentTransactions;
            for (let j = 0; j < transacoes.length; j += 1) {
              const getTransacao = functions.httpsCallable('getTransacao');
              const codigoPagamento = {
                PaymentId: transacoes[j].TransactionId,
              };
              // eslint-disable-next-line
              const responseGetTransacao = await getTransacao(codigoPagamento);
              // console.log(responseGetTransacao.data.Payment);
              if (responseGetTransacao.data.Payment.Status === 11 || responseGetTransacao.data.Payment.Status === 10) {
                // LÓGICA PARA VALORES ESTORNADOS
                this.valorTotalEstornado += parseFloat(assinaturas.docs[i].data().valorCobertura.replace("R$", "").replace(" ", "").replace(",", "."));
              } else if (responseGetTransacao.data.Payment.Status === 2) {
                // LÓGICA PARA VALORES RECEBIDOS
                this.valorTotalCobertura += parseFloat(assinaturas.docs[i].data().valorCobertura.replace("R$", "").replace(".", ",").replace(" ", "").replace(",", "."));
              }
            }
          }
        }
        this.ticketMedioAssinaturas = this.valorTotalCobertura / (assinaturas.docs.length - 1);
        reparos.docs.forEach((reparo) => {
          // eslint-disable-next-line
          // console.log(reparo);
          console.log(reparo.data().valorReparo);
          console.log(parseFloat(reparo.data().valorReparo.replace("R$", "").replace(" ", "").replace(".", "").replace(",", ".")));
          this.valorTotalReparos += parseFloat(reparo.data().valorReparo.replace("R$", "").replace(".", "").replace(" ", "").replace(",", "."));
        });
        if (this.totalReparos > 0) {
          this.ticketMedioReparos = this.valorTotalReparos / this.totalReparos;
        }
        this.percentualAcionamento = (this.valorTotalReparos / this.valorTotalCobertura) * 100;

        // ABAIXO LÓGICA DE CARREGAMENTO DOS DADOS PARA PERFIL DE GERENTE
      } else {
        const currentUser = await usersCollection.doc(auth.currentUser.uid).get();
        const assinaturas = await assinaturasCollection
          .where("loja", "==", currentUser.data().loja)
          .get();
        const vendedores = await usersCollection
          .where("profile", "==", "vendedor")
          .where("loja", "==", currentUser.data().loja)
          .get();
          // eslint-disable-next-line
        let arrayReparos = [];
        arrayReparos[0] = [];
        assinaturas.docs.forEach(async (assinatura) => {
          const reparos = await acionamentoReparoCollection
            .where("assinatura", "==", assinatura.id)
            .get();
          arrayReparos[0].push(reparos);
        });
        // eslint-disable-next-line
        // console.log(reparos.docs.length);
        this.totalReparos = arrayReparos[0].length;
        this.mediaVendas = assinaturas.docs.length / vendedores.docs.length;
        vendedores.docs.forEach((vendedor) => {
          let vendas = 0;
          assinaturas.docs.forEach(async (doc) => {
            if (vendedor.id === doc.data().vendedor) {
              vendas += 1;
            }
          });
          listaVendas.push(vendas);
        });
        assinaturas.docs.forEach(async (doc) => {
          const RPaymentId = {
            RecurrentPaymentId: doc.data().tokenCobranca,
          };
          const getRecorrencia = functions.httpsCallable('getRecorrencia');
          const responseRecorrencia = await getRecorrencia(RPaymentId);
          const transacoes = responseRecorrencia.data.RecurrentPayment.RecurrentTransactions;
          transacoes.forEach(async (transacao) => {
            const getTransacao = functions.httpsCallable('getTransacao');
            const codigoPagamento = {
              PaymentId: transacao.TransactionId,
            };
            const responseGetTransacao = await getTransacao(codigoPagamento);
            if (responseGetTransacao.data.Payment.Status === 11 || responseGetTransacao.data.Payment.Status === 10) {
              this.valorTotalEstornado += parseFloat(doc.data().valorCobertura.replace("R$", "").replace(" ", "").replace(",", "."));
            }
          });
        });
        assinaturas.docs.forEach((doc) => {
          if (doc.data().status === true) {
            this.totalAssinaturasAtivas += 1;
          } else {
            this.totalAssinaturasCanceladas += 1;
          }
          this.valorTotalCobertura += parseFloat(doc.data().valorCobertura.replace("R$", "").replace(" ", "").replace(",", "."));
          // eslint-disable-next-line
          // console.log(doc);
        });
        this.ticketMedioAssinaturas = this.valorTotalCobertura / assinaturas.docs.length;
        arrayReparos[0].forEach((reparo) => {
          // eslint-disable-next-line
          // console.log(reparo);
          this.valorTotalReparos += parseFloat(reparo.data().valorReparo.replace("R$", "").replace(" ", "").replace(",", "."));
        });
        if (this.totalReparos > 0) {
          this.ticketMedioReparos = this.valorTotalReparos / this.totalReparos;
        }
        // const lojas = await lojasCollection.get();
        this.percentualAcionamento = (this.valorTotalReparos / this.valorTotalCobertura) * 100;
      }
    },
    async filtrar() {
      this.totalAssinaturasAtivas = 0;
      this.totalAssinaturasCanceladas = 0;
      this.mediaVendas = 0;
      this.percentualAcionamento = 0;
      this.valorTotalReparos = 0;
      this.totalReparos = 0;
      this.valorTotalCobertura = 0;
      this.ticketMedioReparos = 0;
      this.ticketMedioAssinaturas = 0;
      this.valorTotalEstornado = 0;
      const listaVendas = [];
      let assinaturas = null;
      if ((this.dataInicial === null && this.dataFinal === null) && (this.filtroSelecionado === null || this.filtroSelecionado === "")) {
        assinaturas = await assinaturasCollection.where("loja", "!=", "HQ CELLAIRIS").get();
      } else if (this.dataInicial !== "" && this.dataInicial !== null && this.dataFinal !== "" && this.dataFinal !== null && this.filtroSelecionado !== null && this.filtroSelecionado !== "") {
        assinaturas = await assinaturasCollection.where("loja", "==", this.filtroSelecionado).where("data", ">=", this.dataInicial).where("data", "<=", this.dataFinal).get();
      } else if (this.filtroSelecionado !== null && this.filtroSelecionado !== "") {
        assinaturas = await assinaturasCollection
          .where("loja", "==", this.filtroSelecionado)
          .get();
      } else if (this.dataInicial !== "" && this.dataInicial !== null && this.dataFinal !== "" && this.dataFinal !== null) {
        assinaturas = await assinaturasCollection.where("data", ">=", this.dataInicial).where("data", "<=", this.dataFinal).get();
      }
      const vendedores = await usersCollection
        .where("profile", "==", "vendedor")
        .where("loja", "==", this.filtroSelecionado)
        .get();
        // eslint-disable-next-line
      let arrayReparos = [];
      arrayReparos[0] = [];
      assinaturas.docs.forEach(async (assinatura) => {
        const reparos = await acionamentoReparoCollection
          .where("assinatura", "==", assinatura.id)
          .get();
        arrayReparos[0].push(reparos);
      });
      // eslint-disable-next-line
      console.log(arrayReparos[0].length);
      this.totalReparos = arrayReparos[0].length;
      this.mediaVendas = assinaturas.docs.length / vendedores.docs.length;
      vendedores.docs.forEach((vendedor) => {
        let vendas = 0;
        assinaturas.docs.forEach(async (doc) => {
          if (vendedor.id === doc.data().vendedor) {
            vendas += 1;
          }
        });
        listaVendas.push(vendas);
      });
      assinaturas.docs.forEach(async (doc) => {
        const RPaymentId = {
          RecurrentPaymentId: doc.data().tokenCobranca,
        };
        const getRecorrencia = functions.httpsCallable('getRecorrencia');
        const responseRecorrencia = await getRecorrencia(RPaymentId);
        const transacoes = responseRecorrencia.data.RecurrentPayment.RecurrentTransactions;
        transacoes.forEach(async (transacao) => {
          const getTransacao = functions.httpsCallable('getTransacao');
          const codigoPagamento = {
            PaymentId: transacao.TransactionId,
          };
          const responseGetTransacao = await getTransacao(codigoPagamento);
          if (responseGetTransacao.data.Payment.Status === 11 || responseGetTransacao.data.Payment.Status === 10) {
            this.valorTotalEstornado += parseFloat(doc.data().valorCobertura.replace("R$", "").replace(" ", "").replace(",", "."));
          }
        });
      });
      assinaturas.docs.forEach((doc) => {
        console.log(doc.data().status);
        console.log(doc.data().situacao);
        if (doc.data().status === true) {
          this.totalAssinaturasAtivas += 1;
        } else if (doc.data().status === false && doc.data().situacao === "Cancelada") {
          // eslint-disable-next-line
          if (doc.data().situacao === "Cancelada") {
            this.totalAssinaturasCanceladas += 1;
          }
        }
        this.valorTotalCobertura += parseFloat(doc.data().valorCobertura.replace("R$", "").replace(" ", "").replace(",", "."));
        // eslint-disable-next-line
        // console.log(doc);
      });
      this.ticketMedioAssinaturas = this.valorTotalCobertura / assinaturas.docs.length;
      arrayReparos[0].forEach((reparo) => {
        // eslint-disable-next-line
        // console.log(reparo);
        this.valorTotalReparos += parseFloat(reparo.data().valorReparo.replace("R$", "").replace(" ", ""));
      });
      if (this.totalReparos > 0) {
        this.ticketMedioReparos = this.valorTotalReparos / this.totalReparos;
      }
      // const lojas = await lojasCollection.get();
      this.percentualAcionamento = this.valorTotalReparos / this.valorTotalCobertura;
    },
  },
};
</script>
