<template>
  <main>
    <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-question text-orange"></i>
        </button>
        <div class="z-50 text-left ml-8">
          <!-- Info -->
          <div class="text-3xl font-bold">Sobre o Cellairis Plus</div>
          <div>Informações sobre o nosso Serviço de Reparos.</div>
        </div>
      </div>
    </section>
    <!-- Main Section -->
    <section class="container mx-auto mt-6">
      <div
        class="bg-white rounded border-gray-200 relative flex flex-col"
      >
        <div class="px-6 pt-6 pb-5 font-bold border-b border-gray-200 bg-blue">
          <!-- Title -->
          <span class="card-title font-bold text-white">O que é o Cellairis Plus?</span>
          <i class="fa fa-question float-right text-white text-2xl"></i>
        </div>
        <div class="p-6">
          <ul class="container mx-auto">
            <li class="p-3">
              <!-- Text -->
              <p class="mb-2">
                Assinatura mensal, de custo baixíssimo, onde você pode fazer 2 consertos de tela por ano, sem pagar nada na hora e cancelar a qualquer no momento que quiser.
              </p>
              <p>
                O Cellairis Plus visa facilitar o dia a dia de seus consumidores desburocratizando a troca de telas de aparelhos de celular, reduzindo custos e principalmente tempo.
              </p>
            </li>
          </ul>
        </div>
        <div class="px-6 pt-6 pb-5 font-bold border-b border-gray-200 bg-blue">
          <!-- Title -->
          <span class="card-title font-bold text-white">Por que fazer?</span>
          <i class="fa fa-question float-right text-white text-2xl"></i>
        </div>
        <div class="p-6">
          <ul class="container mx-auto">
            <li class="p-6">
              <!-- Text -->
              <p>
                ✓ Não Prende o limite do cartão.
              </p>
            </li>
            <li class="p-6">
              <!-- Text -->
              <p>
                ✓ Não tem carência.
              </p>
            </li>
            <li class="p-6">
              <!-- Text -->
              <p>
                ✓ Sem fidelidade.
              </p>
            </li>
            <li class="p-6">
              <!-- Text -->
              <p>
                ✓ Sem Taxa de cancelamento.
              </p>
            </li>
            <li class="p-6">
              <!-- Text -->
              <p>
                ✓ R$0,00 (ZERO) taxa de serviço.
              </p>
            </li>
            <li class="p-6">
              <!-- Text -->
              <p>
                ✓ Trocou de celular? É só cancelar a assinatura atual e fazer outra sem burocracia.
              </p>
            </li>
            <li class="p-6">
              <!-- Text -->
              <p>
                ✓ Adquirindo um case da linha Raputre ou Showcase Cellairis e uma película Monstershock ou Monstershock Full o cliente pode levar a assinatura com desconto.
              </p>
            </li>
            <li class="p-6">
              <!-- Text -->
              <p>
                ✓ E ainda ganha 20% de desconto nas cases e películas selecionadas adquiridas no ato de adesão da sua assinatura.
              </p>
            </li>
          </ul>
        </div>
        <div class="px-6 pt-6 pb-5 font-bold border-b border-gray-200 bg-blue">
          <!-- Title -->
          <span class="card-title font-bold text-white">Quem pode fazer?</span>
          <i class="fa fa-question float-right text-white text-2xl"></i>
        </div>
        <div class="p-6">
          <ul class="container mx-auto">
            <li class="p-6">
              <!-- Text -->
              <p>
                ✓ Qualquer cliente acima de 18 anos que possua um celular dentro do portifólio Cellairis de reparo. Porém, sem danos preexistentes na tela.
              </p>
            </li>
            <li class="p-6">
              <!-- Text -->
              <p>
                ✓ É possível contratar mais de uma assinatura por CPF (limitado a 5).
              </p>
            </li>
            <li class="p-6 mb-10">
              <!-- Text -->
              <p>
                ✓ Contratação com cartões de Terceiro.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from '@/components/Footer.vue';

export default {
  // eslint-disable-next-line
  name: "About",
  components: {
    AppFooter,
  },
};
</script>
