<script>
import { Chart, registerables } from 'chart.js';
import { BarChart, useBarChart } from 'vue-chart-3';
import {
  ref,
  computed,
  defineComponent,
  reactive,
} from 'vue';
import {
  assinaturasCollection,
  marcasCollection,
  usersCollection,
  auth,
} from '@/includes/firebase';

Chart.register(...registerables);

export default defineComponent({
  name: 'App',
  components: {
    BarChart,
  },
  setup() {
    const loaded = ref(false);

    const assinaturasMarca = reactive([]);
    const listaMarcas = reactive([]);
    const nomesMarcas = reactive([]);

    (async () => {
      const user = await usersCollection.doc(auth.currentUser.uid).get();
      if (user.data().profile !== "gerente") {
        assinaturasMarca[0] = [];
        const marcas = await marcasCollection.get();
        marcas.forEach((doc) => {
          if (doc.data().status === true) {
            listaMarcas.push({
              id: doc.id,
              nome: doc.data().nome,
            });
            nomesMarcas.push(doc.data().nome);
          }
          // console.log(listaMarcas);
        });
        const assinaturas = await assinaturasCollection.get();
        listaMarcas.forEach((marca) => {
          let totalAssinaturasMarca = 0;
          assinaturas.forEach((doc) => {
            if (doc.data().marca === marca.id && doc.data().status === false && (doc.data().situacao === "Cancelada" || doc.data().situacao === "Cancelada (In)")) {
              totalAssinaturasMarca += 1;
            }
          });
          // console.log(assinaturasMarca);
          assinaturasMarca[0].push(Number(totalAssinaturasMarca));
          // contador += 1;
          // console.log(contador);
          // console.log(assinaturasMarca[0]);
        });
        loaded.value = true;
      } else {
        assinaturasMarca[0] = [];
        const marcas = await marcasCollection.get();
        marcas.forEach((doc) => {
          if (doc.data().status === true) {
            listaMarcas.push({
              id: doc.id,
              nome: doc.data().nome,
            });
            nomesMarcas.push(doc.data().nome);
          }
          // console.log(listaMarcas);
        });
        const assinaturas = await assinaturasCollection
          .where("loja", "==", user.data().loja)
          .get();
        listaMarcas.forEach((marca) => {
          let totalAssinaturasMarca = 0;
          assinaturas.forEach((doc) => {
            if (doc.data().marca === marca.id && doc.data().status === false && doc.data().situacao === "Cancelada") {
              totalAssinaturasMarca += 1;
            }
          });
          // console.log(assinaturasMarca);
          assinaturasMarca[0].push(Number(totalAssinaturasMarca));
          // contador += 1;
          // console.log(contador);
          // console.log(assinaturasMarca[0]);
        });
        loaded.value = true;
      }
    })();

    const dados = ref(assinaturasMarca);
    const marcas = ref(nomesMarcas);
    // console.log(dados.value);

    const chartData = computed(() => ({
      labels: marcas.value, // ["Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho", "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"],
      datasets: [
        {
          data: dados.value,
          backgroundColor: '#FF6961',
        },
      ],
    }));

    const options = ref({
      plugins: {
        title: {
          text: "Total Cancelamentos por Marca",
          font: {
            size: 20,
          },
        },
      },
    });

    const { barChartProps } = useBarChart({
      chartData,
      options,
    });

    return { barChartProps };
  },
});
</script>

<template>
  <div id="app" style="width: 100%">
    <BarChart v-bind="barChartProps" />
  </div>
</template>
