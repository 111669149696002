import { createApp } from "vue";
import Oruga from '@oruga-ui/oruga-next';
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VeeValidatePlugin from "./includes/validation";
import { auth } from "./includes/firebase";
import "./assets/tailwind.css";
import "./assets/main.css";
import "./plugins/chart";
import '@oruga-ui/oruga-next/dist/oruga-full.css';

let app;

auth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App);

    app.use(store);
    app.use(router);
    app.use(VeeValidatePlugin);
    app.use(Oruga);

    app.mount("#app");
  }
});
