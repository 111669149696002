<template>
  <main>
    <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-address-card text-orange"></i>
        </button>
        <div class="z-50 text-left ml-8">
          <!-- Info -->
          <div class="text-3xl font-bold">Histórico do Cliente</div>
        </div>
      </div>
    </section>
    <!-- Main Content -->
    <section class="container mx-auto">
      <div
        class="bg-white rounded border border-gray-200 relative flex flex-col"
      >
        <div class="p-5">
          <div class="flex">
            <div
              class="
                font-bold
                h-6
                mt-3
                text-gray-600
                leading-8
                uppercase
              "
            >
            Cliente: {{this.cliente.nome}}
            <!-- Cliente: {{this.cliente.data().name}} Data Adesão: {{this.assinatura.data().data}} -->
            </div>
            <div
              class="
                font-bold
                h-6
                mt-3
                text-gray-600
                leading-8
                uppercase
                ml-8
              "
            >
            CPF: {{this.cliente.cpf}}
            <!-- Cliente: {{this.cliente.data().name}} Data Adesão: {{this.assinatura.data().data}} -->
            </div>
          </div>
          <div class="flex">
            <div
              class="
                font-bold
                h-6
                mt-3
                text-gray-600
                leading-8
                uppercase
              "
            >
            Data Aquisição: {{this.cliente.data}}
            <!-- Cliente: {{this.cliente.data().name}} Data Adesão: {{this.assinatura.data().data}} -->
            </div>
            <div
              class="
                font-bold
                h-6
                mt-3
                text-gray-600
                leading-8
                uppercase
                ml-8
              "
            >
            Situação: {{this.cliente.situacao}}
            <!-- Cliente: {{this.cliente.data().name}} Data Adesão: {{this.assinatura.data().data}} -->
            </div>
          </div>
        </div>
        <!-- component -->
        <div class="p-5">
          <div class="flex justify-center">
            <!-- <div class="flex justify-center items-center text-white
            w-1/7 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-purple-400 h-12">
              CPF
            </div> -->
            <div class="flex justify-center items-center text-white
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Mensalidade
            </div>
            <div class="flex justify-center items-center text-white
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Valor
            </div>
            <div class="flex justify-center text-center items-center text-white
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Data Tentativa
            </div>
            <div class="flex justify-center items-center text-white
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Numero Tentativa
            </div>
            <div class="flex justify-center items-center text-white
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Status
            </div>
            <div class="flex justify-center items-center text-white
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Motivo
            </div>
            <!-- <div class="flex justify-center items-center text-white
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-blue h-12">
              Status
            </div> -->
          </div>
          <div v-for="item in listaTransacoes"  :key ="item.DataTentativa">
            <div class="flex justify-center text-sm">
              <div class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12"
              >
              {{item.Mensalidade}}</div>
              <div class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12"
              >
              R$ {{item.DadosCobranca.Payment.Amount / 100}}0</div>
              <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              {{item.DataTentativa.toLocaleDateString("pt-BR").substr(0,10)}}
              </div>
              <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              {{item.Tentativa}}
              </div>
              <div
              v-if="item.DadosCobranca.Payment.Status === 2"
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              Pago
              </div>
              <div
              v-else
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              Não Pago
              </div>
              <div
              v-if="item.DadosCobranca.Payment.Status === 2"
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              -
              </div>
              <div
              v-else-if="item.DadosCobranca.Payment.ProviderReturnCode === '51'"
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              Limite indisponível
              </div>
              <div
              v-else-if="item.DadosCobranca.Payment.ProviderReturnCode === '82'"
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              Cartão Cancelado
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {
  usersCollection,
  assinaturasCollection,
  // marcasCollection,
  // modelosCollection,
  // planosCollection,
  functions,
} from "@/includes/firebase";

export default {
  name: "HistoricoRecorrenciaCliente",
  props: {
    idAssinatura: { type: String, required: true },
  },
  data() {
    return {
      listaTransacoes: [],
      assinatura: null,
      cliente: null,
    };
  },
  async created() {
    this.assinatura = await assinaturasCollection.doc(this.idAssinatura).get();
    const user = await usersCollection.where("email", "==", this.assinatura.data().cliente).get();
    console.log(this.assinatura.data().data);
    const dataAquisicao = this.assinatura.data().data.toDate();
    console.log(dataAquisicao);
    this.cliente = {
      cpf: user.docs[0].data().cpf,
      nome: user.docs[0].data().name,
      data: dataAquisicao.toLocaleString("pt-br").substr(0, 10),
      situacao: this.assinatura.data().situacao,
    };
    // console.log(this.cliente);
    const recorrencia = {
      RecurrentPaymentId: this.assinatura.data().tokenCobranca,
    };
    const getRecorrencia = functions.httpsCallable("getRecorrencia");
    const getTransacao = functions.httpsCallable("getTransacao");

    const tentativas = await getRecorrencia(recorrencia);

    for (let i = 0; i < tentativas.data.RecurrentPayment.RecurrentTransactions.length; i += 1) {
      const idTransacao = {
        PaymentId: tentativas.data.RecurrentPayment.RecurrentTransactions[i].TransactionId,
      };
      // eslint-disable-next-line
      const transacao = await getTransacao(idTransacao);
      const data = new Date(transacao.data.Payment.ReceivedDate);
      const dadosTransacao = {
        idTentativa: idTransacao.PaymentId,
        Tentativa: tentativas.data.RecurrentPayment.RecurrentTransactions[i].TryNumber,
        Mensalidade: tentativas.data.RecurrentPayment.RecurrentTransactions[i].PaymentNumber + 1,
        DataTentativa: data,
        DadosCobranca: transacao.data,
      };
      this.listaTransacoes.push(dadosTransacao);
    }
    // eslint-disable-next-line
    this.listaTransacoes.sort(function (a, b) {
      // eslint-disable-next-line
      let data1 = new Date(a.DataTentativa);
      // eslint-disable-next-line
      let data2 = new Date(b.DataTentativa);

      if (data1 > data2) return -1;
      if (data1 < data2) return 1;
      return 0;
    });
    console.log(this.listaTransacoes);
  },
  methods: {
  },
};
</script>
