import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyCmySAhlWFUdE5qvdQUPemjREUb61xcjug",
  authDomain: "caramelo.vip", // "clube-reparo.firebaseapp.com",
  projectId: "clube-reparo",
  storageBucket: "clube-reparo.appspot.com",
  appId: "1:233875937833:web:e7d8facd47b347fa6d5a38",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const secondaryApp = firebase.initializeApp(firebaseConfig, "Secondary");

const auth = firebase.auth();
const authAux = secondaryApp.auth();
const db = firebase.firestore();
const storage = firebase.storage();

if (window.location.hostname === 'localhost') {
  firebase.firestore().useEmulator('localhost', 8081);
  firebase.functions().useEmulator('localhost', 5001);
  /* OLD implementation */
  // Firebase.firestore().settings({ host: 'localhost:8080', ssl: false });
  // Firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

const usersCollection = db.collection("users");
const marcasCollection = db.collection("marcas");
const modelosCollection = db.collection("modelos");
const planosCollection = db.collection("planos");

const assinaturasCollection = db.collection("assinaturas");

const motivosCollecion = db.collection("motivosCancelamento");

const lojasCollection = db.collection("lojas");

const acionamentoReparoCollection = db.collection("acionamentoReparo");

const emailsCollection = db.collection("emails");

const logErrosAssinaturasCollection = db.collection("logErrosAssinaturas");

const functions = firebase.functions();

export {
  auth,
  authAux,
  db,
  storage,
  usersCollection,
  marcasCollection,
  modelosCollection,
  planosCollection,
  assinaturasCollection,
  motivosCollecion,
  lojasCollection,
  acionamentoReparoCollection,
  emailsCollection,
  logErrosAssinaturasCollection,
  functions,
};
