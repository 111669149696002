<template>
  <main>
  <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-store text-orange"></i>
        </button>
        <div class="z-50 text-left ml-8">
          <!-- Info -->
          <div class="text-3xl font-bold">Cadastro de modelos</div>
        </div>
      </div>
    </section>
    <!-- Main Content -->
    <section class="container mx-auto">
      <div
        class="bg-white rounded border border-gray-200 relative flex flex-col"
      >
        <!-- component -->
        <div class="p-5">
          <div class="mt-8 p-4">
            <!-- Forms -->
            <div>
              <div
                class="
                  flex flex-col
                  md:flex-row
                  border
                  rounded
                  border-gray-200
                  bg-gray-100
                "
              >
                <span
                  class="
                    font-bold
                    text-gray-600 text-xs
                    leading-8
                    uppercase
                    ml-3
                  "
                >
                  Dados do modelo
                </span>
              </div>
              <div>
                <p
                  class="
                    text-red-600 text-xs
                    leading-8
                    uppercase
                    ml-3
                  "
                >para alterar um modelo informe o nome</p>
              </div>
              <vee-form
                :validation-schema="modeloSchema"
                ref="frmDados"
              >
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Faixa
                    </div>
                    <div
                    class="
                      bg-white
                      my-2
                      flex
                      border border-gray-200
                      rounded
                      svelte-1l8159u
                    "
                  >
                    <Multiselect
                      v-model="modelo.faixa"
                      :options="listaPlanos"
                      :searchable="true"
                      @change="selecionarFaixa"
                      label="nome"
                      class="
                        px-2
                        appearance-none
                        outline-none
                        w-full
                        text-gray-800
                        border-none
                      "
                    />
                  </div>
                  <span
                    class="text-red-600 font-bold text-xs uppercase leading-8"
                    v-show="!isValidFaixa()"
                  >
                    O campo Faixa é obrigatório!</span
                  >
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      sku sismeta caramelo
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="sku_sismeta_caramelo"
                        v-model="modelo.sku_sismeta_caramelo"
                        placeholder="Digite a SKU SISMETA Caramelo..."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="sku_sismeta_caramelo"
                    />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Marca
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <Multiselect
                        name="marca"
                        v-model="modelo.marca"
                        :options="listaMarcas"
                        :searchable="true"
                        @change="selecionarMarca"
                        label="nome"
                        class="
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                          border-none
                        "
                      />
                    </div>
                    <span
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      v-show="!isValidMarca()"
                    >
                      O campo Marca é obrigatório!</span
                    >
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Nome
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="nome"
                        v-model="modelo.nome"
                        placeholder="Digite o Modelo..."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                        @change="consultaModelo"
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="nome"
                    />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row">
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      tipo de reparo
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="tipoReparo"
                        v-model="modelo.tipoReparo"
                        placeholder="Digite o Tipo de Reparo.."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                      />
                    </div>
                    <ErrorMessage
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      name="tipoReparo"
                    />
                  </div>
                  <div class="w-full mx-2 flex-1 svelte-1l8159u">
                    <div
                      class="
                        font-bold
                        h-6
                        mt-3
                        text-gray-600 text-xs
                        leading-8
                        uppercase
                      "
                    >
                      Custo do Reparo
                    </div>
                    <div
                      class="
                        bg-white
                        my-2
                        p-1
                        flex
                        border border-gray-200
                        rounded
                        svelte-1l8159u
                      "
                    >
                      <vee-field
                        name="valorReparo"
                        v-model="modelo.valorReparo"
                        placeholder="Digite o Valor do Reparo..."
                        class="
                          p-1
                          px-2
                          appearance-none
                          outline-none
                          w-full
                          text-gray-800
                        "
                        @change="formataParaMoeda"
                      />
                    </div>
                    <span
                      class="text-red-600 font-bold text-xs uppercase leading-8"
                      v-show="!isValidCusto()"
                    >
                      O campo Custo é obrigatório!</span
                    >
                  </div>
                </div>
              </vee-form>
              <vee-form enctype="multipart/form-data">
                <input
                  class="form-control
                    block
                    w-1/2
                    px-3
                    py-1.5
                    text-base
                    font-normal
                    text-gray-700
                    bg-white bg-clip-padding
                    border border-solid border-gray-300
                    rounded
                    transition
                    ease-in-out
                    m-0
                    focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none
                  "
                  type="file" @change="onFileChange" accept=".csv"
                />
              </vee-form>
            </div>
            <div class="flex p-2 mt-4">
              <div class="flex-auto flex flex-row-reverse">
                <button
                  class="
                    text-base
                    ml-2
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  @click.prevent="cadastrar()"
                >
                  Cadastrar
                </button>
                <button
                  class="
                    text-base
                    hover:scale-110
                    focus:outline-none
                    ml-2
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  @click.prevent="importar()"
                >
                  Importar
                </button>
                <button
                  class="
                    text-base
                    hover:scale-110
                    focus:outline-none
                    flex
                    justify-center
                    px-4
                    py-2
                    rounded
                    font-bold
                    cursor-pointer
                    hover:bg-blue
                    bg-orange
                    text-white
                    border
                    duration-200
                    ease-in-out
                    border-gray-300
                    transition
                  "
                  @click.prevent="alterar()"
                >
                  Alterar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mask } from "vue-the-mask";
import Multiselect from "@vueform/multiselect";
import {
  modelosCollection,
  planosCollection,
  marcasCollection,
} from "@/includes/firebase";

export default {
  name: "CadastroModelos",
  data() {
    return {
      listaMarcas: [],
      listaPlanos: [],
      alertMsg: "",
      file: "",
      lista: [],
      modelo: {
        codigo: "",
        faixa: "",
        sku_sismeta_caramelo: "",
        marca: "",
        nome: "",
        tipoReparo: "",
        valorReparo: "",
      },
      modeloSchema: {
        faixa: "required",
        sku_sismeta_caramelo: "required",
        marca: "required",
        nome: "required:min:2",
        tipoReparo: "required",
        valorReparo: "required",
      },
    };
  },
  directives: { mask },
  components: {
    Multiselect,
  },
  async created() {
    this.listarPlanos();
    this.listarMarcas();
  },
  methods: {
    formataParaMoeda() {
      this.modelo.valorReparo = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(this.modelo.valorReparo);
    },
    // lê os dados do arquivo
    onFileChange(arq) {
      const files = arq.target.files[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.lista.push(e.target.result.split("\r\n"));
      };
      reader.readAsText(files, 'ISO-8859-1');
    },
    // grava os dados do csv
    async importar() {
      const lista = [];
      let qtdLinhas = 0;
      let contadorListaSemHeader = 0;
      // eslint-disable-next-line
      this.lista[0].forEach(() => qtdLinhas += 1);
      for (let i = 1; i < qtdLinhas; i += 1) {
        // console.log(this.lista);
        if (this.lista[0][i] !== '') {
          lista[contadorListaSemHeader] = this.lista[0][i].split(";");
          contadorListaSemHeader += 1;
        }
      }
      for (let i = 0; i < lista.length; i += 1) {
        let marcaDaLinha = "";
        for (let j = 0; j < this.listaMarcas.length; j += 1) {
          if (this.listaMarcas[j].nome === lista[i][2]) {
            // eslint-disable-next-line
            marcaDaLinha = this.listaMarcas[j].value;
            break;
          }
        }
        let planoDaLinha = "";
        for (let j = 0; j < this.listaPlanos.length; j += 1) {
          if (this.listaPlanos[j].faixa === lista[i][0]) {
            // eslint-disable-next-line
            planoDaLinha = this.listaPlanos[j].value;
            break;
          }
        }
        // eslint-disable-next-line
        const snapshot = await modelosCollection.where("nome","==", lista[i][3]).get();        
        if (snapshot.docs.length === 0) {
          const modelo = {
            faixa: planoDaLinha,
            sku_sismeta_caramelo: lista[i][1],
            marca: marcaDaLinha,
            nome: lista[i][3],
            tipoReparo: lista[i][4],
            valorReparo: lista[i][5],
            status: !!lista[i][6],
          };
          try {
            /* eslint-disable-next-line */
            await modelosCollection.add(modelo);
          } catch (e) {
            /* eslint-disable-next-line */
            alert("Um erro inesperado aconteceu. Por favor, tente novamente.");
            /* eslint-disable-next-line */
            return;
          }
        } else {
          try {
            let planoDaLinhaExistente = "";
            for (let j = 0; j < this.listaPlanos.length; j += 1) {
              if (this.listaPlanos[j].nome === lista[i][0]) {
                // eslint-disable-next-line
                planoDaLinhaExistente = this.listaPlanos[j].value;
                break;
              }
            }
            // eslint-disable-next-line
            await modelosCollection.doc(snapshot.docs[0].id).update({
              faixa: planoDaLinhaExistente,
              valorReparo: lista[i][5],
            });
            // eslint-disable-next-line
          } catch (e) {
            console.log(e);
            // eslint-disable-next-line
            alert("Algo deu errado, tente novamente.");
            return;
          }
        }
        this.reg_alert_variant = "bg-green-500";
        this.reg_alert_msg = "Sucesso! Importação realizada.";
        /* eslint-disable-next-line */
        alert("Sucesso! Importação realizada");
      }
    },
    async cadastrar() {
      if (this.modelo.codigo === "") {
        const modelo = {
          faixa: this.modelo.faixa,
          sku_sismeta_caramelo: this.modelo.sku_sismeta_caramelo,
          marca: this.modelo.marca,
          nome: this.modelo.nome,
          tipoReparo: this.modelo.tipoReparo,
          valorReparo: this.modelo.valorReparo,
          status: true,
        };
        /* eslint-disable-next-line */
        if (modelo.faixa !== '' && modelo.sku_sismeta_caramelo !== '' && modelo.marca !== '' && modelo.nome !== '' && modelo.tipoReparo !== '' && modelo.valorReparo !== '') {
          try {
            await modelosCollection.add(modelo);
          } catch (e) {
            /* eslint-disable-next-line */
            alert("Um erro inesperado aconteceu. Por favor, tente novamente.");

            /* eslint-disable-next-line */
            return;
          }
        }
        /* eslint-disable-next-line */
        this.modelo.faixa = "";
        this.modelo.sku_sismeta_caramelo = "";
        this.modelo.marca = "";
        this.modelo.nome = "";
        this.modelo.tipoReparo = "";
        this.modelo.valorReparo = "";
        /* eslint-disable-next-line */
        alert("Sucesso! modelo cadastrado");
        // this.reg_alert_variant = "bg-green-500";
        // this.reg_alert_msg = "Sucesso! Importação realizada.";
      }
    },
    async consultaModelo() {
      const strModelo = this.modelo.nome.toUpperCase();
      console.log(strModelo);
      const snapshot = await modelosCollection.where("nome", "==", strModelo).get();
      if (snapshot.docs.length > 0) {
        this.modelo.codigo = snapshot.docs.id;
        this.modelo.faixa = this.selecionarFaixa(snapshot.docs[0].data().faixa);
        this.modelo.sku_sismeta_caramelo = snapshot.docs[0].data().sku_sismeta_caramelo;
        this.modelo.marca = snapshot.docs[0].data().marca;
        this.modelo.nome = snapshot.docs[0].data().nome;
        this.modelo.tipoReparo = snapshot.docs[0].data().tipoReparo;
        this.modelo.valorReparo = snapshot.docs[0].data().valorReparo;
      }
    },
    async alterar() {
      if (this.modelo.codigo !== "") {
        try {
          await modelosCollection.doc(this.modelo.codigo).update({
            faixa: this.modelo.faixa,
            valorReparo: this.modelo.valorReparo,
          });
          // eslint-disable-next-line
          alert("Modelo atualizado");
        } catch (e) {
          console.log(e);
          // eslint-disable-next-line
          alert("Algo deu errado, tente novamente.");
        }
      }
    },
    selecionarMarca(value) {
      this.modelo.marca = value;
      console.log(this.modelo.marca);
    },
    isValidFaixa() {
      return this.modelo.faixa !== '' && this.modelo.faixa !== null;
    },
    async selecionarFaixa(value) {
      this.modelo.faixa = value;
    },
    async listarPlanos() {
      const snapshot = await planosCollection.orderBy("faixa", "asc").get();

      snapshot.docs.forEach((document) => {
        const plano = {
          value: document.id,
          nome: document.data().faixa,
        };
        this.listaPlanos.push(plano);
      });
    },
    async listarMarcas() {
      const snapshot = await marcasCollection.where("status", "==", true).orderBy("nome", "asc").get();

      snapshot.docs.forEach((document) => {
        const marca = {
          value: document.id,
          nome: document.data().nome,
        };
        this.listaMarcas.push(marca);
      });
    },
    isValidMarca() {
      return this.modelo.marca !== "" && this.modelo.marca !== null;
    },
    isValidCusto() {
      return this.modelo.valorReparo !== "" && this.modelo.valorReparo !== null;
    },
  },
};
</script>
