<template>
  <div
    class="text-white text-center font-bold p-4 mb-4"
    v-if="recovery_show_alert"
    :class="recovery_alert_variant"
  >
    {{ recovery_alert_msg }}
  </div>
  <vee-form :validation-schema="recoverySchema" @submit="recovery">
    <!-- Email -->
    <div class="mb-3">
      <label class="inline-block mb-2">Email</label>
      <vee-field
        type="email"
        name="email"
        class="
          block
          w-full
          py-1.5
          px-3
          text-gray-800
          border border-gray-300
          transition
          duration-500
          focus:outline-none
          focus:border-black
          rounded
        "
        placeholder="Digite seu Email"
      />
      <ErrorMessage class="text-red-600" name="email" />
    </div>
    <button
      type="submit"
      :disabled="recovery_in_submission"
      class="
        block
        w-full
        bg-orange
        text-white
        py-1.5
        px-3
        rounded
        transition
        hover:bg-black
      "
    >
      Recuperar senha
    </button>
  </vee-form>
</template>

<script>
import { authAux } from "@/includes/firebase";

export default {
  name: "RecoveryPwdForm",
  data() {
    return {
      recoverySchema: {
        email: "required|email",
      },
      recovery_in_submission: false,
      recovery_show_alert: false,
      recovery_alert_variant: "bg-blue-500",
      recovery_alert_msg: "Por favor, espere! Estamos recuperando sua senha do sistema.",
    };
  },
  methods: {
    async recovery(values) {
      this.recovery_in_submission = true;
      this.recovery_show_alert = true;
      this.recovery_alert_variant = "bg-blue-500";
      this.recovery_alert_msg = "Por favor, espere! Estamos recuperando sua senha do sistema.";

      try {
        await authAux.sendPasswordResetEmail(values.email);
      } catch (error) {
        console.log(error);
        this.recovery_in_submission = false;
        this.recovery_alert_variant = "bg-red-500";
        this.recovery_alert_msg = "Dados de acesso inválidos.";
        return;
      }

      this.recovery_alert_variant = "bg-green-500";
      this.recovery_alert_msg = "Sucesso! E-mail enviado para recuperação de senha do sistema.";
      window.location.reload();
    },
  },
};
</script>
