<template>
  <main>
    <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex Items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-store text-orange"></i>
          </button>
          <div class="z-50 text-left ml-8">
            <!-- Info -->
            <div class="text-3xl font-bold">Conciliador</div>
            <div>
              Exportação da relação de vendas
            </div>
          </div>
        </div>
      </section>
      <!-- Main Content -->
      <section class="container mx-auto">
        <div
          class="bg-white rounded border border-gray-200 relative flex flex-col mb-8"
        >
          <!-- component -->
          <div class="p-5">
            <div class="p-4 flex justify-center" v-if="this.profile !== 'gerente'">
              <div class="flex-col md:flex-row w-full">
                <div class="w-1/3 mx-2 flex-1 svelte-1l8159u">
                  <div
                    class="
                      bg-white
                      my-2
                      p-1
                      flex
                      justify-center
                      Items-center
                      svelte-1l8159u
                      w-full
                      uppercase
                    "
                  >
                    <!-- <o-field label="Selecione um intervalo">
                      <o-datepicker :searchable="true" placeholder="Clique para selecionar" icon="calendar" v-model="dates" editable range class="border rounded border-gray-200 p-2"> </o-datepicker>
                    </o-field> -->
                    <o-field label="Data inicial:" class="text-xs uppercase font-bold text-gray-600 mt-3 w-1/3">
                      <o-datepicker :searchable="true" editable v-model="dataInicial" :locale="pt-br" placeholder="Clique para selecionar..." class="border rounded border-gray-200 py-3 px-2"> </o-datepicker>
                    </o-field>
                    <o-field label="Data final:" class="ml-8 text-xs uppercase font-bold text-gray-600 mt-3 w-1/3">
                      <o-datepicker :searchable="true"  editable v-model="dataFinal" :locale="pt-br" placeholder="Clique para selecionar..." trap-focus class="border rounded border-gray-200 py-3 px-2"> </o-datepicker>
                    </o-field>
                  </div>
                </div>
                <!-- <div class="w-1/2 mx-2 flex-1 svelte-1l8159u">
                </div> -->
                <ul class="container mx-auto">
                  <li class="p-4 border border-gray-200">
                    <div>
                      <div class="font-bold">
                        <i class="fas fa-question w-7 text-purple-700"></i>
                        <span
                          class="py-4 px-2 font-semibold"
                        >
                          Transações Recebidas
                        </span>
                        <div class="text-xs ml-10">
                          (Valores recebidos na data selecionada, a data de captura é no mês anterior.)
                        </div>
                        <button
                          class="
                            text-left
                            w-1/8
                            text-base
                            hover:scale-110
                            focus:outline-none
                            justify-left
                            flex
                            px-4
                            py-2
                            rounded
                            font-bold
                            cursor-pointer
                            hover:bg-black
                            bg-orange
                            text-white
                            border
                            duration-200
                            ease-in-out
                            border-white
                            transition
                            mt-5
                          "
                            @click.self="exportarExcel()"
                        >
                          Exportar
                        </button>
                      </div>
                    </div>
                  </li>
                  <li class="p-4 border border-gray-200"
                  >
                    <div>
                      <div class="font-bold">
                        <i class="fas fa-question w-7 text-purple-700"></i>
                        <span
                          class="py-4 px-2 font-semibold"
                        >
                          Transações sem sucesso
                        </span>
                        <div class="text-xs ml-10">
                          (Todas as transações não capturadas no intervalo.)
                        </div>
                        <div>
                          <button
                            class="
                              text-left
                              w-1/8
                              text-base
                              hover:scale-110
                              focus:outline-none
                              justify-left
                              flex
                              px-4
                              py-2
                              rounded
                              font-bold
                              cursor-pointer
                              hover:bg-black
                              bg-orange
                              text-white
                              border
                              duration-200
                              ease-in-out
                              border-white
                              transition
                              mt-5
                            "
                            @click.self="exportarExcelNaoPagos()"
                          >
                            Exportar
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="p-4 border border-gray-200"
                  >
                    <div>
                      <div class="font-bold">
                        <i class="fas fa-question w-7 text-purple-700"></i>
                        <span
                          class="py-4 px-2 font-semibold"
                        >
                          Previsão Recebíveis
                        </span>
                        <div class="text-xs ml-10" >
                          (Exporta a previsão de assinaturas pagas para o mês seguinte.)
                        </div>
                        <div>
                          <button
                            class="
                              text-left
                              w-1/8
                              text-base
                              hover:scale-110
                              focus:outline-none
                              justify-left
                              flex
                              px-4
                              py-2
                              rounded
                              font-bold
                              cursor-pointer
                              hover:bg-black
                              bg-orange
                              text-white
                              border
                              duration-200
                              ease-in-out
                              border-white
                              transition
                              mt-5
                            "
                            @click.self="exportarPrevisaoRecebiveis()"
                          >
                            Exportar
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="p-4 border border-gray-200"
                  >
                    <div>
                      <div class="font-bold">
                        <i class="fas fa-question w-7 text-purple-700"></i>
                        <span
                          class="py-4 px-2 font-semibold"
                        >
                          Todas as Transações no Intervalo
                        </span>
                        <div class="text-xs ml-10">
                          (Todas as transações capturadas e não capturadas no intervalo.)
                        </div>
                        <div>
                          <button
                            class="
                              text-left
                              w-1/8
                              text-base
                              hover:scale-110
                              focus:outline-none
                              justify-left
                              flex
                              px-4
                              py-2
                              rounded
                              font-bold
                              cursor-pointer
                              hover:bg-black
                              bg-orange
                              text-white
                              border
                              duration-200
                              ease-in-out
                              border-white
                              transition
                              mt-5
                            "
                            @click.self="exportarExcelTodas()"
                          >
                            Exportar
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState } from "vuex";
import * as XLSX from "xlsx";
import {
  functions,
  assinaturasCollection,
} from "@/includes/firebase";

export default {
  name: "ExportarConciliacao",
  computed: {
    ...mapState(["userLoggedIn"]),
  },
  data() {
    return {
      taxas: [
        {
          bandeira: "Visa",
          taxa: 0.0253,
        },
        {
          bandeira: "Master",
          taxa: 0.0253,
        },
        {
          bandeira: "Dinners",
          taxa: 0.0253,
        },
        {
          bandeira: "Elo",
          taxa: 0.0326,
        },
        {
          bandeira: "Amex",
          taxa: 0.0320,
        },
        {
          bandeira: "Hiper",
          taxa: 0.0334,
        },
      ],
      Pagamentos: [],
      listaAssinaturas: [],
      listaRecorrencias: [],
      profile: "",
      carregamento: false,
      filtroSelecionado: "",
      listaFiltro: [],
      dates: [],
      dataInicial: null,
      dataFinal: null,
    };
  },
  async created() {
    const assinaturas = await assinaturasCollection.get();
    // ORGANIZAÇÃO DOS DADOS DESEJADOS EM UM ARRAY DE ASSINATURAS
    for (let i = 0; i < assinaturas.docs.length; i += 1) {
      // console.log(assinaturas.docs[i].data());
      if (assinaturas.docs[i].data().tokenCobranca !== "") {
        const assinatura = {
          idAssinatura: assinaturas.docs[i].id,
          cliente: assinaturas.docs[i].data().cliente,
          tokenCobranca: assinaturas.docs[i].data().tokenCobranca,
          valorCobertura: assinaturas.docs[i].data().valorCobertura,
          dataAquisicao: assinaturas.docs[i].data().data,
          situacao: assinaturas.docs[i].data().situacao,
          cartao: assinaturas.docs[i].data().cartao,
        };
        this.listaAssinaturas.push(assinatura);
      }
    }

    for (let i = 0; i < this.listaAssinaturas.length; i += 1) {
      // console.log(this.listaAssinaturas.length);
      if (this.listaAssinaturas[i].tokenCobranca !== "") {
        const recorrencia = functions.httpsCallable('getRecorrencia');
        // console.log(this.listaAssinaturas[i].tokenCobranca);
        const token = {
          RecurrentPaymentId: this.listaAssinaturas[i].tokenCobranca,
        };
        // eslint-disable-next-line
        const retornoRecorrencia = await recorrencia(token);
        this.listaRecorrencias.push({
          Assinatura: this.listaAssinaturas[i].idAssinatura,
          dataAquisicao: this.listaAssinaturas[i].dataAquisicao,
          Cliente: this.listaAssinaturas[i].cliente,
          Valor: this.listaAssinaturas[i].valorCobertura,
          Situacao: this.listaAssinaturas[i].situacao,
          Recorrencias: retornoRecorrencia.data.RecurrentPayment.RecurrentTransactions,
          Cartao: this.listaAssinaturas[i].cartao,
        });
      }
    }
  },
  methods: {
    // EXPORTA TODAS AS TRANSAÇÕES BEM SUCEDIDAS
    async exportarExcel() {
      // eslint-disable-next-line
      var options = {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
      };
      // ARRAY ONDE SERÃO ARMAZENADOS OS OBJETOS PARA EXPORTAÇÃO
      // eslint-disable-next-line
      let arrayJson = [];
      arrayJson[0] = [];
      // ARRAY ONDE SERÃO ARMAZENADOS OS OBJETOS PARA EXPORTAÇÃO
      // eslint-disable-next-line
      let arrayJsonResumo = [];
      arrayJsonResumo[0] = [];
      // OBJETOS QUE ARMAZENARÃO OS DADOS A SEREM EXPORTADOS
      let dataJson = null;
      let dataJsonResumo = null;
      if (this.dataInicial !== null && this.dataFinal !== null) {
        const obterToken = functions.httpsCallable('obterTokenOAuth');
        const responseToken = await obterToken();

        const filtro = {
          Authorization: responseToken.data.access_token,
          InitialForecastedDate: this.dataInicial.toLocaleString('ko-KR', options).replace(".", "-").replace(".", "-").replace(".", "")
            .replaceAll(" ", ""),
          FinalForecastedDate: this.dataFinal.toLocaleString('ko-KR', options).replace(".", "-").replace(".", "-").replace(".", "")
            .replaceAll(" ", ""),
        };
        const conciliar = functions.httpsCallable('conciliar');
        const response = await conciliar(filtro);

        console.log(response.data);

        for (let i = 0; i < response.data.Items.length; i += 1) {
          // eslint-disable-next-line
          let cliente = null;
          for (let k = 0; k < response.data.Items[i].ItemSchedules.length; k += 1) {
            for (let j = 0; j < this.listaRecorrencias.length; j += 1) {
              // console.log(this.listaRecorrencias[j].Recorrencias);
              const mensalidade = this.listaRecorrencias[j].Recorrencias.find((transacao) => transacao.TransactionId === response.data.Items[i].ItemSchedules[k].TransactionalEventId);
              if (mensalidade !== undefined && mensalidade !== null && mensalidade !== "") {
                cliente = {
                  Cliente: this.listaRecorrencias[j].Cliente,
                  Assinatura: this.listaRecorrencias[j].Assinatura,
                  dataAquisicao: this.listaRecorrencias[j].dataAquisicao,
                  Valor: this.listaRecorrencias[j].Valor,
                  Pagamento: mensalidade,
                  // Bandeira: this.getBrand(this.listaRecorrencias[j].Cartao),
                };
              }
            }
            if (cliente !== null && cliente !== undefined && cliente !== "") {
              break;
            }
          }
          for (let j = 0; j < response.data.Items[i].ItemSchedules.length; j += 1) {
            // for (let k = 0; k < this.listaRecorrencias.length; k += 1) {
            console.log(response.data.Items[i].ItemSchedules[j]);
            // OBJETO COM OS DADOS DA TRANSAÇÃO E DO CLIENTE
            if (cliente !== "" && cliente !== undefined && cliente !== null) {
              // console.log(cliente);
              dataJson = {
                Cliente: cliente.Cliente,
                Assinatura: cliente.Assinatura,
                Mensalidade: cliente.Pagamento.PaymentNumber + 1,
                TentativaCobranca: cliente.Pagamento.TryNumber,
                DataCaptura: response.data.Items[i].ItemSchedules[j].PaymentDetails.CaptureDate,
                DataAutorizacao: response.data.Items[i].ItemSchedules[j].PaymentDetails.AuthorizationDate,
                DataPrevistaPagamento: response.data.Items[i].ForecastedDate,
                ValorBruto: cliente.Valor,
                ValorLiquidoParcela: response.data.Items[i].ItemSchedules[j].InstallmentNetAmount / 100,
                SomatorioLiquidoCentavos: response.data.Items[i].ForecastedNetAmount / 100,
                TipoTransacao: response.data.Items[i].ItemSchedules[j].ScheduleType,
                IdTransacional: response.data.Items[i].ItemSchedules[j].PaymentDetails.PaymentId,
                // TransactionalEventId: response.data.Items[i].ItemSchedules[j].TransactionalEventId,
                IdEventoAgenda: response.data.Items[i].ItemSchedules[j].ScheduleId,
                FormaPagamento: response.data.Items[i].Product,
                Bandeira: response.data.Items[i].Brand,
                TipoDocumento: response.data.Items[i].ItemSchedules[j].MerchantDetails.DocumentType,
                CNPJRecebente: response.data.Items[i].DocumentNumber,
                NomeFantasia: response.data.Items[i].ItemSchedules[j].MerchantDetails.FancyName,
                RazaoSocial: response.data.Items[i].ItemSchedules[j].MerchantDetails.CorporateName,
                CategoriaRecebente: response.data.Items[i].ItemSchedules[j].MerchantDetails.MerchantType,
                IdRecebente: response.data.Items[i].ItemSchedules[j].MerchantDetails.MerchantId,
                // TransactionalEventType: response.data.Items[i].ItemSchedules[j].TransactionalEventType,
                // InstallmentNumber: response.data.Items[i].ItemSchedules[j].InstallmentNumber,
                Parcelas: response.data.Items[i].ItemSchedules[j].PaymentDetails.Installments,
                NumeroPedido: response.data.Items[i].ItemSchedules[j].PaymentDetails.OrderId,
                NumeroAfiliado: response.data.Items[i].ItemSchedules[j].PaymentDetails.AffiliationCode,
                Nsu: response.data.Items[i].ItemSchedules[j].PaymentDetails.Nsu,
              };
              dataJsonResumo = {
                Cliente: cliente.Cliente,
                Assinatura: cliente.Assinatura,
                Mensalidade: cliente.Pagamento.PaymentNumber + 1,
                TentativaCobranca: cliente.Pagamento.TryNumber,
                DataCaptura: response.data.Items[i].ItemSchedules[j].PaymentDetails.CaptureDate,
                // DataAutorizacao: response.data.Items[i].ItemSchedules[j].PaymentDetails.AuthorizationDate,
                DataPrevistaPagamento: response.data.Items[i].ForecastedDate,
                ValorBruto: cliente.Valor,
                ValorLiquidoParcela: response.data.Items[i].ItemSchedules[j].InstallmentNetAmount / 100,
                SomatorioLiquidoCentavos: response.data.Items[i].ForecastedNetAmount / 100,
                TipoTransacao: response.data.Items[i].ItemSchedules[j].ScheduleType,
                IdTransacional: response.data.Items[i].ItemSchedules[j].PaymentDetails.PaymentId,
                // TransactionalEventId: response.data.Items[i].ItemSchedules[j].TransactionalEventId,
                // IdEventoAgenda: response.data.Items[i].ItemSchedules[j].ScheduleId,
                FormaPagamento: response.data.Items[i].Product,
                Bandeira: response.data.Items[i].Brand,
                // TipoDocumento: response.data.Items[i].ItemSchedules[j].MerchantDetails.DocumentType,
                // CNPJRecebente: response.data.Items[i].DocumentNumber,
                NomeFantasia: response.data.Items[i].ItemSchedules[j].MerchantDetails.FancyName,
                // RazaoSocial: response.data.Items[i].ItemSchedules[j].MerchantDetails.CorporateName,
                CategoriaRecebente: response.data.Items[i].ItemSchedules[j].MerchantDetails.MerchantType,
                // IdRecebente: response.data.Items[i].ItemSchedules[j].MerchantDetails.MerchantId,
                // TransactionalEventType: response.data.Items[i].ItemSchedules[j].TransactionalEventType,
                // InstallmentNumber: response.data.Items[i].ItemSchedules[j].InstallmentNumber,
                // Parcelas: response.data.Items[i].ItemSchedules[j].PaymentDetails.Installments,
                // NumeroPedido: response.data.Items[i].ItemSchedules[j].PaymentDetails.OrderId,
                // NumeroAfiliado: response.data.Items[i].ItemSchedules[j].PaymentDetails.AffiliationCode,
                // Nsu: response.data.Items[i].ItemSchedules[j].PaymentDetails.Nsu,
              };
              arrayJsonResumo[0].push(dataJsonResumo);
              arrayJson[0].push(dataJson);
            }
          }
        }
        // console.log(response);
      }
      // console.log(arrayJson);
      const ws = XLSX.utils.json_to_sheet(arrayJson[0]);
      const ws2 = XLSX.utils.json_to_sheet(arrayJsonResumo[0]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.sheet_add_aoa(ws, [[
        "Cliente",
        "Assinatura",
        "Mensalidade",
        "Tentativa de Cobrança",
        "Data da Captura",
        "Data da Autorização",
        "Data Prevista de Pagamento",
        "Valor Bruto",
        "Valor Líquido da Parcela",
        "Somatório Líquido em Centavos",
        "Tipo de Transação",
        "Id Transacional",
        "Id do Evento na Agenda",
        "Forma de Pagamento",
        "Bandeira",
        "Tipo de Documento",
        "CNPJ do Recebente",
        "Razão Social",
        "Nome Fantasia",
        "Categoria do Recebente",
        "Id do Recebente",
        "Parcela",
        "Número do Pedido da Loja",
        "Número de Afiliado",
        "NSU",
      ]], { origin: "A1" });
      XLSX.utils.book_append_sheet(wb, ws, "Detalhamento");
      XLSX.utils.book_append_sheet(wb, ws2, "Resumo");
      XLSX.writeFile(wb, 'Transacoes_recebidas.xlsx');
    },
    monthDiff(d1, d2) {
      // eslint-disable-next-line
      let months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },
    async exportarExcelNaoPagos() {
      // eslint-disable-next-line
      let arrayJson = [];
      arrayJson[0] = [];
      let naoPaga = {};
      // CLOUD FUNCTION PARA OBTER AS INFOS DA TRANSACAO
      const obterTransacao = functions.httpsCallable('getTransacao');
      for (let i = 0; i < this.listaRecorrencias.length; i += 1) {
        // DATA QUE A ASSINATURA FOI CRIADA
        const dataAssinatura = this.listaRecorrencias[i].dataAquisicao.toDate();
        // DATA INCIAL DA BUSCA
        const dataInicio = new Date(this.dataInicial);
        // DATA FINAL DA BUSCA
        const dataFim = new Date(this.dataFinal);
        if (dataAssinatura < dataFim) {
          for (let j = 0; j < this.listaRecorrencias[i].Recorrencias.length; j += 1) {
            const codigo = {
              // CÓDIGO DA TRANSAÇÃO
              PaymentId: this.listaRecorrencias[i].Recorrencias[j].TransactionId,
            };
            // CLOUD FUNCTION PARA OBTER AS INFOS DA TRANSACAO
            // eslint-disable-next-line
            const transacao = await obterTransacao(codigo);
            const dataInicioCaptura = new Date(dataInicio.getFullYear(), dataInicio.getMonth() - 1, dataInicio.getDate());
            // eslint-disable-next-line
            let dataFimCaptura = null;
            // VALIDA SE O MES DE CONSULTA É DE 30 DIAS
            if (dataFim.getDate() === 30 && (dataFim.getMonth() === 3 || dataFim.getMonth() === 5 || dataFim.getMonth() === 8 || dataFim.getMonth() === 10)) {
              dataFimCaptura = new Date(dataFim.getFullYear(), dataFim.getMonth(), "01");
            } else {
              dataFimCaptura = new Date(dataFim.getFullYear(), dataFim.getMonth() - 1, dataFim.getDate());
            }
            // DATA DE TENTATIVA DE COBRANÇA FEITA PELA BRASPAG
            const dataCobranca = new Date(transacao.data.Payment.ReceivedDate);

            if (dataCobranca.getMonth() === dataFim.getMonth()) {
              console.log(transacao.data);
            }
            if (transacao.data.Payment.Status !== 2) {
              if (this.listaRecorrencias[i].Recorrencias[j].TryNumber <= 2 && dataCobranca >= dataInicioCaptura && dataCobranca <= dataFimCaptura) {
                // eslint-disable-next-line
                let motivoErro = ""
                // MOTIVOS DO ERRO NO PAGAMENTO
                if (transacao.data.Payment.ProviderReturnCode === "51") {
                  motivoErro = "Sem limite disponível";
                } else if (transacao.data.Payment.ProviderReturnCode === "82") {
                  motivoErro = "Cartão Inválido";
                }
                naoPaga = {
                  Cliente: this.listaRecorrencias[i].Cliente,
                  Valor: transacao.data.Payment.Amount / 100,
                  Bandeira: this.getBrand(this.listaRecorrencias[i].Cartao),
                  Situacao: "Não Pago",
                  DataTentativa: transacao.data.Payment.ReceivedDate,
                  Codigo: transacao.data.Payment.ProviderReturnCode,
                  Motivo: motivoErro,
                  Tentativa: this.listaRecorrencias[i].Recorrencias[j].TryNumber,
                  Mensalidade: this.listaRecorrencias[i].Recorrencias[j].PaymentNumber + 1,
                };
                console.log(naoPaga);
                arrayJson[0].push(naoPaga);
              } else if (dataCobranca.getDate() >= 8 && dataCobranca >= dataInicioCaptura && dataCobranca <= dataFimCaptura) {
                // eslint-disable-next-line
                let motivoErro = ""
                // MOTIVOS DO ERRO NO PAGAMENTO
                if (transacao.data.Payment.ProviderReturnCode === "51") {
                  motivoErro = "Sem limite disponível";
                } else if (transacao.data.Payment.ProviderReturnCode === "82") {
                  motivoErro = "Cartão Inválido";
                }
                naoPaga = {
                  Cliente: this.listaRecorrencias[i].Cliente,
                  Valor: transacao.data.Payment.Amount / 100,
                  Bandeira: this.getBrand(this.listaRecorrencias[i].Cartao),
                  Situacao: "Não Pago",
                  DataTentativa: transacao.data.Payment.ReceivedDate,
                  Codigo: transacao.data.Payment.ProviderReturnCode,
                  Motivo: motivoErro,
                  Tentativa: this.listaRecorrencias[i].Recorrencias[j].TryNumber,
                  Mensalidade: this.listaRecorrencias[i].Recorrencias[j].PaymentNumber + 1,
                };
                console.log(naoPaga);
                arrayJson[0].push(naoPaga);
              } else if (this.listaRecorrencias[i].Recorrencias[j].TryNumber >= 2 && dataCobranca.getMonth() === dataFim.getMonth() && dataCobranca.getDate() < 13 && dataAssinatura.getDate() > 19) {
                // eslint-disable-next-line
                let motivoErro = ""
                // MOTIVOS DO ERRO NO PAGAMENTO
                if (transacao.data.Payment.ProviderReturnCode === "51") {
                  motivoErro = "Sem limite disponível";
                } else if (transacao.data.Payment.ProviderReturnCode === "82") {
                  motivoErro = "Cartão Inválido";
                }
                naoPaga = {
                  Cliente: this.listaRecorrencias[i].Cliente,
                  Valor: transacao.data.Payment.Amount / 100,
                  Situacao: "Não Pago",
                  DataTentativa: transacao.data.Payment.ReceivedDate,
                  Codigo: transacao.data.Payment.ProviderReturnCode,
                  Motivo: motivoErro,
                  Tentativa: this.listaRecorrencias[i].Recorrencias[j].TryNumber,
                  Mensalidade: this.listaRecorrencias[i].Recorrencias[j].PaymentNumber + 1,
                };
                console.log(naoPaga);
                arrayJson[0].push(naoPaga);
              }
            }
          }
        }
      }
      const data = XLSX.utils.json_to_sheet(arrayJson[0]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      // XLSX.writeFile(wb, 'Transacoes_sem_sucesso.xlsx');
    },
    async exportarExcelTodas() {
      // eslint-disable-next-line
      let arrayJson = [];
      arrayJson[0] = [];
      let naoPaga = {};
      const obterTransacao = functions.httpsCallable('getTransacao');
      for (let i = 0; i < this.listaRecorrencias.length; i += 1) {
        const dataAssinatura = this.listaRecorrencias[i].dataAquisicao.toDate();
        const dataInicio = new Date(this.dataInicial);
        const dataFim = new Date(this.dataFinal);
        if (dataAssinatura < dataFim) {
          for (let j = 0; j < this.listaRecorrencias[i].Recorrencias.length; j += 1) {
            const codigo = {
              PaymentId: this.listaRecorrencias[i].Recorrencias[j].TransactionId,
            };
            // eslint-disable-next-line
            const transacao = await obterTransacao(codigo);
            const dataInicioCaptura = new Date(dataInicio.getFullYear(), dataInicio.getMonth() - 1, dataInicio.getDate());
            // eslint-disable-next-line
            let dataFimCaptura = null;
            // VALIDA SE O MES DE CONSULTA É DE 30 DIAS
            if (dataFim.getDate() === 30 && (dataFim.getMonth() === 3 || dataFim.getMonth() === 5 || dataFim.getMonth() === 8 || dataFim.getMonth() === 10)) {
              dataFimCaptura = new Date(dataFim.getFullYear(), dataFim.getMonth(), "01");
            } else {
              dataFimCaptura = new Date(dataFim.getFullYear(), dataFim.getMonth() - 1, dataFim.getDate());
            }
            // DATA DE TENTATIVA DE COBRANÇA FEITA PELA BRASPAG
            const dataCobranca = new Date(transacao.data.Payment.ReceivedDate);

            if (dataCobranca.getMonth() === dataFim.getMonth()) {
              console.log(transacao.data);
            }
            if (transacao.data.Payment.Status !== 2) {
              if (this.listaRecorrencias[i].Recorrencias[j].TryNumber <= 2 && dataCobranca >= dataInicioCaptura && dataCobranca <= dataFimCaptura) {
                // eslint-disable-next-line
                let motivoErro = ""
                // MOTIVOS DO ERRO NO PAGAMENTO
                if (transacao.data.Payment.ProviderReturnCode === "51") {
                  motivoErro = "Sem limite disponível";
                } else if (transacao.data.Payment.ProviderReturnCode === "82") {
                  motivoErro = "Cartão Inválido";
                }
                // OBJETO CONTENDO OS DADOS A SEREM EXPORTADOS
                naoPaga = {
                  Cliente: this.listaRecorrencias[i].Cliente,
                  Valor: transacao.data.Payment.Amount / 100,
                  Bandeira: this.getBrand(this.listaRecorrencias[i].Cartao),
                  Situacao: "Não Pago",
                  DataTentativa: transacao.data.Payment.ReceivedDate,
                  Codigo: transacao.data.Payment.ProviderReturnCode,
                  Motivo: motivoErro,
                  Tentativa: this.listaRecorrencias[i].Recorrencias[j].TryNumber,
                  Mensalidade: this.listaRecorrencias[i].Recorrencias[j].PaymentNumber + 1,
                };
                console.log(naoPaga);
                arrayJson[0].push(naoPaga);
              } else if (dataCobranca.getDate() >= 8 && dataCobranca >= dataInicioCaptura && dataCobranca <= dataFimCaptura) {
                // eslint-disable-next-line
                let motivoErro = ""
                // MOTIVOS DO ERRO NO PAGAMENTO
                if (transacao.data.Payment.ProviderReturnCode === "51") {
                  motivoErro = "Sem limite disponível";
                } else if (transacao.data.Payment.ProviderReturnCode === "82") {
                  motivoErro = "Cartão Inválido";
                }
                naoPaga = {
                  Cliente: this.listaRecorrencias[i].Cliente,
                  Valor: transacao.data.Payment.Amount / 100,
                  Bandeira: this.getBrand(this.listaRecorrencias[i].Cartao),
                  Situacao: "Não Pago",
                  DataTentativa: transacao.data.Payment.ReceivedDate,
                  Codigo: transacao.data.Payment.ProviderReturnCode,
                  Motivo: motivoErro,
                  Tentativa: this.listaRecorrencias[i].Recorrencias[j].TryNumber,
                  Mensalidade: this.listaRecorrencias[i].Recorrencias[j].PaymentNumber + 1,
                };
                console.log(naoPaga);
                arrayJson[0].push(naoPaga);
              } else if (this.listaRecorrencias[i].Recorrencias[j].TryNumber >= 2 && dataCobranca.getMonth() === dataFim.getMonth() && dataCobranca.getDate() < 13 && dataAssinatura.getDate() > 19) {
                // eslint-disable-next-line
                let motivoErro = ""
                // MOTIVOS DO ERRO NO PAGAMENTO
                if (transacao.data.Payment.ProviderReturnCode === "51") {
                  motivoErro = "Sem limite disponível";
                } else if (transacao.data.Payment.ProviderReturnCode === "82") {
                  motivoErro = "Cartão Inválido";
                }
                naoPaga = {
                  Cliente: this.listaRecorrencias[i].Cliente,
                  Valor: transacao.data.Payment.Amount / 100,
                  Situacao: "Não Pago",
                  DataTentativa: transacao.data.Payment.ReceivedDate,
                  Codigo: transacao.data.Payment.ProviderReturnCode,
                  Motivo: motivoErro,
                  Tentativa: this.listaRecorrencias[i].Recorrencias[j].TryNumber,
                  Mensalidade: this.listaRecorrencias[i].Recorrencias[j].PaymentNumber + 1,
                };
                console.log(naoPaga);
                arrayJson[0].push(naoPaga);
              }
            }
          }
        }
      }
      // eslint-disable-next-line
      var options = {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
      };

      // eslint-disable-next-line
      let arrayJsonPagos = [];
      arrayJsonPagos[0] = [];
      // eslint-disable-next-line
      let arrayJsonResumo = [];
      arrayJsonResumo[0] = [];
      let dataJsonPagos = null;
      let dataJsonResumo = null;
      if (this.dataInicial !== null && this.dataFinal !== null) {
        const obterToken = functions.httpsCallable('obterTokenOAuth');
        const responseToken = await obterToken();

        const filtro = {
          Authorization: responseToken.data.access_token,
          InitialForecastedDate: this.dataInicial.toLocaleString('ko-KR', options).replace(".", "-").replace(".", "-").replace(".", "")
            .replaceAll(" ", ""),
          FinalForecastedDate: this.dataFinal.toLocaleString('ko-KR', options).replace(".", "-").replace(".", "-").replace(".", "")
            .replaceAll(" ", ""),
        };
        const conciliar = functions.httpsCallable('conciliar');
        const response = await conciliar(filtro);

        console.log(response.data);

        for (let i = 0; i < response.data.Items.length; i += 1) {
          // eslint-disable-next-line
          let cliente = null;
          for (let k = 0; k < response.data.Items[i].ItemSchedules.length; k += 1) {
            for (let j = 0; j < this.listaRecorrencias.length; j += 1) {
              // console.log(this.listaRecorrencias[j].Recorrencias);
              const mensalidade = this.listaRecorrencias[j].Recorrencias.find((transacao) => transacao.TransactionId === response.data.Items[i].ItemSchedules[k].TransactionalEventId);
              if (mensalidade !== undefined && mensalidade !== null && mensalidade !== "") {
                cliente = {
                  Cliente: this.listaRecorrencias[j].Cliente,
                  Assinatura: this.listaRecorrencias[j].Assinatura,
                  dataAquisicao: this.listaRecorrencias[j].dataAquisicao,
                  Valor: this.listaRecorrencias[j].Valor,
                  Pagamento: mensalidade,
                };
              }
            }
            if (cliente !== null && cliente !== undefined && cliente !== "") {
              break;
            }
          }
          for (let j = 0; j < response.data.Items[i].ItemSchedules.length; j += 1) {
            // for (let k = 0; k < this.listaRecorrencias.length; k += 1) {
            console.log(response.data.Items[i].ItemSchedules[j]);
            // OBJETO COM OS DADOS DA TRANSAÇÃO E DO CLIENTE
            if (cliente !== "" && cliente !== undefined && cliente !== null) {
              // console.log(cliente);
              dataJsonPagos = {
                Cliente: cliente.Cliente,
                Assinatura: cliente.Assinatura,
                Mensalidade: cliente.Pagamento.PaymentNumber + 1,
                TentativaCobranca: cliente.Pagamento.TryNumber,
                DataCaptura: response.data.Items[i].ItemSchedules[j].PaymentDetails.CaptureDate,
                DataAutorizacao: response.data.Items[i].ItemSchedules[j].PaymentDetails.AuthorizationDate,
                DataPrevistaPagamento: response.data.Items[i].ForecastedDate,
                ValorBruto: cliente.Valor,
                ValorLiquidoParcela: response.data.Items[i].ItemSchedules[j].InstallmentNetAmount / 100,
                SomatorioLiquidoCentavos: response.data.Items[i].ForecastedNetAmount / 100,
                TipoTransacao: response.data.Items[i].ItemSchedules[j].ScheduleType,
                IdTransacional: response.data.Items[i].ItemSchedules[j].PaymentDetails.PaymentId,
                // TransactionalEventId: response.data.Items[i].ItemSchedules[j].TransactionalEventId,
                IdEventoAgenda: response.data.Items[i].ItemSchedules[j].ScheduleId,
                FormaPagamento: response.data.Items[i].Product,
                Bandeira: response.data.Items[i].Brand,
                TipoDocumento: response.data.Items[i].ItemSchedules[j].MerchantDetails.DocumentType,
                CNPJRecebente: response.data.Items[i].DocumentNumber,
                NomeFantasia: response.data.Items[i].ItemSchedules[j].MerchantDetails.FancyName,
                RazaoSocial: response.data.Items[i].ItemSchedules[j].MerchantDetails.CorporateName,
                CategoriaRecebente: response.data.Items[i].ItemSchedules[j].MerchantDetails.MerchantType,
                IdRecebente: response.data.Items[i].ItemSchedules[j].MerchantDetails.MerchantId,
                // TransactionalEventType: response.data.Items[i].ItemSchedules[j].TransactionalEventType,
                // InstallmentNumber: response.data.Items[i].ItemSchedules[j].InstallmentNumber,
                Parcelas: response.data.Items[i].ItemSchedules[j].PaymentDetails.Installments,
                NumeroPedido: response.data.Items[i].ItemSchedules[j].PaymentDetails.OrderId,
                NumeroAfiliado: response.data.Items[i].ItemSchedules[j].PaymentDetails.AffiliationCode,
                Nsu: response.data.Items[i].ItemSchedules[j].PaymentDetails.Nsu,
              };
              dataJsonResumo = {
                Cliente: cliente.Cliente,
                Assinatura: cliente.Assinatura,
                Mensalidade: cliente.Pagamento.PaymentNumber + 1,
                TentativaCobranca: cliente.Pagamento.TryNumber,
                DataCaptura: response.data.Items[i].ItemSchedules[j].PaymentDetails.CaptureDate,
                // DataAutorizacao: response.data.Items[i].ItemSchedules[j].PaymentDetails.AuthorizationDate,
                DataPrevistaPagamento: response.data.Items[i].ForecastedDate,
                ValorBruto: cliente.Valor,
                ValorLiquidoParcela: response.data.Items[i].ItemSchedules[j].InstallmentNetAmount / 100,
                SomatorioLiquidoCentavos: response.data.Items[i].ForecastedNetAmount / 100,
                TipoTransacao: response.data.Items[i].ItemSchedules[j].ScheduleType,
                IdTransacional: response.data.Items[i].ItemSchedules[j].PaymentDetails.PaymentId,
                // TransactionalEventId: response.data.Items[i].ItemSchedules[j].TransactionalEventId,
                // IdEventoAgenda: response.data.Items[i].ItemSchedules[j].ScheduleId,
                FormaPagamento: response.data.Items[i].Product,
                Bandeira: response.data.Items[i].Brand,
                // TipoDocumento: response.data.Items[i].ItemSchedules[j].MerchantDetails.DocumentType,
                // CNPJRecebente: response.data.Items[i].DocumentNumber,
                NomeFantasia: response.data.Items[i].ItemSchedules[j].MerchantDetails.FancyName,
                // RazaoSocial: response.data.Items[i].ItemSchedules[j].MerchantDetails.CorporateName,
                CategoriaRecebente: response.data.Items[i].ItemSchedules[j].MerchantDetails.MerchantType,
                // IdRecebente: response.data.Items[i].ItemSchedules[j].MerchantDetails.MerchantId,
                // TransactionalEventType: response.data.Items[i].ItemSchedules[j].TransactionalEventType,
                // InstallmentNumber: response.data.Items[i].ItemSchedules[j].InstallmentNumber,
                // Parcelas: response.data.Items[i].ItemSchedules[j].PaymentDetails.Installments,
                // NumeroPedido: response.data.Items[i].ItemSchedules[j].PaymentDetails.OrderId,
                // NumeroAfiliado: response.data.Items[i].ItemSchedules[j].PaymentDetails.AffiliationCode,
                // Nsu: response.data.Items[i].ItemSchedules[j].PaymentDetails.Nsu,
              };
              arrayJsonResumo[0].push(dataJsonResumo);
              arrayJsonPagos[0].push(dataJsonPagos);
            }
          }
        }
        // console.log(response);
      }
      const data = XLSX.utils.json_to_sheet(arrayJson[0]);
      const pagosResumo = XLSX.utils.json_to_sheet(arrayJsonResumo[0]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "Não Pagos");
      XLSX.utils.book_append_sheet(wb, pagosResumo, "Pagos Resumo");
      XLSX.writeFile(wb, 'Todas_transações_no_intervalo.xlsx');
    },
    async exportarPrevisaoRecebiveis() {
      console.log("teste");
      console.log(this.listaRecorrencias.length);
      // eslint-disable-next-line
      let arrayJson = [];
      arrayJson[0] = [];
      let prevista = {};
      for (let i = 0; i < this.listaRecorrencias.length; i += 1) {
        // console.log(this.listaRecorrencias[i]);
        if (this.listaRecorrencias[i].Situacao === "Ativa" || this.listaRecorrencias[i].Situacao === "Suspensa") {
          console.log(this.listaRecorrencias[i]);
          const dataAssinatura = this.listaRecorrencias[i].dataAquisicao.toDate();
          const dataAtual = new Date();
          const valorMensalidade = parseFloat(this.listaRecorrencias[i].Valor.substr(3, 10).replace(",", "."));
          console.log(valorMensalidade);
          const bandeiraCartao = this.getBrand(this.listaRecorrencias[i].Cartao);
          const mdrBandeira = this.getTaxaMDR(bandeiraCartao);
          const dataPrevisao = new Date(dataAssinatura.getDate(), dataAtual.getMonth() + 1, dataAtual.getFullYear());
          const valorParceiroRecebe = (valorMensalidade - (parseFloat(mdrBandeira) * valorMensalidade)) * 0.7;
          const valorCarameloRecebe = (valorMensalidade - (parseFloat(mdrBandeira) * valorMensalidade)) * 0.3 - 0.30;
          prevista = {
            Cliente: this.listaRecorrencias[i].Cliente,
            ValorBruto: this.listaRecorrencias[i].Valor,
            Bandeira: bandeiraCartao,
            TaxaMDR: mdrBandeira * 100,
            DataPrevista: dataPrevisao,
            Fee: 0.30,
            ValorMDR: `R$ ${String(valorMensalidade * parseFloat(mdrBandeira)).replace(".", ",")}`,
            ValorParceiro: `R$ ${String(valorParceiroRecebe.toFixed(2)).replace(".", ",")}`,
            ValorCaramelo: `R$ ${String(valorCarameloRecebe.toFixed(2)).replace(".", ",")}`,
          };
          // console.log(prevista);
          arrayJson[0].push(prevista);
        }
      }
      const mes = new Date();
      const data = XLSX.utils.json_to_sheet(arrayJson[0]);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "Previsões");
      XLSX.writeFile(wb, `Previsão_${mes.getMonth()}_${mes.getFullYear()}.xlsx`);
    },
    getTaxaMDR(bandeira) {
      return this.taxas.find((x) => x.bandeira === bandeira).taxa;
    },
    getBrand(numero) {
      console.log(numero);
      let brand = "";

      if (numero.substring(0, 1) === "4") {
        brand = "Visa";
      } else if (numero.substring(0, 1) === "5" || numero.substring(0, 1) === "2") {
        brand = "Master";
      } else if (numero.substring(0, 2) === "36") {
        brand = "Dinners";
      } else if (numero.substring(0, 2) === "38") {
        brand = "Dinners";
      } else if (numero.substring(0, 2) === "34") {
        brand = "Amex";
      } else if (numero.substring(0, 2) === "37") {
        brand = "Amex";
      }
      if (brand !== "") {
        return brand;
        // eslint-disable-next-line
      } else {
        return "Bandeira não identificada";
      }
    },
  },
};
</script>
