<template>
  <main>
    <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-store text-orange"></i>
        </button>
        <div class="z-50 text-left ml-8">
          <!-- Info -->
          <div class="text-3xl font-bold">Atendimento</div>
          <div>
            Acesso ao suporte oferecido ao cliente, antes, durante ou depois da
            compra.
          </div>
        </div>
      </div>
    </section>
    <!-- Services -->
    <section class="container mx-auto mt-6" id="comments">
      <div
        class="bg-white rounded border border-gray-200 relative flex flex-col"
      >
        <div
          class="
            bg-blue
            px-6
            pt-6
            pb-5
            font-bold
            border-b border-gray-200
          "
        >
          <span class="card-title text-white">Serviços disponíveis</span>
          <i class="fa fa-tasks float-right text-white text-2xl"></i>
        </div>
        <ul class="container mx-auto">
          <li class="p-4 border border-gray-200">
            <div>
              <div class="font-bold">
                <i class="far fa-address-card w-7 text-orange"></i>
                <router-link
                  class="py-4 px-2 font-semibold"
                  :to="{ name: 'subscribe' }"
                >
                  Assinatura do plano
                </router-link>
              </div>
            </div>
          </li>
          <li class="p-4 border border-gray-200">
            <div>
              <div class="font-bold">
                <i class="fas fa-certificate w-7 text-orange"></i>
                <router-link
                  class="py-4 px-2 font-semibold"
                  :to="{ name: 'posvenda' }"
                >
                  Pós Venda
                </router-link>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import AppFooter from "@/components/Footer.vue";

export default {
  name: "CRM",
  components: {
    AppFooter,
  },
};
</script>
