<template>
  <div
    class="
      fixed
      bottom-0
      left-0
      text-left
      align-top
      w-full
      h-8
      bg-blue
    "
  >
    <div class="relative grid grid-cols-2">
      <!-- Scrub -->
      <div class="text-right m-1">
        <span class="text-white">Este programa faz parte de uma parceria
            com a </span>
      </div>
      <div class="">
        <img
          src="../assets/caramelo-branco.png"
          alt="Logo"
          class="h-4 m-2"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Footer",
  components: {},
};
</script>
