import {
  Chart,
  // Legend,
  Title,
  Tooltip,
} from "chart.js";

Chart.register(Title, Tooltip);

Chart.defaults.plugins.title.display = true;
Chart.defaults.plugins.legend.display = false;
