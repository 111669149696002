<template>
  <app-header />

  <router-view v-slot="{ Component }">
    <component :is="Component"></component>
    <!-- <transition name="fade" mode="out-in">
    </transition> -->
  </router-view>

  <auth-modal />
</template>

<script>
import AppHeader from "./components/Header.vue";
import AuthModal from "./components/Auth.vue";

export default {
  name: "App",
  components: {
    AppHeader,
    AuthModal,
  },
  created() {
    this.$store.dispatch("init_login");
  },
};
</script>

<style>
.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.5s linear;
}

.fade-leave-to {
  transition: all 0.5s linear;
  opacity: 0;
}
</style>
