<template>
  <main>
    <!-- Introduction -->
    <section class="mb-8 py-8 text-white text-center relative">
      <div
        class="absolute inset-0 w-full h-full bg-contain introduction-bg"
        style="background-image: url(assets/img/header.png)"
      ></div>
      <div class="container mx-auto">
        <div class="text-white main-header-content">
          <h1 class="font-bold text-3xl mb-5">Quero te apresentar uma oportunidade que a Cellairis Brasil acaba de lançar</h1>
          <h4 class="w-full md:w-8/12 text-xl mx-auto">
            Uma assinatura mensal, de custo baixíssimo, onde você pode fazer 2 consertos de tela por ano.
          </h4>
          <p class="w-full md:w-8/12 mx-auto">
            Entre no Cellairis Plus uma forma simples e prática de manter
            a tela de seu celular sempre nova, sem burocracia!
          </p>
        </div>
      </div>

      <img
        class="relative block mx-auto mt-5 -mb-8 w-auto max-w-full"
        src="assets/img/introduction-users2.png"
      />
    </section>

    <!-- Main Content -->
    <section class="container mx-auto mt-6">
      <div
        class="bg-white rounded border-gray-200 relative flex flex-col"
      >
        <div class="px-6 pt-6 pb-5 font-bold border-b border-gray-200 bg-blue">
          <!-- Title -->
          <span class="card-title font-bold text-white">Informações sobre o nosso programa</span>
          <i class="fa fa-comments float-right text-white text-2xl" @click="teste()"></i>
        </div>
        <div class="p-6">
          <ul class="container mx-auto">
            <li class="p-3">
              <!-- Text -->
              <div class="mb-2">
                <div class="font-bold">O que é o Cellairis Plus?</div>
              </div>
              <p class="mb-2">
                Assinatura mensal, de custo baixíssimo, onde você pode fazer 2 consertos de tela por ano, sem pagar nada na hora e cancelar a qualquer no momento que quiser.
              </p>
              <p>
                O Cellairis Plus visa facilitar o dia a dia de seus consumidores desburocratizando a troca de telas de aparelhos de celular, reduzindo custos e principalmente tempo.
              </p>
            </li>
            <li class="p-3">
              <!-- Text -->
              <div class="mb-5">
                <div class="font-bold">Veja como é fácil utilizar:</div>
              </div>
              <p class="list-disc">
                Você adquire o plano em uma loja Cellairis.
              </p>
              <p>
                Acidentalmente a tela de seu celular quebra.
              </p>
              <p>
                Você vai até uma loja Cellairis.
              </p>
              <p>
                A tela é trocada e seu celular fica novo 😊.
              </p>
              <p>
                ✌ Fácil assim!
              </p>
            </li>
            <li class="p-3">
              <!-- Text -->
              <div class="mb-2">
                <div class="font-bold">Escolha seu plano tenha esse benefício nas suas mãos.</div>
              </div>
              <p>
                Planos a partir de => R$9,90/mês "Adquirindo as capas da linha Rapture e Showcase Cellairis".
              </p>
              <p>
              </p>
              <p>
                Ou planos a partir R$19,90/mês sem adquirir as capas Rapture e Showcase Cellairis.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </main>
  <app-footer />
</template>

<script>
import { functions } from '@/includes/firebase';
// import { auth } from "@/includes/firebase";
import AppFooter from '@/components/Footer.vue';

export default {
  // eslint-disable-next-line
  name: "Home",
  components: {
    AppFooter,
  },
  data() {
    return {
      songs: [],
      maxPerPage: 25,
      pendingRequest: false,
    };
  },
  methods: {
    async teste() {
      // const myHeaders = new Headers();
      // myHeaders.append("Content-Type", "application/json");

      // const raw = JSON.stringify({
      //   teste: "teste",
      // });

      // const requestOptions = {
      //   method: 'POST',
      //   headers: myHeaders,
      //   body: raw,
      //   redirect: 'follow',
      // };

      // fetch(`${window.location.href}authorizeRecurrentCredictCard`, { requestOptions })
      //   .then((response) => { response.text(); })
      //   .then((result) => { console.log(result); })
      //   .then((error) => console.log('error', error))
      //   .then((data) => console.log('data', data));

      console.log("teste");

      const obj = {
        SubscriptionId: "12345",
        CustomerName: "Ailton Torres de Oliveira",
        EndDate: "2017-02-27",
        CardNumber: "4024007197692931",
        Holder: "AILTON T OLIVEIRA",
        ExpirationDate: "12/2027",
        SecurityCode: "123",
        Brand: "Visa",
      };

      const efetuarAssinatura = functions.httpsCallable('efeturAssinatura');
      const response = await efetuarAssinatura(obj);
      // .then((response) => { console.log('response', response); })
      // .then((error) => { console.log('error', error); });
      console.log(response.data);
      // const data = result.data;

      // const helloUserUrl = `{ window.location.href } authorizeRecurrentCredictCard`;

      // const exec = auth.currentUser.getIdToken().then(function(token) {
      //   console.log('Sending request to', helloUserUrl, 'with ID token in Authorization header.');
      //   var req = new XMLHttpRequest();
      //   req.onload = function() {
      //     this.responseContainer.innerText = req.responseText;
      //   }.bind(this);
      //   req.onerror = function( ) {
      //     this.responseContainer.innerText = 'There was an error';
      //   }.bind(this);
      //   req.open('GET', helloUserUrl, true);
      //   req.setRequestHeader('Authorization', `Bearer ${token}`);
      //   req.send();
      // }.bind(this));

      // await exec();
    },
  },
};
</script>
