import {
  Form as VeeForm,
  Field as VeeField,
  defineRule,
  ErrorMessage,
  configure,
} from "vee-validate";
import {
  required,
  min,
  max,
  alpha_spaces as alphaSpaces,
  email,
  min_value as minVal,
  max_value as maxVal,
  confirmed,
  not_one_of as excluded,
} from "@vee-validate/rules";
import Funcoes from "./funcoes";

export default {
  install(app) {
    app.component("VeeForm", VeeForm);
    app.component("VeeField", VeeField);
    app.component("ErrorMessage", ErrorMessage);

    defineRule("required", required);
    defineRule("tos", required);
    defineRule("min", min);
    defineRule("max", max);
    defineRule("alpha_spaces", alphaSpaces);
    defineRule("email", email);
    defineRule("min_value", minVal);
    defineRule("max_value", maxVal);
    defineRule("passwords_mismatch", confirmed);
    defineRule("excluded", excluded);
    defineRule("country_excluded", excluded);
    defineRule("cpf", (value) => {
      if (!value || !value.length) {
        return true;
      }

      return Funcoes.isValidCPF(value);
    });
    defineRule("imei", (value) => {
      if (!value || !value.length) {
        return true;
      }

      return Funcoes.isValidIMEI(value);
    });
    defineRule("cartao", (value) => {
      if (!value || !value.length) {
        return true;
      }

      return Funcoes.isValidCreditCard(value);
    });

    configure({
      generateMessage: (ctx) => {
        const messages = {
          required: `O campo ${ctx.field} é obrigatório.`,
          min: `O campo ${ctx.field} é muito curto.`,
          max: `O campo ${ctx.field} é muito longo.`,
          alpha_spaces: `O campo ${ctx.field} deve conter somente caracteres alfabéticos e espaços.`,
          email: `O campo ${ctx.field} deve ser um email válido.`,
          min_value: `O campo ${ctx.field} é muito baixo.`,
          max_value: `O campo ${ctx.field} é muito alto.`,
          excluded: `Não é permitido usar este valor para o campo ${ctx.field}.`,
          country_excluded:
            "Devido a restrições, não aceitamos usuários deste local.",
          passwords_mismatch: "As senhas não conferem.",
          tos: "Você deve aceitar os Termos do Serviço.",
          imei: `O campo ${ctx.field} é inválido.`,
          cpf: `O campo ${ctx.field} é inválido.`,
          cartao: `O campo ${ctx.field} é inválido.`,
        };

        const message = messages[ctx.rule.name]
          ? messages[ctx.rule.name]
          : `O campo ${ctx.field} é inválido.`;

        return message;
      },
      validateOnBlur: true,
      validateOnChange: true,
      validateOnInput: false,
      validateOnModelUpdate: true,
    });
  },
};
