import { createStore } from "vuex";
// eslint-disable-next-line
import firebase from 'firebase';
import { auth, authAux, usersCollection } from "@/includes/firebase";

export default createStore({
  state: {
    authModalShow: false,
    userLoggedIn: false,
    userCreated: false,
  },
  mutations: {
    toggleAuthModal: (state) => {
      state.authModalShow = !state.authModalShow;
    },
    toggleAuth(state) {
      state.userLoggedIn = !state.userLoggedIn;
    },
    toggleCreated(state) {
      state.userCreated = !state.userCreated;
    },
  },
  actions: {
    async register({ commit }, payload) {
      const userCred = await authAux.createUserWithEmailAndPassword(
        payload.email,
        payload.senha,
      );
      if (payload.profile === "cliente") {
        await usersCollection.doc(userCred.user.uid).set({
          name: payload.nome,
          email: payload.email,
          profile: payload.profile,
          dataNascimento: payload.dataNascimento,
          celular: payload.celular,
          cpf: payload.cpf,
        });
        await authAux.sendPasswordResetEmail(payload.email);
      } else if (payload.profile === "vendedor" || payload.profile === "gerente") {
        await usersCollection.doc(userCred.user.uid).set({
          name: payload.nome,
          email: payload.email,
          profile: payload.profile,
          cpf: payload.cpf,
          celular: payload.celular,
          loja: payload.loja,
          cnpj: payload.cnpj,
        });
        await authAux.sendPasswordResetEmail(payload.email);
      } else if (payload.profile === "parceiro") {
        await usersCollection.doc(userCred.user.uid).set({
          name: payload.nome,
          email: payload.email,
          profile: payload.profile,
          cpf: payload.cpf,
          celular: payload.celular,
          grupoEconomico: payload.grupoEconomico,
        });
        await authAux.sendPasswordResetEmail(payload.email);
      } else if (payload.profile === "supervisor") {
        await usersCollection.doc(userCred.user.uid).set({
          name: payload.nome,
          email: payload.email,
          profile: payload.profile,
          cpf: payload.cpf,
          celular: payload.celular,
          lojas: payload.lojas,
        });
        await authAux.sendPasswordResetEmail(payload.email);
      }

      await userCred.user.updateProfile({
        displayName: payload.nome,
      });

      commit("toggleCreated");
    },
    async login({ commit }, payload) {
      await auth.signInWithEmailAndPassword(payload.email, payload.senha);

      commit("toggleAuth");
    },
    init_login({ commit }) {
      const user = auth.currentUser;

      if (user) {
        commit("toggleAuth");
      }
    },
    async signout({ commit }) {
      await auth.signOut();

      commit("toggleAuth");
    },
    reauthenticate({ commit }, payload) {
      const user = auth.currentUser;
      // eslint-disable-next-line
      console.log(user.email);
      // eslint-disable-next-line
      // console.log(auth);
      const cred = auth.EmailAuthProvider.credential(user.email, payload.senhaAtual);
      // eslint-disable-next-line
      // console.log(cred);
      commit(user.reauthenticateWithCredential(cred));
    },
    async atualizaSenha({ commit }, payload) {
      // eslint-disable-next-line
      if (payload.novaSenha !== "" && payload.novaSenha !== null && payload.senhaAtual !== "" && payload.senhaAtual !== null) {
        const user = auth.currentUser;
        // eslint-disable-next-line
        console.log(firebase.auth.EmailAuthProvider);
        const cred = firebase.auth.EmailAuthProvider.credential(
          user.email,
          payload.senhaAtual,
        );
        user.reauthenticateWithCredential(cred)
          .then(() => {
            user.updatePassword(payload.novaSenha)
              .then(() => {
                // eslfirebaseint-disable-next-line
                commit("Alterado com sucesso");
              }).catch((error) => {
                // eslint-disable-next-line
                commit(error);
              });
          }).catch((error) => {
            commit(error);
          });
      }
    },
    async atualizaEmailGerente({ commit }, payload) {
      firebase.auth.updateUser(payload.uid, {
        email: payload.email,
      }).then(() => {
        commit("Alterado com sucesso");
      }).catch((error) => {
        commit(error);
      });
    },
    async atualizaEmail({ commit }, payload) {
      // eslint-disable-next-line
      if (payload.novoEmail !== "" && payload.novoEmail !== null) {
        const user = auth.currentUser;
        // eslint-disable-next-line
        const cred = firebase.auth.EmailAuthProvider.credential(
          user.email,
          payload.senha,
        );
        user.reauthenticateWithCredential(cred)
          .then(() => {
            user.updateEmail(payload.novoEmail)
              .then(() => {
                // eslint-disable-next-line
                commit("Alterado com sucesso");
              }).catch((error) => {
                // eslint-disable-next-line
                commit(error);
              });
          }).catch((error) => {
            commit(error);
          });
      }
    },
  },
  modules: {},
});
