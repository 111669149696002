import { createRouter, createWebHistory } from "vue-router";
import store from '@/store';
import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import MyClub from "@/views/MyClub.vue";
import ControlPanel from "@/views/ControlPanel.vue";
import Setting from "@/views/Setting.vue";
import Store from "@/views/Store.vue";
import CRM from "@/views/CRM.vue";
import Subscribe from "@/views/Subscribe.vue";
import PosVenda from "@/views/PosVenda.vue";
import MinhaAssinatura from "@/views/MinhaAssinatura.vue";
import Perfil from "@/views/Perfil.vue";
import MeusPagamentos from "@/views/MeusPagamentos.vue";
import CadastroLoja from "@/views/CadastroLoja.vue";
import CadastroPlanos from "@/views/CadastroPlanos.vue";
import CadastroUsuario from "@/views/CadastroUsuario.vue";
import CadastroModelos from "@/views/CadastroModelos.vue";
import ControlPanelDetails from "@/views/ControlPanelDetails.vue";
import Produtos from "@/views/Produtos.vue";
import CadastroAdmin from "@/views/CadastroAdmin.vue";
import PerfilFuncionario from "@/views/PerfilFuncionario.vue";
import CadastroSupervisores from "@/views/CadastroSupervisores.vue";
import Checkout from "@/views/Checkout.vue";
import ExportarConciliacao from "@/views/ExportarConciliacao.vue";
import HistoricoRecorrencias from "@/views/HistoricoRecorrencias.vue";
import HistoricoRecorrenciaCliente from "@/views/HistoricoRecorrenciaCliente.vue";
import Export from "@/views/Export.vue";

const routes = [
  {
    path: "/cellairis",
    name: "home",
    component: Home,
  },
  {
    path: "/cellairis/exportacao",
    name: "exportacao",
    component: Export,
  },
  {
    path: "/cellairis/about",
    name: "about",
    component: About,
  },
  {
    path: "/cellairis/myclub",
    name: "myclub",
    component: MyClub,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/controlpanel",
    name: "controlpanel",
    component: ControlPanel,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/setting",
    name: "setting",
    component: Setting,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/store",
    name: "store",
    component: Store,
  },
  {
    path: "/cellairis/crm",
    name: "crm",
    component: CRM,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/subscribe",
    name: "subscribe",
    component: Subscribe,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/posvenda",
    name: "posvenda",
    component: PosVenda,
  },
  {
    path: "/cellairis/minha-assinatura",
    name: "minha-assinatura",
    component: MinhaAssinatura,
  },
  {
    path: "/cellairis/perfil",
    name: "perfil",
    component: Perfil,
  },
  {
    path: "/cellairis/meus-pagamentos",
    name: "meus-pagamentos",
    component: MeusPagamentos,
  },
  {
    path: "/cellairis/cadastro-loja",
    name: "cadastro-loja",
    component: CadastroLoja,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/cadastro-planos",
    name: "cadastro-planos",
    component: CadastroPlanos,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/cadastro-usuario",
    name: "cadastro-usuario",
    component: CadastroUsuario,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/cadastro-admin",
    name: "cadastro-admin",
    component: CadastroAdmin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/cadastro-modelo",
    name: "cadastro-modelo",
    component: CadastroModelos,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/control-panel-details",
    name: "controlpaneldetails",
    component: ControlPanelDetails,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/produtos",
    name: "produtos",
    component: Produtos,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/perfil-funcionario",
    name: "perfil-funcionario",
    component: PerfilFuncionario,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/cadastro-supervisores",
    name: "cadastro-supervisores",
    component: CadastroSupervisores,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/checkout/:idAssinatura",
    name: "checkout",
    component: Checkout,
    props: (route) => ({ idAssinatura: String(route.params.idAssinatura) }),
    meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/cellairis/exportar-conciliacao",
    name: "exportar-conciliacao",
    component: ExportarConciliacao,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/historico-recorrencias",
    name: "historico-recorrencias",
    component: HistoricoRecorrencias,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/cellairis/historico-recorrencia-cliente/:idAssinatura",
    name: "historico-recorrencia-cliente",
    component: HistoricoRecorrenciaCliente,
    props: (route) => ({ idAssinatura: String(route.params.idAssinatura) }),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }

  if (store.state.userLoggedIn) {
    next();
  } else {
    next({ name: 'home' });
  }
});

export default router;
