<template>
  <main>
    <!-- Header -->
    <section class="w-full mb-8 py-6 text-center text-white relative">
      <div
        class="absolute inset-0 w-full h-full box-border bg-contain music-bg"
        style="background-image: url(/assets/img/other-header.png)"
      ></div>
      <div class="container mx-auto flex items-center">
        <!-- Button -->
        <button
          type="button"
          class="
            z-50
            h-24
            w-24
            text-3xl
            bg-white
            text-black
            rounded-full
            focus:outline-none
          "
        >
          <i class="fas fa-store text-orange"></i>
        </button>
        <div class="z-50 text-left ml-8">
          <!-- Info -->
          <div class="text-3xl font-bold">Produtos e Valores das Assinaturas</div>
        </div>
      </div>
    </section>
      <!-- Main Content -->
    <section class="container mx-auto">
      <div
        class="bg-white rounded border border-gray-200 relative flex flex-col mb-8"
      >
        <!-- component -->
        <div class="p-5">
          <div class="flex justify-center pb-10">
            <!-- <div class="flex justify-center items-center text-white
            w-1/7 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-purple-400 h-12">
              CPF
            </div> -->
            <div class="
              flex justify-left
              items-center text-white
              border
              w-full
              pl-2
              font-bold
              bg-red-500
              h-12"
            >
              Capas Participantes:
            </div>
          </div>
          <div class="grid sm:grid-rows-5 md:grid-rows-1 grid-flow-col px-10">
            <div v-for="item in listaCapas" :key="item.sku" class="row-auto bg-white sm:w-full md:w-1/3 lg:w-3/4 xl:w-4/5 mb-4 rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <a href="#">
                <img class="rounded-t-lg" src="https://firebasestorage.googleapis.com/v0/b/clube-reparo.appspot.com/o/capa-rapture.png?alt=media&token=35c2a008-a2a8-4693-92d1-9fb165fa4a6e" alt="" />
              </a>
              <div>
                <a href="#">
                    <h5 class="my-8 text-sm tracking-tight text-gray-900 dark:text-white text-center">{{ item.nome }}</h5>
                </a>
                <p class="mb-3 sm:text-xs text-sm font-normal text-gray-700 dark:text-gray-400">{{ item.descricao }}</p>
                <!-- <a href="#" class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-500-700 rounded-lg hover:bg-red-500-800 focus:ring-4 focus:ring-blue-300 dark:bg-red-500-600 dark:hover:bg-red-500-700 dark:focus:ring-blue-800">
                Read more
                <svg class="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </a> -->
              </div>
            </div>
          </div>
          <div class="
            my-10
            flex justify-left
            items-center text-white
            border
            w-full
            pl-2
            font-bold
            bg-red-500
            h-12"
          >
            Películas Participantes:
          </div>
          <div class="grid sm:grid-rows-5 md:grid-rows-1 grid-flow-col px-10">
            <div v-for="item in listaPeliculas" :key="item.sku" class="row-auto bg-white sm:w-full md:w-1/3 lg:w-3/4 xl:w-3/5 mb-4 rounded-lg border border-gray-200 shadow-md dark:bg-gray-800 dark:border-gray-700">
              <a href="#">
                <img class="rounded-t-lg" src="https://firebasestorage.googleapis.com/v0/b/clube-reparo.appspot.com/o/capa-rapture.png?alt=media&token=35c2a008-a2a8-4693-92d1-9fb165fa4a6e" alt="" />
              </a>
              <div>
                <a href="#">
                    <h5 class="my-8 text-sm tracking-tight text-gray-900 dark:text-white text-center">{{ item.nome }}</h5>
                </a>
                <p class="mb-3 sm:text-xs text-sm font-normal text-gray-700 dark:text-gray-400">{{ item.descricao }}</p>
                <!-- <a href="#" class="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-red-500-700 rounded-lg hover:bg-red-500-800 focus:ring-4 focus:ring-blue-300 dark:bg-red-500-600 dark:hover:bg-red-500-700 dark:focus:ring-blue-800">
                Read more
                <svg class="ml-2 -mr-1 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                </a> -->
              </div>
            </div>
          </div>
          <div class="my-10">
            <div class="
              flex justify-left
              items-center text-white
              border
              w-full
              pl-2
              mb-0
              font-bold
              bg-red-500
              h-12"
            >
              Modelos e Valores
            </div>
            <div class="flex justify-center text-sm mt-0">
            <!-- <div class="flex justify-center items-center text-white
            w-1/7 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 bg-purple-400 h-12">
              CPF
            </div> -->
            <div class="flex justify-center items-center text-black
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              Faixa
            </div>
            <div class="flex justify-center items-center text-black
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              Marca
            </div>
            <div class="flex justify-center items-center text-black
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              Modelo
            </div>
            <div class="flex justify-center items-center text-black
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              Tipo Reparo
            </div>
            <div class="flex justify-center items-center text-black
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              Valor Reparo
            </div>
            <div class="flex justify-center items-center text-black
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              Com Capa e Película
            </div>
            <div class="flex justify-center items-center text-black
            w-1/8 sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              Sem Capa e Película
            </div>
          </div>
          <div v-for="item in listaProdutos"  :key ="item.faixa" class="text-sm">
            <div class="flex justify-center">
              <!-- <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              {{item.cpf}}
              </div> -->
              <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              {{item.faixa}}
              </div>
              <div class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12"
              >
              {{item.marca}}</div>
              <div class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12"
              >
              {{item.nome}}</div>
              <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              {{item.tipoReparo}}
              </div>
              <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              {{item.valorReparo}}
              </div>
              <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              R$ {{String(item.comCapa).replace(".", ",")}}0
              </div>
              <div
              class="
              bg-gray-100
              my-2
              p-1
              flex
              justify-center
              items-center
              w-full sm:w-1/2 md:w-1/3 lg:w-1/4 xl:w-1/6 mb-4 h-12">
              R$ {{String(item.semCapa).replace(".", ",")}}0
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState } from "vuex";
// import Multiselect from "@vueform/multiselect";
import {
  auth,
  usersCollection,
  modelosCollection,
  marcasCollection,
  planosCollection,
} from "@/includes/firebase";

export default {
  name: "ReportDetails",
  computed: {
    ...mapState(["userLoggedIn"]),
  },
  data() {
    return {
      profile: "",
      filtroSelecionado: "",
      listaFiltro: [
        {
          nome: "Vendedor",
          value: "vendedor",
        },
        {
          nome: "Loja",
          value: "loja",
        },
        {
          nome: "Data Aquisição",
          value: "data",
        },
      ],
      listaCapas: [
        {
          sku: 1,
          nome: "Rapture",
          descricao: "",
        },
        {
          sku: 2,
          nome: "Showcase Clear",
          descricao: "",
        },
        {
          sku: 3,
          nome: "Showcase Grip preto/clear",
          descricao: "",
        },
        {
          sku: 4,
          nome: "Showcase Grip preto/preto",
          descricao: "",
        },
        {
          sku: 5,
          nome: "Showcase Clear",
          descricao: "",
        },
        {
          sku: 6,
          nome: "Showcase Grip Verde",
          descricao: "",
        },
        {
          sku: 7,
          nome: "Showcase Grip Azul",
          descricao: "",
        },
      ],
      listaPeliculas: [
        {
          sku: 1,
          nome: "Monstershock",
          descricao: "",
        },
        {
          sku: 2,
          nome: "Monstershock Full",
          descricao: "",
        },
      ],
      listaProdutos: [],
    };
  },
  //   components: {
  //     Multiselect,
  //   },
  async mounted() {
    if (auth.currentUser != null) {
      const docSnapshot = await usersCollection.doc(auth.currentUser.uid).get();
      this.profile = docSnapshot.data();
    }
    this.carregaDados();
  },
  methods: {
    async carregaDados() {
      const marcas = await marcasCollection.where("status", "==", true).get();
      const planos = await planosCollection.orderBy("faixa", "asc").get();
      const modelos = await modelosCollection.where("status", "==", true).orderBy("nome", "asc").get();
      const faixas = [];
      planos.docs.forEach((doc) => {
        faixas.push(doc.data().faixa);
      });
      console.log(faixas);
      faixas.forEach((faixa) => {
        modelos.docs.forEach((doc) => {
          const faixaCorrespondente = planos.docs.find((x) => x.id === doc.data().faixa);
          if (faixa === faixaCorrespondente.data().faixa) {
            const marcaCorrespondente = marcas.docs.find((x) => x.id === doc.data().marca).data().nome;
            // console.log(marcaCorrespondente);
            const modelo = {
              faixa: faixaCorrespondente.data().faixa,
              marca: marcaCorrespondente,
              nome: doc.data().nome,
              tipoReparo: doc.data().tipoReparo,
              valorReparo: doc.data().valorReparo,
              comCapa: faixaCorrespondente.data().com_capa,
              semCapa: faixaCorrespondente.data().sem_capa,
            };
            this.listaProdutos.push(modelo);
          }
        });
      });
      // console.log(marcas.docs[0].id);
      // console.log(planos);
    },
  },
  selecionarFiltro(value) {
    this.filtroSelecionado = value;
  },
};
</script>
